
import store from '../vuex/store.js'
import i18n from '../translations/translations.js'
import dateFunctionsAdd from './dateFunctionsAdd.js'
import functions from './functions.js'

const regexYear = /^[1-9]{1}[0-9]{3}$/
const regexValidDefault = /^[0-9a-zA-ZäöüÄÖÜèéàÈÉÀç" ".,'_-]+$/
const regexMail = /^[0-9a-zA-ZäöüÄÖÜèéàÈÉÀç" ".,'_%+-]+[@]{1}[0-9a-zA-ZäöüÄÖÜèéàÈÉÀç" ".,'_%+-]+[.]{1}[A-Za-z]{2,}$/

const regexPW = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*()-]).{8,255}$/

export default ({

    name: function (name) {
        if (regexValidDefault.test(name) && name.length <= 255 && name.trim().length > 0) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validName') }
        }
    },

    prename: function (prename) {
        if (regexValidDefault.test(prename) && prename.length <= 255 && prename.trim().length > 0) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validPrename') }
        }
    },

    sex: function (sex) {
        if (functions.toInteger(sex) !== undefined) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validSalutation') }
        }
    },

    firmname: function (firmname) {
        if (firmname.length <= 255 && firmname.trim().length > 0) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validFirmname') }
        }
    },

    street: function (street) {
        if (regexValidDefault.test(street) && street.length <= 255 && street.trim().length > 0) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validStreet') }
        }
    },

    streetNr: function (streetNr) {
        if (regexValidDefault.test(streetNr) && streetNr.length <= 255 && streetNr.trim().length > 0) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validStreetNr') }
        }
    },

    postbox: function (postbox) {
        const isNullOrUndefined = postbox === undefined || postbox === null || postbox === ''
        const isValid = isNullOrUndefined || (regexValidDefault.test(postbox) && postbox.length <= 255)

        return { is_valid: isValid, text: isValid ? '' : i18n.t('errorInfo.validPostbox') }
    },

    place: function (place) {
        if (regexValidDefault.test(place) && place.length <= 255 && place.trim().length > 0) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validPlace') }
        }
    },

    zipCode: function (zipCode, country) {
        if (country === '') return { is_valid: true, text: '' }

        const countryInfoAll = store.getters.countryInfoAll
        const regex = new RegExp(countryInfoAll[country].regex_zipcode)

        if (regex.test(zipCode)) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validZip') }
        }
    },

    country: function (country) {
        if (functions.toInteger(country) !== undefined) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validCountry') }
        }
    },

    countryState: function (countryState) {
        if (functions.toInteger(countryState) !== undefined) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validState') }
        }
    },

    meteringPointType: function (meteringPointType) {
        if (functions.toInteger(meteringPointType) !== undefined) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validMeteringPointType') }
        }
    },

    meteringpointtype: function (meteringpointtype) {
        if (functions.toInteger(meteringpointtype) !== undefined) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validMeteringPointType') }
        }
    },

    phoneprefix: function (phoneprefix) {
        if (phoneprefix.length <= 5 && phoneprefix.trim().length > 0) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validPhoneprefix') }
        }
    },

    phone: function (phone, phoneprefix, countryListInitial) {
        const countryList = JSON.parse(JSON.stringify(countryListInitial))
        const phoneprefixList = countryList.map(country => country.phoneprefix)

        if (!phoneprefixList.includes(phoneprefix)) {
            return { is_valid: false, text: i18n.t('errorInfo.validPhoneprefix') }
        } else {
            const country = countryList.filter(country => country.phoneprefix === phoneprefix)
            const phonelength = phone.replace(/ /g, '').length
            const phonelengthMin = country[0].phonelengthMin
            const phonelengthMax = country[0].phonelengthMax

            if (/^[0-9 ]+$/.test(phone) && phonelength <= phonelengthMax && phonelength >= phonelengthMin) {
                return { is_valid: true, text: '' }
            } else {
                return { is_valid: false, text: i18n.t('errorInfo.validPhone') }
            }
        }
    },

    language: function (language) {
        if (language.length === 2) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validLanguage') }
        }
    },

    mail: function (mail) {
        if (regexMail.test(mail) && mail.length <= 255 && mail.trim().length > 0) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validMail') }
        }
    },

    website: function (website) {
        if (/^(http:\/\/www.|https:\/\/www.|http:\/\/|https:\/\/)?[a-z0-9]+([.-]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(website) && website.length <= 255 && website.trim().length > 0) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validWebsite') }
        }
    },

    subdomain: function (subdomain) {
        if (subdomain.length <= 255 && subdomain.trim().length > 0) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validSubdomain') }
        }
    },

    mailSend: function (mail) {
        if (regexMail.test(mail) && mail.length <= 255 && mail.trim().length > 0) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validEmailComChanel') }
        }
    },

    xcodeSupplier: function (xcodeSupplier, marketarea) {
        if (marketarea === '') return { is_valid: true, text: '' }

        const countriesMarketareasRules = store.getters.countriesMarketareasRules
        const regexXcodeMarketarea = new RegExp(countriesMarketareasRules[marketarea].regexXCode)

        if (regexXcodeMarketarea.test(xcodeSupplier)) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validXcode') }
        }
    },

    xcodeBalancegroup: function (xcodeBalancegroup, marketarea) {
        if (marketarea === '') return { is_valid: true, text: '' }

        const countriesMarketareasRules = store.getters.countriesMarketareasRules
        const regexXcodeMarketarea = new RegExp(countriesMarketareasRules[marketarea].regexXCode)

        if (regexXcodeMarketarea.test(xcodeBalancegroup)) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validXcode') }
        }
    },

    marketarea: function (marketarea, otherMarketareas) {
        if (otherMarketareas !== undefined) {
            if (marketarea !== '') {
                const filterMarketArea = otherMarketareas.filter(function (obj) {
                    return obj.marketarea === marketarea
                })

                if (filterMarketArea.length > 1) {
                    return { is_valid: false, text: i18n.t('errorInfo.validMarketareaMultiple') }
                } else {
                    return { is_valid: true, text: '' }
                }
            } else {
                return { is_valid: false, text: i18n.t('errorInfo.validMarketarea') }
            }
        } else {
            if (marketarea !== '') {
                return { is_valid: true, text: '' }
            } else {
                return { is_valid: false, text: i18n.t('errorInfo.validMarketarea') }
            }
        }
    },

    marketareaGV: function (marketareaGV) {
        if (marketareaGV !== '') {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validMarketarea') }
        }
    },

    meteringPointGV: function (meteringPointGV, marketarea, country, state) {
        if (marketarea === '' || country === '' || state === '') return { is_valid: true, text: '' }

        const countriesMarketareasRules = store.getters.countriesMarketareasRules
        const rulesMp = countriesMarketareasRules[marketarea][country][state].rulesMp

        let check = false
        for (let i = 0; i < rulesMp.length; i++) {
            const regex = new RegExp(rulesMp[i].regex_meteringpoint)
            if (regex.test(meteringPointGV)) {
                check = true
                break
            }
        }

        if (check === true) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validMeteringCode') }
        }
    },

    meteringPointProductionGV: function (meteringPointProductionGV, marketarea, country, state) {
        if (marketarea === '' || country === '' || state === '') return { is_valid: true, text: '' }

        const countriesMarketareasRules = store.getters.countriesMarketareasRules
        const rulesMp = countriesMarketareasRules[marketarea][country][state].rulesMp

        let check = false
        for (let i = 0; i < rulesMp.length; i++) {
            const regex = new RegExp(rulesMp[i].regex_meteringpoint)
            if (regex.test(meteringPointProductionGV)) {
                check = true
                break
            }
        }

        if (check === true) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validMeteringCode') }
        }
    },

    currencies: function (currencies) {
        if (currencies !== undefined && currencies.length > 0) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validCurrencies') }
        }
    },

    password1: function (password1) {
        if (regexPW.test(password1) && password1.length <= 255 && password1.length > 0) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validPassword') }
        }
    },

    password2: function (password1, password2) {
        if (password1 === password2) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validRepeatPassword') }
        }
    },

    activationCode: function (code, required) {
        if (required) {
            if (regexPW.test(code) && code.length <= 255 && code.length > 0) {
                return { is_valid: true, text: '' }
            }
            return { is_valid: false, text: i18n.t('errorInfo.validActivationCode') }
        }

        if (code === '' || (regexPW.test(code) && code.length <= 255)) {
            return { is_valid: true, text: '' }
        }

        return { is_valid: false, text: i18n.t('errorInfo.validActivationCode') }
    },

    customertype: function (customertype) {
        if (customertype.trim().length > 0) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validCustomertype') }
        }
    },

    billingtype: function (billingtype) {
        if (billingtype.trim().length > 0) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validBillingtype') }
        }
    },

    margin: function (validationArr, marginArr, maxValue = 100000) {
        let overallValidation = true

        marginArr.forEach((object, objIndex) => {
            object.data.forEach((element, index) => {
                const marginNum = functions.toNumber(element.margin)

                if (marginNum !== undefined) {
                    if (marginNum >= 0) {
                        if (marginNum <= maxValue) {
                            validationArr[objIndex][index].is_valid = true
                            validationArr[objIndex][index].text = ''
                        } else {
                            validationArr[objIndex][index].is_valid = false
                            validationArr[objIndex][index].text = i18n.t('errorInfo.validMaxNumber', { max: maxValue })
                            overallValidation = false
                        }
                    } else {
                        validationArr[objIndex][index].is_valid = false
                        validationArr[objIndex][index].text = i18n.t('errorInfo.validMarginPositive')
                        overallValidation = false
                    }
                } else {
                    validationArr[objIndex][index].is_valid = false
                    validationArr[objIndex][index].text = i18n.t('errorInfo.validMarginNumber')
                    overallValidation = false
                }
            })
        })

        return { validationArr, overallValidation }
    },

    priceArr: function (validationArr, priceArr, maxValue = 100000) {
        let overallValidation = true

        priceArr.forEach((object, objIndex) => {
            object.data.forEach((element, index) => {
                const priceNum = functions.toNumber(element.price)

                if (priceNum !== undefined) {
                    if (priceNum >= 0) {
                        if (priceNum <= maxValue) {
                            validationArr[objIndex][index].is_valid = true
                            validationArr[objIndex][index].text = ''
                        } else {
                            validationArr[objIndex][index].is_valid = false
                            validationArr[objIndex][index].text = i18n.t('errorInfo.validMaxNumber', { max: maxValue })
                            overallValidation = false
                        }
                    } else {
                        validationArr[objIndex][index].is_valid = false
                        validationArr[objIndex][index].text = i18n.t('errorInfo.validPricePositive')
                        overallValidation = false
                    }
                } else {
                    validationArr[objIndex][index].is_valid = false
                    validationArr[objIndex][index].text = i18n.t('errorInfo.validPriceNumber')
                    overallValidation = false
                }
            })
        })

        return { validationArr, overallValidation }
    },

    priceMargin: function (validationArr, marginArr, priceArr, priceObjName, role = 'admin', marginObjName, checkPositive = true, maxValue = 100000) {
        try {
            let overallValidation = true

            const marginObj = (marginObjName !== undefined) ? marginObjName : 'margin'

            marginArr.forEach((element, index) => {
                const marginNum = functions.toNumber(element)

                if (marginNum !== undefined) {
                    if (checkPositive) {
                        if (marginNum >= 0 && marginNum <= maxValue) {
                            validationArr[marginObj][index].is_valid = true
                            validationArr[marginObj][index].text = ''
                        } else {
                            if (marginNum < 0) {
                                validationArr[marginObj][index].is_valid = false
                                validationArr[marginObj][index].text = i18n.t('errorInfo.validMarginPositive')
                                overallValidation = false
                            } else {
                                validationArr[marginObj][index].is_valid = false
                                validationArr[marginObj][index].text = i18n.t('errorInfo.validMaxNumber', { max: maxValue })
                                overallValidation = false
                            }
                        }
                    } else {
                        if (marginNum >= -maxValue && marginNum <= maxValue) {
                            validationArr[marginObj][index].is_valid = true
                            validationArr[marginObj][index].text = ''
                        } else {
                            validationArr[marginObj][index].is_valid = false
                            validationArr[marginObj][index].text = i18n.t('errorInfo.validRangeNumber', { min: -maxValue, max: maxValue })
                            overallValidation = false
                        }
                    }
                } else {
                    validationArr[marginObj][index].is_valid = false
                    validationArr[marginObj][index].text = i18n.t('errorInfo.validMarginNumber')
                    overallValidation = false
                }
            })

            if (role === 'admin') {
                priceArr.forEach((element, index) => {
                    const priceNum = functions.toNumber(element)

                    if (priceNum !== undefined) {
                        if (checkPositive) {
                            if (priceNum >= 0 && priceNum <= maxValue) {
                                validationArr[priceObjName][index].is_valid = true
                                validationArr[priceObjName][index].text = ''
                            } else {
                                if (priceNum < 0) {
                                    validationArr[priceObjName][index].is_valid = false
                                    validationArr[priceObjName][index].text = i18n.t('errorInfo.validPricePositive')
                                    overallValidation = false
                                } else {
                                    validationArr[priceObjName][index].is_valid = false
                                    validationArr[priceObjName][index].text = i18n.t('errorInfo.validMaxNumber', { max: maxValue })
                                    overallValidation = false
                                }
                            }
                        } else {
                            if (priceNum >= -maxValue && priceNum <= maxValue) {
                                validationArr[priceObjName][index].is_valid = true
                                validationArr[priceObjName][index].text = ''
                            } else {
                                validationArr[priceObjName][index].is_valid = false
                                validationArr[priceObjName][index].text = i18n.t('errorInfo.validRangeNumber', { min: -maxValue, max: maxValue })
                                overallValidation = false
                            }
                        }
                    } else {
                        validationArr[priceObjName][index].is_valid = false
                        validationArr[priceObjName][index].text = i18n.t('errorInfo.validPriceNumber')
                        overallValidation = false
                    }
                })
            }

            return overallValidation
        } catch (err) {
            console.log(err)
            return false
        }
    },

    valuation: function (valuationArr, keysValidationArr, validations, consumption, production, maxValue = 100000) {
        let overallValidation = true

        for (let j = 0; j < keysValidationArr.length; j++) {
            const maxValueValuation = (keysValidationArr[j].maxValue === undefined) ? maxValue : keysValidationArr[j].maxValue
            const key = keysValidationArr[j].key
            const direction = keysValidationArr[j].direction
            const checkPositive = keysValidationArr[j].positive

            for (let i = 0; i < valuationArr.length; i++) {
                if (
                    (direction === 'CONS' && consumption === true) ||
                    (direction === 'PROD' && production === true) ||
                    direction === undefined
                ) {
                    const value = valuationArr[i][key]
                    const valueNum = functions.toNumber(value)

                    if (valueNum !== undefined) {
                        if (checkPositive) {
                            if (valueNum >= 0 && valueNum <= maxValueValuation) {
                                validations[key][i].is_valid = true
                                validations[key][i].text = ''
                            } else {
                                if (valueNum < 0) {
                                    validations[key][i].is_valid = false
                                    validations[key][i].text = i18n.t('errorInfo.validPricePositive')
                                    overallValidation = false
                                } else {
                                    validations[key][i].is_valid = false
                                    validations[key][i].text = i18n.t('errorInfo.validMaxNumber', { max: maxValueValuation })
                                    overallValidation = false
                                }
                            }
                        } else {
                            if (valueNum >= -maxValueValuation && valueNum <= maxValueValuation) {
                                validations[key][i].is_valid = true
                                validations[key][i].text = ''
                            } else {
                                validations[key][i].is_valid = false
                                validations[key][i].text = i18n.t('errorInfo.validRangeNumber', { min: -maxValueValuation, max: maxValueValuation })
                                overallValidation = false
                            }
                        }
                    } else {
                        validations[key][i].is_valid = false
                        validations[key][i].text = i18n.t('errorInfo.validPriceNumber')
                        overallValidation = false
                    }
                }
            }
        }

        return overallValidation
    },

    customerId: function (customerId) {
        if (customerId.trim() === '') return { is_valid: false, text: i18n.t('errorInfo.validCustomer') }

        if (functions.toInteger(customerId.trim()) !== undefined && !(customerId.trim().includes('+') ||
            customerId.trim().includes('-') ||
            customerId.trim().includes('\''))) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validCustomerId') }
        }
    },

    selectedYears: function (selectedYears) {
        if (selectedYears.length > 0) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validSelectedYears') }
        }
    },

    currency: function (currency) {
        if (currency.trim().length > 0) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validCurrency') }
        }
    },

    offerid: function (offerid) {
        if (functions.toInteger(offerid) !== undefined) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validOfferId') }
        }
    },

    offerDate: function (offerDate) {
        if (offerDate.trim().length > 0) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validOfferDate') }
        }
    },

    actualYearSupplyStart: function (actualYearSupplyStart, actualYearSupply, offerDate, dayDiffOfferSupply) {
        if (actualYearSupply === true) {
            if (offerDate !== '') {
                if (actualYearSupplyStart.trim().length > 0) {
                    if ((dateFunctionsAdd.ISODateToDate(actualYearSupplyStart)).getTime() >= (dateFunctionsAdd.addDays(offerDate, dayDiffOfferSupply)).getTime()) {
                        return { is_valid: true, text: '' }
                    } else {
                        return { is_valid: false, text: i18n.t('errorInfo.validActualYearSupplyOfferDiff', { days: dayDiffOfferSupply }) }
                    }
                } else {
                    return { is_valid: false, text: i18n.t('errorInfo.validActualYearSupplyStart') }
                }
            } else {
                return { is_valid: true, text: '' }
            }
        } else {
            return { is_valid: true, text: '' }
        }
    },

    offerHour: function (offerHour) {
        if (offerHour.trim().length > 0) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validOfferHour') }
        }
    },

    offerMinute: function (offerMinute) {
        if (offerMinute.trim().length > 0) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validOfferMinute') }
        }
    },

    offerValidityPeriod: function (offerValidityPeriod) {
        if (offerValidityPeriod.trim().length > 0) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validOfferValidityPeriod') }
        }
    },

    histRangeStart: function (histRangeStart) {
        if (histRangeStart.trim().length > 0) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validHistRangeStart') }
        }
    },

    histRangeEnd: function (histRangeEnd) {
        if (histRangeEnd.trim().length > 0) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validHistRangeEnd') }
        }
    },

    dateRange: function (start, end, minDays = 365) {
        const startDate = dateFunctionsAdd.ISODateToDate(start)
        const endDate = dateFunctionsAdd.ISODateToDate(end)

        if (startDate === undefined || endDate === undefined) return { is_valid: true, text: '' }

        const checkDate = dateFunctionsAdd.addDays(start, minDays - 1)
        if (endDate.getTime() < checkDate.getTime()) return { is_valid: false, text: i18n.t('errorInfo.validHistRangeMinDays', { minDays }) }

        return { is_valid: true, text: '' }
    },

    yearlyConsumption: function (yearlyConsumption, ownConsumption, maxValue = 10000000000, minValue = 1) {
        if (ownConsumption === false) return { is_valid: true, text: '' }

        const yearlyConsumptionForm = functions.toNumber(yearlyConsumption)

        if (yearlyConsumptionForm !== undefined) {
            if (yearlyConsumptionForm > minValue && yearlyConsumptionForm <= maxValue) {
                return { is_valid: true, text: '' }
            } else {
                if (yearlyConsumption <= minValue) {
                    return { is_valid: false, text: i18n.t('errorInfo.validMinNumber', { min: minValue }) }
                } else {
                    return { is_valid: false, text: i18n.t('errorInfo.validMaxNumber', { max: maxValue }) }
                }
            }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validYearlyConsumption') }
        }
    },

    yearlyProduction: function (yearlyProduction, ownProduction, maxValue = 10000000000, minValue = 1) {
        if (ownProduction === false) return { is_valid: true, text: '' }

        const yearlyProductionForm = functions.toNumber(yearlyProduction)

        if (yearlyProductionForm !== undefined) {
            if (yearlyProductionForm > minValue & yearlyProductionForm <= maxValue) {
                return { is_valid: true, text: '' }
            } else {
                if (yearlyProductionForm <= minValue) {
                    return { is_valid: false, text: i18n.t('errorInfo.validMinNumber', { min: minValue }) }
                } else {
                    return { is_valid: false, text: i18n.t('errorInfo.validMaxNumber', { max: maxValue }) }
                }
            }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validYearlyProduction') }
        }
    },

    acceptOffer: function (decision) {
        const keys = Object.keys(decision)

        for (let i = 0; i < keys.length; i++) {
            if (decision[keys[i]].accepted === true) return { is_valid: true, text: '' }
        }

        return { is_valid: false, text: i18n.t('errorInfo.validAcceptOffer') }
    },

    acceptTermsConditions: function (decision) {
        if (decision === true) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validAcceptTermsConditions') }
        }
    },

    acceptPortalConditions: function (decision) {
        if (decision === true) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validAcceptPortalConditions') }
        }
    },

    acceptFutureProfile: function (decision) {
        if (decision === true) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validAcceptFutureProfile') }
        }
    },

    verificationcode: function (verificationcode) {
        if (functions.toInteger(verificationcode.trim()) !== undefined && verificationcode.trim().length === 6) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validVerificationCode') }
        }
    },

    captcha: function (captcha) {
        if (captcha.trim().length === 4) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validCaptcha') }
        }
    },

    authenticatorCode: function (authenticatorCode) {
        if (functions.toInteger(authenticatorCode) !== undefined && authenticatorCode.length === 6) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validAuthenticatorCode') }
        }
    },

    recoveryCode: function (recoveryCode) {
        if (recoveryCode.trim().length > 0) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validRecoveryCode') }
        }
    },

    countryCode: function (countryCode, marketarea) {
        const countriesMarketareasRules = store.getters.countriesMarketareasRules

        if (
            countriesMarketareasRules[marketarea] !== undefined &&
            countriesMarketareasRules[marketarea][countryCode] !== undefined
        ) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validCountryCode') }
        }
    },

    stateCode: function (countryCode, stateCode, marketarea) {
        const countriesMarketareasRules = store.getters.countriesMarketareasRules

        if (
            countriesMarketareasRules[marketarea] !== undefined &&
            countriesMarketareasRules[marketarea][countryCode] !== undefined &&
            countriesMarketareasRules[marketarea][countryCode][stateCode] !== undefined

        ) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validStateCode') }
        }
    },

    meteringpoint: function (meteringpoint, marketarea, country, state, checkActive = false) {
        if (marketarea === '' || country === '' || state === '') return { is_valid: true, text: '' }

        const countriesMarketareasRules = store.getters.countriesMarketareasRules
        const countriesMarketareasRulesData = countriesMarketareasRules[marketarea]?.[country]?.[state]

        if (!countriesMarketareasRulesData) return { is_valid: false, text: i18n.t('errorInfo.validMeteringCodeCountryState') }
        if (checkActive && !countriesMarketareasRulesData.active) return { is_valid: false, text: i18n.t('errorInfo.validMeteringCodeCountryState') }

        const rulesMp = countriesMarketareasRulesData.rulesMp

        let check = false
        for (let i = 0; i < rulesMp.length; i++) {
            const regex = new RegExp(rulesMp[i].regex_meteringpoint)
            if (regex.test(meteringpoint)) {
                check = true
                break
            }
        }

        if (check === true) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validMeteringCode') }
        }
    },

    meteringpointTest: function (meteringpoint) {
        if (meteringpoint !== 'CH000000000000000000000000000TEST') {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validMeteringTestCode') }
        }
    },

    meteringpointNew: function (meteringpointNew, meteringpointOld, marketarea, country, state, checkActive = false) {
        if (marketarea === '' || country === '' || state === '') return { is_valid: true, text: '' }

        const countriesMarketareasRules = store.getters.countriesMarketareasRules
        const countriesMarketareasRulesData = countriesMarketareasRules[marketarea]?.[country]?.[state]

        if (!countriesMarketareasRulesData) return { is_valid: false, text: i18n.t('errorInfo.validMeteringCodeCountryState') }
        if (checkActive && !countriesMarketareasRulesData.active) return { is_valid: false, text: i18n.t('errorInfo.validMeteringCodeCountryState') }

        const rulesMp = countriesMarketareasRulesData.rulesMp

        let check = false
        for (let i = 0; i < rulesMp.length; i++) {
            const regex = new RegExp(rulesMp[i].regex_meteringpoint)
            if (regex.test(meteringpointNew)) {
                check = true
                break
            }
        }

        if (check === true) {
            if (meteringpointNew !== meteringpointOld) {
                return { is_valid: true, text: '' }
            } else {
                return { is_valid: false, text: i18n.t('errorInfo.validMeteringCodeDiffNew') }
            }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validMeteringCode') }
        }
    },

    meteringpoints: function (meteringpoints, meteringPointTypeList, ownConsumption, ownProduction, yearlyConsumption, yearlyProduction, maxMpOffer, maxDev = 0.05) {
        try {
            if (meteringpoints.length > 0 && Array.isArray(meteringpoints)) {
                if (meteringpoints.length > maxMpOffer) return { is_valid: false, text: i18n.t('errorInfo.maxMpOffer', { limit: maxMpOffer }) }

                const idCons = meteringPointTypeList.find(item => item.keyName === 'CONS').key
                const idProd = meteringPointTypeList.find(item => item.keyName === 'PROD').key

                let yearlyConsumptionSum = 0
                let yearlyProductionSum = 0

                for (let i = 0; i < meteringpoints.length; i++) {
                    if (meteringpoints[i].meteringPointType.value === idCons) {
                        yearlyConsumptionSum += functions.toNumber(meteringpoints[i].yearlyConsumption)
                    } else if (meteringpoints[i].meteringPointType.value === idProd) {
                        yearlyProductionSum += functions.toNumber(meteringpoints[i].yearlyConsumption)
                    } else {
                        throw new Error('invalid meteringpointtype')
                    }
                }

                if (ownConsumption === true) {
                    const yearlyConsumptionNumber = functions.toNumber(yearlyConsumption)
                    if (Math.abs(yearlyConsumptionSum / yearlyConsumptionNumber - 1) > maxDev) {
                        return { is_valid: false, text: i18n.t('errorInfo.validMeteringPointsYearlyDev') }
                    }
                }

                if (ownProduction === true) {
                    const yearlyProductionNumber = functions.toNumber(yearlyProduction)
                    if (Math.abs(yearlyProductionSum / yearlyProductionNumber - 1) > maxDev) {
                        return { is_valid: false, text: i18n.t('errorInfo.validMeteringPointsYearlyProdDev') }
                    }
                }

                return { is_valid: true, text: '' }
            } else {
                return { is_valid: false, text: i18n.t('errorInfo.validMeteringPoints') }
            }
        } catch (err) {
            return { is_valid: false, text: i18n.t('errors.controlInput') }
        }
    },

    maxYear: function (maxYear) {
        if (maxYear.toString().trim().length > 0) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validYear') }
        }
    },

    deliveryFromNew: function (deliveryFromNew, cancelAllDelivery) {
        if (cancelAllDelivery === false) {
            const date = dateFunctionsAdd.ISODateToDate(deliveryFromNew)

            if (date === 'Invalid Date') {
                return { is_valid: false, text: i18n.t('errorInfo.validDeliveryFromNew') }
            } else {
                return { is_valid: true, text: '' }
            }
        } else {
            return { is_valid: true, text: '' }
        }
    },

    deliveryToNew: function (deliveryToNew, deliveryFromNew, cancelAllDelivery) {
        if (cancelAllDelivery === false) {
            const dateFrom = dateFunctionsAdd.ISODateToDate(deliveryFromNew)
            const dateTo = dateFunctionsAdd.ISODateToDate(deliveryToNew)

            if (dateTo === 'Invalid Date') {
                return { is_valid: false, text: i18n.t('errorInfo.validDeliveryToNew') }
            } else {
                if (dateFrom !== 'Invalid Date') {
                    if (dateTo.getTime() >= dateFrom.getTime()) {
                        return { is_valid: true, text: '' }
                    } else {
                        return { is_valid: false, text: i18n.t('errorInfo.validDeliveryToNewAfterBefore') }
                    }
                }
            }
        } else {
            return { is_valid: true, text: '' }
        }
    },

    deliveryFromNewMP: function (deliveryFromNewMP) {
        const date = dateFunctionsAdd.ISODateToDate(deliveryFromNewMP)

        if (date === 'Invalid Date') {
            return { is_valid: false, text: i18n.t('errorInfo.validDate') }
        } else {
            return { is_valid: true, text: '' }
        }
    },

    yearlyConsumptionNew: function (yearlyConsumptionNew, maxValue = 10000000000) {
        const yearlyConsumptionNewForm = functions.toNumber(yearlyConsumptionNew)

        if (yearlyConsumptionNewForm !== undefined) {
            if (yearlyConsumptionNewForm > 0 && yearlyConsumptionNewForm <= maxValue) {
                return { is_valid: true, text: '' }
            } else {
                if (yearlyConsumptionNewForm <= 0) {
                    return { is_valid: false, text: i18n.t('errorInfo.validPos') }
                } else {
                    return { is_valid: false, text: i18n.t('errorInfo.validMaxNumber', { max: maxValue }) }
                }
            }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validNumber') }
        }
    },

    signById: function (signById, isSignById) {
        if (isSignById === true) {
            if (functions.toInteger(signById) !== undefined) {
                return { is_valid: true, text: '' }
            } else {
                return { is_valid: false, text: i18n.t('errorInfo.validSignById') }
            }
        } else {
            return { is_valid: true, text: '' }
        }
    },

    signByName: function (signByName, isSignById) {
        if (isSignById === false) {
            if (regexValidDefault.test(signByName) && signByName.length <= 255 && signByName.trim().length > 0) {
                return { is_valid: true, text: '' }
            } else {
                return { is_valid: false, text: i18n.t('errorInfo.validSignByName') }
            }
        } else {
            return { is_valid: true, text: '' }
        }
    },

    signByPrename: function (signByPrename, isSignById) {
        if (isSignById === false) {
            if (regexValidDefault.test(signByPrename) && signByPrename.length <= 255 && signByPrename.trim().length > 0) {
                return { is_valid: true, text: '' }
            } else {
                return { is_valid: false, text: i18n.t('errorInfo.validSignByPrename') }
            }
        } else {
            return { is_valid: true, text: '' }
        }
    },

    deliveryNew: function (deliveryNew) {
        const validations = {
            start: {
                is_valid: true,
                text: '',
            },
            end: {
                is_valid: true,
                text: '',
            },
            overall: {
                is_valid: true,
                text: '',
            },
        }

        if (deliveryNew.start.isChange !== true && deliveryNew.end.isChange !== true && deliveryNew.all !== true) {
            validations.overall.is_valid = false
            validations.overall.text = i18n.t('errorInfo.validDeliveryNew')

            return validations
        }

        if (deliveryNew.all === false) {
            if (deliveryNew.start.isChange === true && deliveryNew.end.isChange === true) {
                if (dateFunctionsAdd.ISODateToDate(deliveryNew.start.date).getTime() > dateFunctionsAdd.ISODateToDate(deliveryNew.end.date).getTime()) {
                    validations.overall.is_valid = false
                    validations.overall.text = i18n.t('errors.inputError')

                    validations.start.is_valid = false
                    validations.start.text = i18n.t('errorInfo.validStartEndDate')

                    validations.end.is_valid = false
                    validations.end.text = i18n.t('errorInfo.validStartEndDate')

                    return validations
                }
            }
        }

        return validations
    },

    deliveryCancellationArr (deliveryCancellationArr) {
        let check = true

        for (let i = 0; i < deliveryCancellationArr.length; i++) {
            let validDate = true
            if (dateFunctionsAdd.checkISODate(deliveryCancellationArr[i].cancellationFrom) !== true) {
                deliveryCancellationArr[i].validationsCancellationPeriod.cancellationFrom.is_valid = false
                deliveryCancellationArr[i].validationsCancellationPeriod.cancellationFrom.text = i18n.t('errorInfo.validDate')

                validDate = false
                check = false
            }

            if (dateFunctionsAdd.checkISODate(deliveryCancellationArr[i].cancellationTo) !== true) {
                deliveryCancellationArr[i].validationsCancellationPeriod.cancellationTo.is_valid = false
                deliveryCancellationArr[i].validationsCancellationPeriod.cancellationTo.text = i18n.t('errorInfo.validDate')

                validDate = false
                check = false
            }

            if (validDate === true) {
                const timeFrom = dateFunctionsAdd.ISODateToDate(deliveryCancellationArr[i].cancellationFrom).getTime()
                const timeTo = dateFunctionsAdd.ISODateToDate(deliveryCancellationArr[i].cancellationTo).getTime()

                if (timeFrom > timeTo) {
                    deliveryCancellationArr[i].validationsCancellationPeriod.cancellationFrom.is_valid = false
                    deliveryCancellationArr[i].validationsCancellationPeriod.cancellationFrom.text = i18n.t('errorInfo.validStartEndDate')

                    deliveryCancellationArr[i].validationsCancellationPeriod.cancellationTo.is_valid = false
                    deliveryCancellationArr[i].validationsCancellationPeriod.cancellationTo.text = i18n.t('errorInfo.validStartEndDate')

                    check = false
                }
            }
        }

        if (check === false) return false

        for (let i = 1; i < deliveryCancellationArr.length; i++) {
            const timeFrom = dateFunctionsAdd.ISODateToDate(deliveryCancellationArr[i].cancellationFrom).getTime()
            const timeTo = dateFunctionsAdd.ISODateToDate(deliveryCancellationArr[i].cancellationTo).getTime()

            for (let j = 0; j < i; j++) {
                const timeFromJ = dateFunctionsAdd.ISODateToDate(deliveryCancellationArr[j].cancellationFrom).getTime()
                const timeToJ = dateFunctionsAdd.ISODateToDate(deliveryCancellationArr[j].cancellationTo).getTime()

                if (Math.max(timeFrom, timeFromJ) <= Math.min(timeTo, timeToJ)) {
                    deliveryCancellationArr[i].validationsCancellationPeriod.cancellationFrom.is_valid = false
                    deliveryCancellationArr[i].validationsCancellationPeriod.cancellationFrom.text = i18n.t('errorInfo.validIntervalDateNoOverlap')

                    deliveryCancellationArr[i].validationsCancellationPeriod.cancellationTo.is_valid = false
                    deliveryCancellationArr[i].validationsCancellationPeriod.cancellationTo.text = i18n.t('errorInfo.validIntervalDateNoOverlap')

                    deliveryCancellationArr[j].validationsCancellationPeriod.cancellationFrom.is_valid = false
                    deliveryCancellationArr[j].validationsCancellationPeriod.cancellationFrom.text = i18n.t('errorInfo.validIntervalDateNoOverlap')

                    deliveryCancellationArr[j].validationsCancellationPeriod.cancellationTo.is_valid = false
                    deliveryCancellationArr[j].validationsCancellationPeriod.cancellationTo.text = i18n.t('errorInfo.validIntervalDateNoOverlap')

                    check = false
                }
            }
        }

        return check
    },

    fulltextStreet: function (text, minLength = 3) {
        if (text.trim().length >= minLength || text === '') {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validFulltext', { minLength }) }
        }
    },

    fulltextCity: function (text, minLength = 3) {
        if (text.trim().length >= minLength || text === '') {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validFulltext', { minLength }) }
        }
    },

    fulltextCustomername: function (text, minLength = 3) {
        if (text.trim().length >= minLength || text === '') {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validFulltext', { minLength }) }
        }
    },

    rolloutFrom: function (rolloutFrom) {
        if (regexYear.test(rolloutFrom) === false) return { is_valid: false, text: i18n.t('errorInfo.validYear') }
        return { is_valid: true, text: '' }
    },

    rolloutTo: function (rolloutTo, rolloutFrom, maxYears = 3) {
        if (regexYear.test(rolloutTo) === false) return { is_valid: false, text: i18n.t('errorInfo.validYear') }

        if (regexYear.test(rolloutFrom) === false) {
            return { is_valid: true, text: '' }
        } else {
            const rolloutToInt = functions.toInteger(rolloutTo)
            const rolloutFromInt = functions.toInteger(rolloutFrom)

            if (rolloutToInt < rolloutFromInt) return { is_valid: false, text: i18n.t('errorInfo.validYearRange') }
            if (rolloutToInt - rolloutFromInt >= maxYears) return { is_valid: false, text: i18n.t('errorInfo.validYearRangeMax', { maxYears }) }

            return { is_valid: true, text: '' }
        }
    },

    minFee: function (minFee, maxFee, maxValue = 1000000000) {
        const minFeeNum = functions.toNumber(minFee)
        const maxFeeNum = functions.toNumber(maxFee)

        if (minFeeNum === undefined) return { is_valid: false, text: i18n.t('errorInfo.validNumber') }
        if (minFeeNum < 0) return { is_valid: false, text: i18n.t('errorInfo.validPos') }
        if (minFeeNum > maxValue) return { is_valid: false, text: i18n.t('errorInfo.validMaxNumber', { max: maxValue }) }
        if (minFeeNum > maxFeeNum) return { is_valid: false, text: i18n.t('errorInfo.minMaxFee') }

        return { is_valid: true, text: '' }
    },

    maxFee: function (maxFee, minFee, maxValue = 1000000000) {
        const minFeeNum = functions.toNumber(minFee)
        const maxFeeNum = functions.toNumber(maxFee)

        if (maxFeeNum === undefined) return { is_valid: false, text: i18n.t('errorInfo.validNumber') }
        if (maxFeeNum < 0) return { is_valid: false, text: i18n.t('errorInfo.validPos') }
        if (maxFeeNum > maxValue) return { is_valid: false, text: i18n.t('errorInfo.validMaxNumber', { max: maxValue }) }
        if (minFeeNum > maxFeeNum) return { is_valid: false, text: i18n.t('errorInfo.minMaxFee') }

        return { is_valid: true, text: '' }
    },

    variableFeePerMwh: function (varFee, maxValue = 100000) {
        const varFeeNum = functions.toNumber(varFee)

        if (varFeeNum === undefined) return { is_valid: false, text: i18n.t('errorInfo.validNumber') }
        if (varFeeNum < 0) return { is_valid: false, text: i18n.t('errorInfo.validPos') }
        if (varFeeNum > maxValue) return { is_valid: false, text: i18n.t('errorInfo.validMaxNumber', { max: maxValue }) }

        return { is_valid: true, text: '' }
    },

    spotFee: function (spotFee, maxValue = 1000000000) {
        const spotFeeNum = functions.toNumber(spotFee)

        if (spotFeeNum === undefined) return { is_valid: false, text: i18n.t('errorInfo.validNumber') }
        if (spotFeeNum < 0) return { is_valid: false, text: i18n.t('errorInfo.validPos') }
        if (spotFeeNum > maxValue) return { is_valid: false, text: i18n.t('errorInfo.validMaxNumber', { max: maxValue }) }

        return { is_valid: true, text: '' }
    },

    multiplicatorBuy: function (multiplicatorBuy, maxValue = 1000) {
        const multiplicatorBuyNum = functions.toNumber(multiplicatorBuy)

        if (multiplicatorBuyNum === undefined) return { is_valid: false, text: i18n.t('errorInfo.validNumber') }
        if (multiplicatorBuyNum < 0) return { is_valid: false, text: i18n.t('errorInfo.validPos') }
        if (multiplicatorBuyNum > maxValue) return { is_valid: false, text: i18n.t('errorInfo.validMaxNumber', { max: maxValue }) }

        return { is_valid: true, text: '' }
    },

    multiplicatorSell: function (multiplicatorSell, maxValue = 1000) {
        const multiplicatorSellNum = functions.toNumber(multiplicatorSell)

        if (multiplicatorSellNum === undefined) return { is_valid: false, text: i18n.t('errorInfo.validNumber') }
        if (multiplicatorSellNum < 0) return { is_valid: false, text: i18n.t('errorInfo.validPos') }
        if (multiplicatorSell > maxValue) return { is_valid: false, text: i18n.t('errorInfo.validMaxNumber', { max: maxValue }) }

        return { is_valid: true, text: '' }
    },

    price: function (price, checkPositive = false, maxValue = 100000) {
        const priceNum = functions.toNumber(price)

        if (priceNum === undefined) return { is_valid: false, text: i18n.t('errorInfo.validNumber') }
        if (priceNum < 0 && checkPositive === true) return { is_valid: false, text: i18n.t('errorInfo.validPos') }
        if (priceNum > maxValue) return { is_valid: false, text: i18n.t('errorInfo.validMaxNumber', { max: maxValue }) }

        return { is_valid: true, text: '' }
    },

    fx: function (fx, maxValue = 100000) {
        const fxNum = functions.toNumber(fx)

        if (fxNum === undefined) return { is_valid: false, text: i18n.t('errorInfo.validNumber') }
        if (fxNum < 0) return { is_valid: false, text: i18n.t('errorInfo.validPos') }
        if (fxNum > maxValue) return { is_valid: false, text: i18n.t('errorInfo.validMaxNumber', { max: maxValue }) }

        return { is_valid: true, text: '' }
    },

    procWeight: function (procWeight, maxWeight = 100) {
        const procWeightInt = functions.toInteger(procWeight)

        if (procWeightInt === undefined || procWeightInt < 0 || procWeightInt > maxWeight) {
            return { is_valid: false, text: i18n.t('errorInfo.validIntegerRange', { min: 0, max: maxWeight }) }
        }

        return { is_valid: true, text: '' }
    },

    procReleaseLimit: function (procReleaseLimit, maxValue = 100000) {
        const procReleaseLimitNum = functions.toNumber(procReleaseLimit)
        if (procReleaseLimitNum === undefined) {
            return { is_valid: false, text: i18n.t('errorInfo.validNumber') }
        }
        if (procReleaseLimitNum > maxValue || procReleaseLimitNum < -maxValue) return { is_valid: false, text: i18n.t('errorInfo.validRangeNumber', { min: -maxValue, max: maxValue }) }

        return { is_valid: true, text: '' }
    },

    volumeSelection: function (volumeSelection, consumption, production, fixedDates, isOpCalc) {
        let valid = false

        if (consumption === true) {
            if (volumeSelection.CONS.BUY === true) valid = true
            if (fixedDates === false && volumeSelection.CONS.SELL === true) valid = true
        }

        if (production === true) {
            if (volumeSelection.PROD.SELL === true) valid = true
            if (fixedDates === false && volumeSelection.PROD.BUY === true) valid = true
        }

        if (valid) return { is_valid: true, text: '' }

        if (isOpCalc) return { is_valid: false, text: i18n.t('errorInfo.validVolumeSelectionOp') }
        return { is_valid: false, text: i18n.t('errorInfo.validVolumeSelection') }
    },

    file: function (file, fileTypeCheck) {
        let fileType = file.name.split('.')
        fileType = fileType[fileType.length - 1]

        if (fileType !== fileTypeCheck) {
            return { is_valid: false, text: i18n.t('errorInfo.validFileType', { type: fileTypeCheck }) }
        }

        return { is_valid: true, text: '' }
    },

    closePercent: function (closePercent, consumption, production) {
        const result = {
            CONS: {
                is_valid: true,
                text: '',
            },
            PROD: {
                is_valid: true,
                text: '',
            },
        }

        if (consumption) {
            const percentCons = functions.toInteger(closePercent.CONS)
            if (percentCons === undefined || percentCons < 0 || percentCons > 100) {
                result.CONS.is_valid = false
                result.CONS.text = i18n.t('errorInfo.validIntegerRange', { min: 0, max: 100 })
            }
        }

        if (production) {
            const percentProd = functions.toInteger(closePercent.PROD)
            if (percentProd === undefined || percentProd < 0 || percentProd > 100) {
                result.PROD.is_valid = false
                result.PROD.text = i18n.t('errorInfo.validIntegerRange', { min: 0, max: 100 })
            }
        }

        return result
    },

    tradePartnerId: function (tradePartnerId) {
        if (functions.toInteger(tradePartnerId) !== undefined) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validOption') }
        }
    },

    tradePartnerCurrencyId: function (tradePartnerCurrencyId) {
        if (functions.toInteger(tradePartnerCurrencyId) !== undefined) {
            return { is_valid: true, text: '' }
        } else {
            return { is_valid: false, text: i18n.t('errorInfo.validOption') }
        }
    },

    externalPrice: function (externalPrice, dp) {
        dp = functions.toInteger(dp)
        if (dp === undefined) throw new Error('invalid dp')
        const minValue = -100000
        const maxValue = 100000

        const externalPriceNum = functions.toNumber(externalPrice)

        if (externalPriceNum === undefined) {
            return { is_valid: false, text: i18n.t('errorInfo.validPriceNumber') }
        }

        const externalPriceSplitDecimal = externalPrice.split('.')
        if (externalPriceSplitDecimal.length > 1 && externalPriceSplitDecimal[1].length > dp) {
            return { is_valid: false, text: i18n.t('errorInfo.validDecimalPlaces', { dec: dp }) }
        }

        if (externalPriceNum < minValue || externalPriceNum > maxValue) {
            return { is_valid: false, text: i18n.t('errorInfo.validRangeNumber', { min: minValue, max: maxValue }) }
        }

        return { is_valid: true, text: '' }
    },

})
