import navigationGuards from '../navigationGuards.js'

export default [
    {
        path: '/finance-transaction',
        component: () => import('@/views/financeTransaction/FinanceTransaction.vue'),
        beforeEnter: navigationGuards.guard,
        meta: {
            parent: 'FinanceTransaction',
        },
    },
]
