import navigationGuards from '../navigationGuards.js'

export default [
    {
        path: '/data-editor',
        name: 'Data Editor',
        component: () => import('@/views/dataEditor/dataEditorManagement.vue'),
        beforeEnter: navigationGuards.guard,
        meta: {
            parent: 'DataEditor',
        },
        children: [
            {
                path: '',
                redirect: { name: 'data-editor/issuer' },
            },
            {
                path: 'issuer',
                name: 'data-editor/issuer',
                component: () => import('@/views/dataEditor/IssuerEditor.vue'),
                beforeEnter: navigationGuards.guard,
                meta: {
                    parent: 'DataEditor',
                    breadcrumb: () => [
                        {
                            text: 'breadcrumbs.dataEditor',
                            active: true,
                        },
                        {
                            text: 'breadcrumbs.issuer',
                            to: { name: 'data-editor/issuer' },
                        },
                    ],
                },
            },
            {
                path: 'issuer/new',
                name: 'IssuerEditingNew',
                component: () => import('@/views/dataEditor/IssuerEditing.vue'),
                beforeEnter: navigationGuards.guard,
                meta: {
                    parent: 'data-editor/issuer',
                    breadcrumb: () => [
                        {
                            text: 'breadcrumbs.dataEditor',
                            active: true,
                        },
                        {
                            text: 'breadcrumbs.issuer',
                            to: { name: 'data-editor/issuer' },
                        },
                        {
                            text: 'breadcrumbs.newEntry',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'issuer/:param',
                name: 'IssuerEditing',
                component: () => import('@/views/dataEditor/IssuerEditing.vue'),
                beforeEnter: navigationGuards.guard,
                props: true,
                meta: {
                    parent: 'data-editor/issuer',
                    breadcrumb: (route) => [
                        {
                            text: 'breadcrumbs.dataEditor',
                            active: true,
                        },
                        {
                            text: 'breadcrumbs.issuer',
                            to: { name: 'data-editor/issuer' },
                        },
                        {
                            translatedText: `${route.params.param}`,
                            to: { name: 'data-editor/issuer' },
                        },
                    ],
                },
            },
            {
                path: 'customer',
                name: 'data-editor/customer',
                component: () => import('@/views/dataEditor/CustomerEditor.vue'),
                beforeEnter: navigationGuards.guard,
                meta: {
                    parent: 'DataEditor',
                    breadcrumb: () => [
                        {
                            text: 'breadcrumbs.dataEditor',
                            active: true,
                        },
                        {
                            text: 'breadcrumbs.customer',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'customer/stepper',
                name: 'CustomerStepper',
                component: () => import('@/views/dataEditor/CustomerStepper.vue'),
                beforeEnter: navigationGuards.guard,
                meta: {
                    parent: 'data-editor/customer',
                    breadcrumb: () => [
                        {
                            text: 'breadcrumbs.dataEditor',
                            active: true,
                        },
                        {
                            text: 'breadcrumbs.customer',
                            to: { name: 'data-editor/customer' },
                        },
                        {
                            text: 'breadcrumbs.newEntry',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'customer/new',
                name: 'CustomerEditingNew',
                component: () => import('@/views/dataEditor/CustomerEditing.vue'),
                beforeEnter: navigationGuards.guard,
                meta: {
                    parent: 'data-editor/customer',
                    breadcrumb: () => [
                        {
                            text: 'breadcrumbs.dataEditor',
                            active: true,
                        },
                        {
                            text: 'breadcrumbs.customer',
                            to: { name: 'data-editor/customer' },
                        },
                        {
                            text: 'breadcrumbs.newEntry',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'customer/:param',
                name: 'CustomerEditing',
                component: () => import('@/views/dataEditor/CustomerEditing.vue'),
                beforeEnter: navigationGuards.guard,
                props: true,
                meta: {
                    parent: 'data-editor/customer',
                    breadcrumb: (route) => [
                        {
                            text: 'breadcrumbs.dataEditor',
                            active: true,
                        },
                        {
                            text: 'breadcrumbs.customer',
                            to: { name: 'data-editor/customer' },
                        },
                        {
                            translatedText: `${route.params.param}`,
                            to: { name: 'data-editor/customer' },
                        },
                    ],
                },
            },
            {
                path: 'location',
                name: 'data-editor/location',
                component: () => import('@/views/dataEditor/LocationEditor.vue'),
                beforeEnter: navigationGuards.guard,
                meta: {
                    parent: 'DataEditor',
                    breadcrumb: () => [
                        {
                            text: 'breadcrumbs.dataEditor',
                            active: true,
                        },
                        {
                            text: 'breadcrumbs.location',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'location/new',
                name: 'LocationEditingNew',
                component: () => import('@/views/dataEditor/LocationEditing.vue'),
                beforeEnter: navigationGuards.guard,
                meta: {
                    parent: 'data-editor/location',
                    breadcrumb: () => [
                        {
                            text: 'breadcrumbs.dataEditor',
                            active: true,
                        },
                        {
                            text: 'breadcrumbs.location',
                            to: { name: 'data-editor/location' },
                        },
                        {
                            text: 'breadcrumbs.newEntry',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'location/:param',
                name: 'LocationEditing',
                component: () => import('@/views/dataEditor/LocationEditing.vue'),
                beforeEnter: navigationGuards.guard,
                props: true,
                meta: {
                    parent: 'data-editor/location',
                    breadcrumb: (route) => [
                        {
                            text: 'breadcrumbs.dataEditor',
                            active: true,
                        },
                        {
                            text: 'breadcrumbs.location',
                            to: { name: 'data-editor/location' },
                        },
                        {
                            translatedText: `${route.params.param}`,
                            to: { name: 'data-editor/location' },
                        },
                    ],
                },
            },
            {
                path: 'measuring-point',
                name: 'data-editor/measuring-point',
                component: () => import('@/views/dataEditor/MeasuringPointEditor.vue'),
                beforeEnter: navigationGuards.guard,
                meta: {
                    parent: 'DataEditor',
                    breadcrumb: () => [
                        {
                            text: 'breadcrumbs.dataEditor',
                            active: true,
                        },
                        {
                            text: 'breadcrumbs.measuringPoint',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'measuring-point/new',
                name: 'MeasuringPointEditingNew',
                component: () => import('@/views/dataEditor/MeasuringPointEditing.vue'),
                beforeEnter: navigationGuards.guard,
                meta: {
                    parent: 'data-editor/measuring-point',
                    breadcrumb: () => [
                        {
                            text: 'breadcrumbs.dataEditor',
                            active: true,
                        },
                        {
                            text: 'breadcrumbs.measuringPoint',
                            to: { name: 'data-editor/measuring-point' },
                        },
                        {
                            text: 'breadcrumbs.newEntry',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'measuring-point/:param',
                name: 'MeasuringPointEditing',
                component: () => import('@/views/dataEditor/MeasuringPointEditing.vue'),
                beforeEnter: navigationGuards.guard,
                props: true,
                meta: {
                    parent: 'data-editor/measuring-point',
                    breadcrumb: (route) => [
                        {
                            text: 'breadcrumbs.dataEditor',
                            active: true,
                        },
                        {
                            text: 'breadcrumbs.measuringPoint',
                            to: { name: 'data-editor/measuring-point' },
                        },
                        {
                            translatedText: `${route.params.param}`,
                            to: { name: 'data-editor/measuring-point' },
                        },
                    ],
                },
            },
        ],
    },
]
