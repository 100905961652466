<template>
  <div
    v-if="shouldShowMessage"
    class="toast mx-4"
  >
    <v-alert
      elevation="5"
      colored-border
      border="bottom"
      dense
      :color="clientMessage.type"
    >
      <div class="d-flex justify-space-between mb-4">
        <div class="d-flex flex-row">
          <v-icon
            size="24"
            class="mr-2"
            :color="clientMessage.type"
          >
            {{ clientMessage.type }}
          </v-icon>
          <h2>
            {{ $t(`ToastComponent.${clientMessage.type}`) }}
          </h2>
        </div>
        <v-icon
          size="24"
          @click="$store.dispatch('closeClientMessage')"
        >
          close
        </v-icon>
      </div>
      <div class="text-left d-flex flex-column">
        {{ $t('TOAST_MESSAGES', clientMessage) }}
        <slot />
      </div>
    </v-alert>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'ToastComponent',
    props: {
        customKey: {
            type: String,
            default: null,
        },
    },
    computed: {
        ...mapState(['clientMessage']),
        shouldShowMessage () {
            const { code, isCustomMessage } = this.clientMessage

            return (code && ((!this.customKey && !isCustomMessage) || (code === this.customKey && isCustomMessage)))
        },
    },
    watch: {
        clientMessage: {
            handler: function ({ timeOut }) {
                if (timeOut) {
                    setTimeout(() => this.$store.dispatch('closeClientMessage'), timeOut)
                }
            },
            deep: true,
        },
    },
}
</script>

<style scoped>
  .toast{
    position: fixed;
    bottom:75px;
    left:0;
    z-index: 9999;
  }
  .toast div{
    max-width:547px;
  }
</style>
