import fetchFunctions from './fetchFunctions.js'
import functions from './functions.js'
import iconv from 'iconv-lite'
import axios from 'axios'
import { apiGet } from '@/api/axiosWrapper.js'

const sleep = function (ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
}

export default ({

    downloadFileInChunks: async function (url, payload, dataType = 'text/csv', checkEOF = true, encodeAsANSI = true, toString = true) {
        try {
            if (encodeAsANSI === true && toString !== true) throw new Error('ansi encoding only possible if toString === true')

            const serializableErrorFlag = process.env.VUE_APP_SERIALIZABLE_ERROR_FLAG
            const serializableErrorRetries = functions.toInteger(process.env.VUE_APP_SERIALIZABLE_ERROR_RETRIES)
            const lengthSerializableErrorFlag = functions.toInteger(process.env.VUE_APP_SERIALIZABLE_ERROR_FLAG.length)
            const transactionMaxSleepMs = functions.toInteger(process.env.VUE_APP_SERIALIZABLE_ERROR_MAX_SLEEP_MS)
            const transactionMinSleepMs = functions.toInteger(process.env.VUE_APP_SERIALIZABLE_ERROR_MIN_SLEEP_MS)

            for (let i = 0; i < serializableErrorRetries; i++) {
                // serializable error check
                let checkSerializableErrorFlag = ''
                let isSerializableError = false

                // eof check (only for text files)
                const lengthEoFFlag = functions.toInteger(process.env.VUE_APP_EOF.length)
                let residualString = ''

                // initialize empty blob
                const chunkBlobs = []

                let response
                if (payload !== undefined) {
                    const data = payload.data
                    const contentType = payload.contentType

                    response = await fetchFunctions.fetchPost(url, data, contentType)
                } else {
                    response = await fetchFunctions.fetchGet(url)
                }

                const decoder = new TextDecoder('utf-8')
                const reader = response.body.getReader()

                let loop = true

                while (loop) {
                    // read chunk
                    const line = await reader.read()
                    const { done } = line
                    let { value } = line

                    if (done) {
                        loop = false
                    } else {
                        if (toString) {
                            // important to use stream = true --> otherwise multi-byte characters could get split
                            value = decoder.decode(value, { stream: true })
                            value = residualString + value
                            if (value.length >= lengthSerializableErrorFlag) {
                                checkSerializableErrorFlag = value.substr(-lengthSerializableErrorFlag)
                            } else {
                                checkSerializableErrorFlag += value
                                checkSerializableErrorFlag = checkSerializableErrorFlag.substr(-lengthSerializableErrorFlag)
                            }
                            if (checkSerializableErrorFlag === serializableErrorFlag) isSerializableError = true
                        } else {
                            value = new Uint8Array([...residualString, ...value])
                        }

                        // eof check preparation
                        // (always take lengthEoFFlag strings to next round, otherwise eof flag could be split)
                        if (checkEOF === true) {
                            residualString = value.slice(-lengthEoFFlag)
                            value = value.slice(0, -lengthEoFFlag)
                        }

                        // encoding
                        if (encodeAsANSI === true) {
                            value = iconv.encode(value, 'CP1252')
                        }

                        // write blob of chunk to chunkBlobs array
                        chunkBlobs.push(new Blob([value], { type: `${dataType}${(encodeAsANSI === true) ? ';charset=windows-1252' : ''}` }))
                    }
                }

                // check that no serializable error --> otherwise for loop is run again
                if (!isSerializableError) {
                    // eof check
                    if (checkEOF === true) {
                        if (toString) {
                            if (residualString !== process.env.VUE_APP_EOF) throw new Error('data not complete')
                        } else {
                            if (functions.abToStr(residualString) !== process.env.VUE_APP_EOF) throw new Error('data not complete')
                        }
                    }

                    // create final blob
                    const finalBlob = new Blob(chunkBlobs, { type: `${dataType}${(encodeAsANSI === true) ? ';charset=windows-1252' : ''}` })

                    return finalBlob
                } else {
                    console.log('serializable error, try again')
                    await sleep(transactionMinSleepMs + Math.ceil(Math.random() * (transactionMaxSleepMs - transactionMinSleepMs)))
                }
            }

            // if no download in for loop throw error
            throw new Error('data not complete')
        } catch (err) {
            console.log(err)
            throw err
        }
    },

    axiosStreamSerializable: async function (url, isArrayBuffer = false) {
        try {
            const serializableErrorFlag = process.env.VUE_APP_SERIALIZABLE_ERROR_FLAG
            const serializableErrorRetries = functions.toInteger(process.env.VUE_APP_SERIALIZABLE_ERROR_RETRIES)
            const lengthSerializableErrorFlag = functions.toInteger(process.env.VUE_APP_SERIALIZABLE_ERROR_FLAG.length)
            const transactionMaxSleepMs = functions.toInteger(process.env.VUE_APP_SERIALIZABLE_ERROR_MAX_SLEEP_MS)
            const transactionMinSleepMs = functions.toInteger(process.env.VUE_APP_SERIALIZABLE_ERROR_MIN_SLEEP_MS)

            for (let i = 0; i < serializableErrorRetries; i++) {
                let response
                if (isArrayBuffer) {
                    response = await axios({
                        method: 'get',
                        url,
                        responseType: 'arraybuffer',
                    })
                } else {
                    response = await apiGet(url)
                }

                let checkString
                if (isArrayBuffer) {
                    checkString = functions.abToStr(response.data.slice(-lengthSerializableErrorFlag))
                } else {
                    checkString = response.data.substr(-lengthSerializableErrorFlag)
                }

                if (checkString !== serializableErrorFlag) {
                    return response
                } else {
                    console.log('serializable error, try again')
                    await sleep(transactionMinSleepMs + Math.ceil(Math.random() * (transactionMaxSleepMs - transactionMinSleepMs)))
                }
            }

            throw new Error('data not complete')
        } catch (err) {
            console.log(err)
            throw err
        }
    },

    checkCompleteFileArrayBuffer: function (arrayBuffer) {
        const eofFlag = process.env.VUE_APP_EOF
        const lengthEoFFlag = eofFlag.length

        if (functions.abToStr(arrayBuffer.slice(-lengthEoFFlag)) !== eofFlag) throw new Error('download not complete')

        return arrayBuffer.slice(0, -lengthEoFFlag)
    },

    checkCompleteFileText: function (text) {
        const eofFlag = process.env.VUE_APP_EOF
        const lengthEoFFlag = eofFlag.length

        if (text.slice(-lengthEoFFlag) !== eofFlag) throw new Error('download not complete')

        return text.slice(0, -lengthEoFFlag)
    },

})
