
import Vue from 'vue'
import Highcharts from 'highcharts'
import HighchartsVue from 'highcharts-vue'
import Stock from 'highcharts/modules/stock'
import Export from 'highcharts/modules/exporting'

import boost from 'highcharts/modules/boost'
Stock(Highcharts)
Export(Highcharts)
Vue.use(HighchartsVue, { highcharts: Highcharts })
boost(Highcharts)

export default Highcharts
