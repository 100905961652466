<template>
  <div>
    <v-tooltip
      v-if="showTooltip && initials"
      top
    >
      <template #activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
        >
          <v-avatar
            class="cursor-pointer avatar-color"
            :size="size"
          >
            <h4 class="primary--text text-uppercase">
              {{ trimmedText }}
            </h4>
          </v-avatar>
        </div>
      </template>
      <span>{{ initials }}</span>
    </v-tooltip>

    <v-avatar
      v-if="!showTooltip && initials"
      class="cursor-pointer avatar-color"
      :size="size"
    >
      <h4 class="primary--text text-uppercase">
        {{ trimmedText }}
      </h4>
    </v-avatar>
  </div>
</template>
<script>
export default {
    name: 'UserAvatarComponent',

    props: {
        size: {
            type: String,
            default: '32',
        },
        showTooltip: {
            type: Boolean,
            default: true,
        },
        initials: {
            type: String,
            default: '',
        },
        isNumber: {
            type: Boolean,
        },
    },
    computed: {
        trimmedText () {
            const notAllowedCharacters = /\(|\)/g
            const cleanedText = this.initials.replaceAll(notAllowedCharacters, '').trim()
            return this.isNumber
                ? cleanedText
                : cleanedText.split(' ').map(word => word.charAt(0)).join('')
        },
    },
}
</script>
<style scoped>
.avatar-color {
background: var(--design-color-opacity-ten);
}
</style>
