import navigationGuards from '../navigationGuards.js'

export default [
    {
        path: '/lauf-erstellung',
        component: () => import('@/views/laufErstellung/LaufErstellung.vue'),
        beforeEnter: navigationGuards.guard,
        meta: {
            parent: 'LaufErstellung',
        },
        children: [
            {
                path: 'future',
                name: 'market/future',
                component: () => import('@/views/market/Market.vue'),
                beforeEnter: navigationGuards.guard,
                meta: {
                    parent: 'Market',
                    breadcrumb: () => [
                        {
                            text: 'breadcrumbs.market',
                            to: { name: 'market/future' },
                        },
                        {
                            text: 'breadcrumbs.future',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'spot',
                name: 'market/spot',
                component: () => import('@/views/market/MarketSpot.vue'),
                beforeEnter: navigationGuards.guard,
                meta: {
                    parent: 'Market',
                    breadcrumb: () => [
                        {
                            text: 'breadcrumbs.market',
                            to: { name: 'market/future' },
                        },
                        {
                            text: 'breadcrumbs.spot',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'exchange',
                name: 'market/exchange',
                component: () => import('@/views/market/MarketExchangeRate.vue'),
                beforeEnter: navigationGuards.guard,
                meta: {
                    parent: 'Market',
                    breadcrumb: () => [
                        {
                            text: 'breadcrumbs.market',
                            to: { name: 'market/future' },
                        },
                        {
                            text: 'breadcrumbs.exchange',
                            active: true,
                        },
                    ],
                },
            },
        ],
    },
]
