
import store from '../vuex/store.js'

export default ({

    guard (to, from, next) {
        console.log('guard')
        if (store.getters.isLoggedIn) {
            next()
        } else {
            const encodedRedirectUrl = encodeURIComponent(to.fullPath)

            next(`/login?redirect=${encodedRedirectUrl}`)
        }
    },

    guardAdmin (to, from, next) {
        console.log('guard')
        if (store.getters.isLoggedIn) {
            if (store.state.role === 'admin') {
                next()
            } else {
                next('/dashboard')
            }
        } else {
            next('/login')
        }
    },

    guardSalesPartner (to, from, next) {
        console.log('guard')
        if (store.getters.isLoggedIn) {
            if (store.state.role === 'salesPartner') {
                next()
            } else {
                next('/dashboard')
            }
        } else {
            next('/login')
        }
    },

    guardAdminOrSalesPartner (to, from, next) {
        console.log('guard')
        if (store.getters.isLoggedIn) {
            if (store.state.role === 'admin' || store.state.role === 'salesPartner') {
                next()
            } else {
                next('/dashboard')
            }
        } else {
            next('/login')
        }
    },

    guardSalesPartnerCustomer: (to, from, next) => {
        console.log('guard')
        if (store.getters.isLoggedIn) {
            if (store.state.role === 'salesPartner' || store.state.role === 'customer') {
                next()
            } else {
                next('/dashboard')
            }
        } else {
            next('/login')
        }
    },

    guardSalesPartnerCustomerFirm (to, from, next) {
        console.log('guard')
        if (store.getters.isLoggedIn) {
            if (store.state.role === 'salesPartner' || store.state.customertype === 'FI') {
                next()
            } else {
                next('/dashboard')
            }
        } else {
            next('/login')
        }
    },

    guardCustomerFirm (to, from, next) {
        console.log('guard')
        if (store.getters.isLoggedIn) {
            if (store.state.role === 'customer' && store.state.customertype === 'FI') {
                next()
            } else {
                next('/dashboard')
            }
        } else {
            next('/login')
        }
    },

    guardCustomerPrivate (to, from, next) {
        console.log('guard')
        if (store.getters.isLoggedIn) {
            if (store.state.role === 'customer' && store.state.customertype === 'PR') {
                next()
            } else {
                next('/dashboard')
            }
        } else {
            next('/login')
        }
    },
})
