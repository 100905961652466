import navigationGuards from '../navigationGuards.js'

export default [
    {
        path: '/portfolio-management',
        component: () => import('@/views/portfoliomanagement/PortfolioManagement.vue'),
        beforeEnter: navigationGuards.guard,
        children: [
            {
                path: '',
                redirect: { name: 'portfolio-management-frame-contracts' },
            },
            {
                path: 'frame-contracts',
                name: 'portfolio-management-frame-contracts',
                component: () => import('@/views/procurement/offers/OffersList.vue'),
                beforeEnter: navigationGuards.guard,
                props: true,
                meta: {
                    parent: 'PortfolioManagement',
                    breadcrumb: () => [
                        {
                            text: 'breadcrumbs.portfoliomanagement',
                            to: { name: 'portfolio-management-frame-contracts' },
                        },
                        {
                            text: 'breadcrumbs.contracts',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'profile',
                name: 'portfolio-management-profile',
                component: () => import('@/views/procurement/strategies/StrategiesList.vue'),
                beforeEnter: navigationGuards.guard,
                props: true,
                meta: {
                    parent: 'PortfolioManagement',
                    breadcrumb: () => [
                        {
                            text: 'breadcrumbs.portfoliomanagement',
                            to: { name: 'portfolio-management-frame-contracts' },
                        },
                        {
                            text: 'breadcrumbs.profile',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'strategies-list-tranches',
                name: 'portfolio-management-strategies-list-tranches',
                component: () => import('@/views/portfoliomanagement/strategiesListTranches/StrategiesListWithTranches.vue'),
                beforeEnter: navigationGuards.guard,
                meta: {
                    parent: 'PortfolioManagement',
                    fromComponent: 'portfolio-management-strategies-list-tranches',
                    breadcrumb: () => [
                        {
                            text: 'breadcrumbs.portfoliomanagement',
                            to: { name: 'portfolio-management-frame-contracts' },
                        },
                        {
                            text: 'breadcrumbs.strategiesListTranches',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'monthly-overviews',
                name: 'portfolio-management-monthly-overviews',
                component: () => import('@/views/portfoliomanagement/monthlyOverview/MonthlyOverview.vue'),
                beforeEnter: navigationGuards.guardAdminOrSalesPartner,
                meta: {
                    parent: 'PortfolioManagement',
                    breadcrumb: () => [
                        {
                            text: 'breadcrumbs.portfoliomanagement',
                            to: { name: 'portfolio-management-frame-contracts' },
                        },
                        {
                            text: 'breadcrumbs.monthlyOverview',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'monthly-overviews/:aggregationId/:year/:month/:offerId/metering-point/:aggregationDetailId',
                name: 'portfolio-management-monthly-overview-metering-point',
                component: () => import('@/views/portfoliomanagement/monthlyOverview/MonthlyOverviewMeteringPoints.vue'),
                beforeEnter: navigationGuards.guardAdminOrSalesPartner,
                meta: {
                    parent: 'PortfolioManagement',
                    breadcrumb: (route) => [
                        {
                            text: 'breadcrumbs.portfoliomanagement',
                            to: { name: 'portfolio-management-frame-contracts' },
                        },
                        {
                            text: 'breadcrumbs.monthlyOverview',
                            to: { name: 'portfolio-management-monthly-overviews' },
                        },
                        {
                            translatedText: `${route.params.year} - ${route.params.month}`,
                            to: { name: 'portfolio-management-monthly-overviews' },
                        },
                        {
                            translatedText: `${route.params.offerId}`,
                            to: { name: 'portfolio-management-monthly-overviews' },
                        },
                        {
                            text: 'breadcrumbs.monthlyOverviewMeteringPoints',
                            active: true,
                        },
                    ],
                },
                props: true,
            },
            {
                path: 'monthly-overviews/:aggregationId/:year/:month/:offerId/price-volume/:aggregationDetailId',
                name: 'portfolio-management-monthly-overview-price-volume',
                component: () => import('@/views/portfoliomanagement/monthlyOverview/PriceVolume.vue'),
                beforeEnter: navigationGuards.guardAdminOrSalesPartner,
                meta: {
                    parent: 'PortfolioManagement',
                    breadcrumb: (route) => [
                        {
                            text: 'breadcrumbs.portfoliomanagement',
                            to: { name: 'portfolio-management-frame-contracts' },
                        },
                        {
                            text: 'breadcrumbs.monthlyOverview',
                            to: { name: 'portfolio-management-monthly-overviews' },
                        },
                        {
                            translatedText: `${route.params.year} - ${route.params.month}`,
                            to: { name: 'portfolio-management-monthly-overviews' },
                        },
                        {
                            translatedText: `${route.params.offerId}`,
                            to: { name: 'portfolio-management-monthly-overviews' },
                        },
                        {
                            text: 'breadcrumbs.monthlyOverviewPriceVolume',
                            active: true,
                        },
                    ],
                },
                props: true,
            },
            {
                path: 'tools',
                name: 'portfolio-management-tools',
                component: () => import('@/views/portfoliomanagement/tools/Tools.vue'),
                beforeEnter: navigationGuards.guardAdmin,
                meta: {
                    parent: 'PortfolioManagement',
                    breadcrumb: () => [
                        {
                            text: 'breadcrumbs.portfoliomanagement',
                            to: { name: 'portfolio-management-frame-contracts' },
                        },
                        {
                            text: 'breadcrumbs.tools',
                            active: true,
                        },
                    ],
                },
            },
        ],
    },
]
