import navigationGuards from '../navigationGuards.js'
import store from '@/vuex/store.js'

const roleToUrlMap = {
    admin: '/customer-management/sales-partner',
    salesPartner: '/customer-management/customers',
    customer: '/customer-management/meteringpoints',
}
const roleBreadcrumbMap = () => {
    const { role } = store.state
    if (role === 'admin') {
        return 'breadcrumbs.salesPartnerMenu'
    }
    if (role === 'salesPartner') {
        return 'breadcrumbs.customerMenu'
    }
    if (role === 'customer') {
        return 'breadcrumbs.meteringpointsMenu'
    }
}

export default [
    {
        path: '/customer-management',
        component: () => import('@/views/customerManagement/CustomerManagement.vue'),
        beforeEnter: navigationGuards.guard,
        children: [
            {
                path: '',
                beforeEnter: (to, from, next) => {
                    const { role } = store.state
                    const targetUrl = roleToUrlMap[role]
                    next(targetUrl)
                },
            },
            {
                path: 'sales-partner',
                name: 'customer-management-sales-partner',
                component: () => import('@/views/customerManagement/salesPartner/SalesPartnerList.vue'),
                beforeEnter: navigationGuards.guardAdmin,
                meta: {
                    parent: 'CustomersSalesPartner',
                    breadcrumb: () => [
                        {
                            text: roleBreadcrumbMap(),
                            to: '/customer-management',
                        },
                        {
                            text: 'breadcrumbs.salesPartnerManagement',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'sales-partner/new',
                name: 'customer-management-new-sales-partner',
                component: () => import('@/views/customerManagement/salesPartner/SalesPartnerCard.vue'),
                beforeEnter: navigationGuards.guardAdmin,
                meta: {
                    parent: 'CustomersSalesPartner',
                    breadcrumb: () => [
                        {
                            text: roleBreadcrumbMap(),
                            to: '/customer-management',
                        },
                        {
                            text: 'breadcrumbs.salesPartnerManagement',
                            to: { name: 'customer-management-sales-partner' },
                        },
                        {
                            text: 'breadcrumbs.newSalesPartner',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'sales-partner/:salesPartnerId/edit',
                name: 'customer-management-edit-sales-partner',
                component: () => import('@/views/customerManagement/salesPartner/SalesPartnerCard.vue'),
                beforeEnter: navigationGuards.guardAdmin,
                meta: {
                    parent: 'CustomersSalesPartner',
                    breadcrumb: (route) => [
                        {
                            text: roleBreadcrumbMap(),
                            to: '/customer-management',
                        },
                        {
                            text: 'breadcrumbs.salesPartnerManagement',
                            translatedText: `(Id: ${route.params.salesPartnerId})`,
                            to: { name: 'customer-management-sales-partner' },
                        },
                        {
                            text: 'breadcrumbs.editSalesPartner',
                            active: true,
                        },
                    ],
                },
                props: true,
            },
            {
                path: 'sales-partner/:salesPartnerId/test-mail',
                name: 'customer-management-sales-partner-test-mail',
                component: () => import('@/views/customerManagement/salesPartner/TestMailCard.vue'),
                beforeEnter: navigationGuards.guardAdmin,
                meta: {
                    parent: 'CustomersSalesPartner',
                    breadcrumb: (route) => [
                        {
                            text: roleBreadcrumbMap(),
                            to: '/customer-management',
                        },
                        {
                            text: 'breadcrumbs.salesPartnerManagement',
                            translatedText: `(Id: ${route.params.salesPartnerId})`,
                            to: { name: 'customer-management-sales-partner' },
                        },
                        {
                            text: 'breadcrumbs.testMailSalesPartner',
                            active: true,
                        },
                    ],
                },
                props: true,
            },
            {
                path: 'sales-partner/:salesPartnerId/document-template',
                name: 'customer-management-sales-partner-document-template',
                component: () => import('@/views/customerManagement/salesPartner/TemplatesCardAdminSP.vue'),
                beforeEnter: navigationGuards.guardAdmin,
                meta: {
                    parent: 'CustomersSalesPartner',
                    breadcrumb: (route) => [
                        {
                            text: roleBreadcrumbMap(),
                            to: '/customer-management',
                        },
                        {
                            text: 'breadcrumbs.salesPartnerManagement',
                            translatedText: `(Id: ${route.params.salesPartnerId})`,
                            to: { name: 'customer-management-sales-partner' },
                        },
                        {
                            text: 'breadcrumbs.documentTemplateSalesPartner',
                            active: true,
                        },
                    ],
                },
                props: true,
            },
            {
                path: 'sales-partner/:salesPartnerId/monthly-overview',
                name: 'customer-management-sales-partner-monthly-overview',
                component: () => import('@/views/portfoliomanagement/monthlyOverview/MonthlyOverview.vue'),
                beforeEnter: navigationGuards.guardAdmin,
                meta: {
                    parent: 'CustomersSalesPartner',
                    breadcrumb: (route) => [
                        {
                            text: roleBreadcrumbMap(),
                            to: '/customer-management',
                        },
                        {
                            text: 'breadcrumbs.salesPartnerManagement',
                            translatedText: `(Id: ${route.params.salesPartnerId})`,
                            to: { name: 'customer-management-sales-partner' },
                        },
                        {
                            text: 'breadcrumbs.monthlyOverviewSalesPartner',
                            active: true,
                        },
                    ],
                },
                props: true,
            },
            {
                path: 'sales-partner/:salesPartnerId/monthly-overview/:aggregationId/:year/:month/metering-point/:aggregationDetailId',
                name: 'customer-management-sales-partner-monthly-overview-metering-point',
                component: () => import('@/views/portfoliomanagement/monthlyOverview/MonthlyOverviewMeteringPoints.vue'),
                beforeEnter: navigationGuards.guardAdminOrSalesPartner,
                meta: {
                    parent: 'PortfolioManagement',
                    breadcrumb: (route) => [
                        {
                            text: roleBreadcrumbMap(),
                            to: '/customer-management',
                        },
                        {
                            text: 'breadcrumbs.salesPartnerManagement',
                            translatedText: `(Id: ${route.params.salesPartnerId})`,
                            to: { name: 'customer-management-sales-partner' },
                        },
                        {
                            text: 'breadcrumbs.monthlyOverviewSalesPartner',
                            translatedText: `(${route.params.year}/${route.params.month})`,
                            to: { name: 'customer-management-sales-partner-monthly-overview' },
                        },
                        {
                            text: 'breadcrumbs.monthlyOverviewMeteringPoints',
                            translatedText: `(Id: ${route.params.aggregationDetailId})`,
                            active: true,
                        },
                    ],
                },
                props: true,
            },
            {
                path: 'sales-partner/:salesPartnerId/monthly-overview/:aggregationId/:year/:month/price-volume/:aggregationDetailId',
                name: 'customer-management-sales-partner-monthly-overview-price-volume',
                component: () => import('@/views/portfoliomanagement/monthlyOverview/PriceVolume.vue'),
                beforeEnter: navigationGuards.guardAdminOrSalesPartner,
                meta: {
                    parent: 'PortfolioManagement',
                    breadcrumb: (route) => [
                        {
                            text: roleBreadcrumbMap(),
                            to: '/customer-management',
                        },
                        {
                            text: 'breadcrumbs.salesPartnerManagement',
                            translatedText: `(Id: ${route.params.salesPartnerId})`,
                            to: { name: 'customer-management-sales-partner' },
                        },
                        {
                            text: 'breadcrumbs.monthlyOverviewSalesPartner',
                            translatedText: `(${route.params.year}/${route.params.month})`,
                            to: { name: 'customer-management-sales-partner-monthly-overview' },
                        },
                        {
                            text: 'breadcrumbs.monthlyOverviewPriceVolume',
                            translatedText: `(Id: ${route.params.aggregationDetailId})`,
                            active: true,
                        },
                    ],
                },
                props: true,
            },
            {
                path: 'sales-partner/:userParentId/users',
                name: 'customer-management-sales-partner-user-list',
                component: () => import('@/components/generalDialogs/UserCard.vue'),
                beforeEnter: navigationGuards.guardAdmin,
                meta: {
                    parent: 'CustomersSalesPartner',
                    breadcrumb: (route) => [
                        {
                            text: roleBreadcrumbMap(),
                            to: '/customer-management',
                        },
                        {
                            text: 'breadcrumbs.salesPartnerManagement',
                            to: { name: 'customer-management-sales-partner' },
                        },
                        {
                            text: 'breadcrumbs.salesPartnerUsers',
                            translatedText: `(Id: ${route.params.userParentId})`,
                            active: true,
                        },
                    ],
                },
                props: true,
            },
            {
                path: 'sales-partner/:userParentId/users/new',
                name: 'customer-management-sales-partner-new-user',
                component: () => import('@/components/generalDialogs/SetUserCard.vue'),
                beforeEnter: navigationGuards.guardAdmin,
                meta: {
                    parent: 'CustomersSalesPartner',
                    breadcrumb: (route) => [
                        {
                            text: roleBreadcrumbMap(),
                            to: '/customer-management',
                        },
                        {
                            text: 'breadcrumbs.salesPartnerManagement',
                            to: { name: 'customer-management-sales-partner' },
                        },
                        {
                            text: 'breadcrumbs.salesPartnerUsers',
                            translatedText: `(Id: ${route.params.userParentId})`,
                            to: { name: 'customer-management-sales-partner-user-list' },
                        },
                        {
                            text: 'breadcrumbs.salesPartnerNewUser',
                            active: true,
                        },
                    ],
                },
                props: true,
            },
            {
                path: 'sales-partner/:salesPartnerId/design',
                name: 'customer-management-sales-partner-design',
                component: () => import('@/components/DesignCard.vue'),
                beforeEnter: navigationGuards.guardAdmin,
                meta: {
                    parent: 'CustomersSalesPartner',
                    breadcrumb: (route) => [
                        {
                            text: roleBreadcrumbMap(),
                            to: '/customer-management',
                        },
                        {
                            text: 'breadcrumbs.salesPartnerManagement',
                            to: { name: 'customer-management-sales-partner' },
                        },
                        {
                            text: 'breadcrumbs.design',
                            translatedText: `(Id: ${route.params.salesPartnerId})`,
                            active: true,
                        },
                    ],
                },
                props: true,
            },
            {
                path: 'customers',
                name: 'customer-management-customers',
                component: () => import('@/views/customerManagement/CustomersList.vue'),
                beforeEnter: navigationGuards.guardSalesPartner,
                meta: {
                    parent: 'CustomersSalesPartner',
                    breadcrumb: () => [
                        {
                            text: roleBreadcrumbMap(),
                            to: '/customer-management',
                        },
                        {
                            text: 'breadcrumbs.customer',
                            active: true,
                        },
                    ],
                },
                props: true,
            },
            {
                path: 'customers/:userParentId/users/:customerType',
                name: 'customer-management-customers-users',
                component: () => import('@/components/generalDialogs/UserCard.vue'),
                beforeEnter: navigationGuards.guardSalesPartner,
                meta: {
                    parent: 'CustomersSalesPartner',
                    breadcrumb: (route) => [
                        {
                            text: roleBreadcrumbMap(),
                            to: '/customer-management',
                        },
                        {
                            text: 'breadcrumbs.customer',
                            translatedText: `(Id: ${route.params.userParentId})`,
                            to: { name: 'customer-management-customers' },
                        },
                        {
                            text: 'breadcrumbs.customerUsers',
                            active: true,
                        },
                    ],
                },
                props: true,
            },
            {
                path: 'customers/:userParentId/users/:customerType/new',
                name: 'customer-management-customers-new-user',
                component: () => import('@/components/generalDialogs/SetUserCard.vue'),
                beforeEnter: navigationGuards.guardSalesPartner,
                meta: {
                    parent: 'CustomersSalesPartner',
                    breadcrumb: (route) => [
                        {
                            text: roleBreadcrumbMap(),
                            to: '/customer-management',
                        },
                        {
                            text: 'breadcrumbs.customer',
                            translatedText: `(Id: ${route.params.userParentId})`,
                            to: { name: 'customer-management-customers' },
                        },
                        {
                            text: 'breadcrumbs.customerUsers',
                            to: { name: 'customer-management-customers-users' },
                        },
                        {
                            text: 'breadcrumbs.newCustomerUser',
                            active: true,
                        },
                    ],
                },
                props: true,
            },
            {
                path: 'customers/:customerId/edit',
                name: 'customer-management-edit',
                component: () => import('@/views/customerManagement/Customer.vue'),
                beforeEnter: navigationGuards.guardSalesPartner,
                meta: {
                    parent: 'CustomersSalesPartner',
                    breadcrumb: (route) => [
                        {
                            text: roleBreadcrumbMap(),
                            to: '/customer-management',
                        },
                        {
                            text: 'breadcrumbs.customer',
                            translatedText: `(Id: ${route.params.customerId})`,
                            to: { name: 'customer-management-customers' },
                        },
                        {
                            text: 'breadcrumbs.editCustomer',
                            active: true,
                        },
                    ],
                },
                props: true,
            },
            {
                path: 'customers/new',
                name: 'customer-management-new',
                component: () => import('@/views/customerManagement/Customer.vue'),
                beforeEnter: navigationGuards.guardSalesPartner,
                meta: {
                    parent: 'CustomersSalesPartner',
                    breadcrumb: () => [
                        {
                            text: roleBreadcrumbMap(),
                            to: '/customer-management',
                        },
                        {
                            text: 'breadcrumbs.customer',
                            to: { name: 'customer-management-customers' },
                        },
                        {
                            text: 'breadcrumbs.newCustomer',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'meteringpoints',
                name: 'customer-management-metering-points',
                component: () => import('@/views/customerManagement/meteringPoints/MeteringPoints.vue'),
                beforeEnter: navigationGuards.guard,
                meta: {
                    parent: 'CustomersSalesPartner',
                    breadcrumb: () => [
                        {
                            text: roleBreadcrumbMap(),
                            to: '/customer-management',
                        },
                        {
                            text: 'breadcrumbs.meteringPoints',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'meteringpoints/:meteringCode/:meteringpointTypeId/graph',
                name: 'customer-management-metering-point-graph',
                component: () => import('@/views/customerManagement/meteringPoints/MeteringPointGraph.vue'),
                beforeEnter: navigationGuards.guard,
                meta: {
                    parent: 'CustomersSalesPartner',
                    breadcrumb: (route) => [
                        {
                            text: roleBreadcrumbMap(),
                            to: '/customer-management',
                        },
                        {
                            text: route.params.meteringpointTypeId === '1'
                                ? 'breadcrumbs.meteringPointsConsuption'
                                : 'breadcrumbs.meteringPointsProduction',
                            translatedText: `(${route.params.meteringCode})`,
                            to: { name: 'customer-management-metering-points' },
                        },
                        {
                            text: 'breadcrumbs.meteringPointsGraph',
                            active: true,
                        },
                    ],
                },
                props: true,
            },
        ],
    },
    {
        path: '/customer-management/sales-partner/:userParentId/users/:userId',
        component: () => import('@/components/generalDialogs/UserTabs.vue'),
        beforeEnter: navigationGuards.guardAdmin,
        children: [
            {
                path: '',
                redirect: { name: 'customer-management-sales-partner-edit-user' },
            },
            {
                path: 'edit',
                name: 'customer-management-sales-partner-edit-user',
                component: () => import('@/components/generalDialogs/SetUserCard.vue'),
                beforeEnter: navigationGuards.guardAdmin,
                meta: {
                    parent: 'CustomersSalesPartner',
                    breadcrumb: (route) => [
                        {
                            text: roleBreadcrumbMap(),
                            to: '/customer-management',
                        },
                        {
                            text: 'breadcrumbs.salesPartnerManagement',
                            to: { name: 'customer-management-sales-partner' },
                        },
                        {
                            text: 'breadcrumbs.salesPartnerUsers',
                            translatedText: `(Id: ${route.params.userParentId})`,
                            to: { name: 'customer-management-sales-partner-user-list' },
                        },
                        {
                            text: 'breadcrumbs.salesPartnerEditUser',
                            translatedText: `(Id: ${route.params.userId})`,
                            active: true,
                        },
                    ],
                },
                props: true,
            },
            {
                path: 'change-password',
                name: 'customer-management-sales-partner-change-password-user',
                component: () => import('@/components/generalDialogs/SetUserCard.vue'),
                beforeEnter: navigationGuards.guardAdmin,
                meta: {
                    parent: 'CustomersSalesPartner',
                    breadcrumb: (route) => [
                        {
                            text: roleBreadcrumbMap(),
                            to: '/customer-management',
                        },
                        {
                            text: 'breadcrumbs.salesPartnerManagement',
                            to: { name: 'customer-management-sales-partner' },
                        },
                        {
                            text: 'breadcrumbs.salesPartnerUsers',
                            translatedText: `(Id: ${route.params.userParentId})`,
                            to: { name: 'customer-management-sales-partner-user-list' },
                        },
                        {
                            text: 'breadcrumbs.salesPartnerChangeUserPassword',
                            translatedText: `(Id: ${route.params.userId})`,
                            active: true,
                        },
                    ],
                },
                props: true,
            },
        ],
    },
    {
        path: '/customer-management/customers/:userParentId/users/:userId/:customerType',
        component: () => import('@/components/generalDialogs/UserTabs.vue'),
        beforeEnter: navigationGuards.guardSalesPartner,
        children: [
            {
                path: '',
                redirect: { name: 'customer-management-customers-edit-user' },
            },
            {
                path: 'edit',
                name: 'customer-management-customers-edit-user',
                component: () => import('@/components/generalDialogs/SetUserCard.vue'),
                beforeEnter: navigationGuards.guardSalesPartner,
                meta: {
                    parent: 'CustomersSalesPartner',
                    breadcrumb: (route) => [
                        {
                            text: roleBreadcrumbMap(),
                            to: '/customer-management',
                        },
                        {
                            text: 'breadcrumbs.customer',
                            translatedText: `(Id: ${route.params.userParentId})`,
                            to: { name: 'customer-management-customers' },
                        },
                        {
                            text: 'breadcrumbs.customerUsers',
                            translatedText: `(Id: ${route.params.userId})`,
                            to: { name: 'customer-management-customers-users' },
                        },
                        {
                            text: 'breadcrumbs.editCustomerUser',
                            active: true,
                        },
                    ],
                },
                props: true,
            },
            {
                path: 'change-password',
                name: 'customer-management-customers-change-password-user',
                component: () => import('@/components/generalDialogs/SetUserCard.vue'),
                beforeEnter: navigationGuards.guardSalesPartner,
                meta: {
                    parent: 'CustomersSalesPartner',
                    breadcrumb: (route) => [
                        {
                            text: roleBreadcrumbMap(),
                            to: '/customer-management',
                        },
                        {
                            text: 'breadcrumbs.customer',
                            translatedText: `(Id: ${route.params.userParentId})`,
                            to: { name: 'customer-management-customers' },
                        },
                        {
                            text: 'breadcrumbs.customerUsers',
                            translatedText: `(Id: ${route.params.userId})`,
                            to: { name: 'customer-management-customers-users' },
                        },
                        {
                            text: 'breadcrumbs.changeCustomerUserPassword',
                            active: true,
                        },
                    ],
                },
                props: true,
            },
        ],
    },
    {
        path: '/customer-management/meteringpoints/:meteringPoint/addresses',
        name: 'metering-point-addresses',
        component: () => import('@/views/customerManagement/meteringPoints/Addresses.vue'),
        beforeEnter: navigationGuards.guard,
        meta: {
            parent: 'CustomersSalesPartner',
            breadcrumb: (route) => [
                {
                    text: roleBreadcrumbMap(),
                    to: '/customer-management',
                },
                {
                    text: 'breadcrumbs.meteringPoints',
                    translatedText: `(${route.params.meteringPoint})`,
                    to: { name: 'customer-management-metering-points' },
                },
                {
                    text: 'breadcrumbs.meteringPointsAddresses',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/customer-management/meteringpoints/:meteringPoint/address/:customerId?',
        name: 'metering-point-address',
        component: () => import('@/views/customerManagement/meteringPoints/Address.vue'),
        beforeEnter: navigationGuards.guard,
        meta: {
            parent: 'CustomersSalesPartner',
            breadcrumb: (route) => {
                const breadcrumbs = [
                    {
                        text: roleBreadcrumbMap(),
                        to: '/customer-management',
                    },
                    {
                        text: 'breadcrumbs.meteringPoints',
                        translatedText: `(${route.params.meteringPoint})`,
                        to: { name: 'customer-management-metering-points' },
                    },
                    {
                        text: 'breadcrumbs.meteringPointsAddresses',
                        translatedText: route.params.customerId === 'all'
                            ? 'all'
                            : `(Id: ${route.params.customerId})`,
                        to: { name: 'metering-point-addresses' },
                    },
                    {
                        text: 'breadcrumbs.meteringPointsAddressChange',
                        active: true,
                    },
                ]
                if (route.params.customerId === undefined) {
                    breadcrumbs.splice(2, 1)
                }
                return breadcrumbs
            },
        },
        props: true,
    },
    {
        path: '/customer-management/customers/:customerId/:customerType/customer-overview',
        component: () => import('@/views/customerManagement/CustomerOverview.vue'),
        beforeEnter: navigationGuards.guardSalesPartner,
        children: [
            {
                path: '',
                redirect: { name: 'customer-overview-frame-contracts' },
            },
            {
                path: 'frame-contracts',
                name: 'customer-overview-frame-contracts',
                component: () => import('@/views/procurement/offers/OffersList.vue'),
                meta: {
                    parent: 'CustomersSalesPartner',
                    breadcrumb: (route) => [
                        {
                            text: roleBreadcrumbMap(),
                            to: '/customer-management',
                        },
                        {
                            text: 'breadcrumbs.customer',
                            translatedText: `(Id: ${route.params.customerId})`,
                            to: { name: 'customer-management-customers' },
                        },
                        {
                            text: 'breadcrumbs.customerFrameContracts',
                            active: true,
                        },
                    ],
                },
                props: true,
            },
            {
                path: 'strategies',
                name: 'customer-overview-strategies',
                component: () => import('@/views/procurement/strategies/StrategiesList'),
                meta: {
                    parent: 'CustomersSalesPartner',
                    breadcrumb: (route) => [
                        {
                            text: roleBreadcrumbMap(),
                            to: '/customer-management',
                        },
                        {
                            text: 'breadcrumbs.customer',
                            translatedText: `(Id: ${route.params.customerId})`,
                            to: { name: 'customer-management-customers' },
                        },
                        {
                            text: 'breadcrumbs.customerStrategies',
                            active: true,
                        },
                    ],
                },
                props: true,
            },
            {
                path: 'open-tranches',
                name: 'customer-overview-open-tranches',
                component: () => import('@/views/portfoliomanagement/strategiesListTranches/StrategiesListWithTranches.vue'),
                meta: {
                    parent: 'CustomersSalesPartner',
                    breadcrumb: (route) => [
                        {
                            text: roleBreadcrumbMap(),
                            to: '/customer-management',
                        },
                        {
                            text: 'breadcrumbs.customer',
                            translatedText: `(Id: ${route.params.customerId})`,
                            to: { name: 'customer-management-customers' },
                        },
                        {
                            text: 'breadcrumbs.customerOpenTranches',
                            active: true,
                        },
                    ],
                },
                props: true,
            },
            {
                path: 'users',
                name: 'customer-overview-users',
                component: () => import('@/components/generalDialogs/UserCard.vue'),
                meta: {
                    parent: 'CustomersSalesPartner',
                    breadcrumb: (route) => [
                        {
                            text: roleBreadcrumbMap(),
                            to: '/customer-management',
                        },
                        {
                            text: 'breadcrumbs.customer',
                            translatedText: `(Id: ${route.params.customerId})`,
                            to: { name: 'customer-management-customers' },
                        },
                        {
                            text: 'breadcrumbs.customerUsers',
                            active: true,
                        },
                    ],
                },
                props: true,
            },
        ],
    },
]
