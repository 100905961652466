import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../vuex/store.js'

import navigationGuards from './navigationGuards.js'
import authRoutes from './routes/authRoutes.js'
import accountSettingRoutes from './routes/accountSettingRoutes.js'
import portfoliomanagementRoutes from './routes/portfoliomanagementRoutes.js'
import procurementRoutes from './routes/procurementRoutes.js'
import customerManagementRoutes from './routes/customerManagementRoutes.js'
import marketRoutes from './routes/marketRoutes.js'
import adminRoutes from './routes/adminRoutes.js'
import taskRoutes from './routes/taskRoutes.js'
import documentViewRoutes from './routes/documentViewRoutes.js'
import laufErstellungRoutes from './routes/laufErstellungRoutes.js'
import financeTransactionRoutes from './routes/financeTransactionRoutes.js'
import audit from './routes/auditRoutes.js'
import customerStatisticsRoutes from './routes/customerStatisticsRoutes.js'
import dataEditorRoutes from './routes/dataEditorRoutes.js'

Vue.use(VueRouter)

const setLoggedInState = async () => {
    if (store.getters.isLoggedIn !== null) {
        return
    }
    await store.dispatch('getUserInfo')
}

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/imprint',
            name: 'imprint',
            component: () => import('@/components/Imprint.vue'),
            meta: {
                parent: 'Imprint',
                breadcrumb: () => [
                    {
                        text: 'breadcrumbs.imprint',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/not-found',
            name: 'NotFound',
            component: () => import('@/views/NotFound.vue'),
        },
        {
            path: '/dashboard',
            name: 'Dashboard',
            alias: '/',
            component: () => import('@/views/Dashboard.vue'),
            beforeEnter: navigationGuards.guard,
            meta: {
                parent: 'Dashboard',
                breadcrumb: () => [
                    {
                        text: 'breadcrumbs.dashboard',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/ecology',
            name: 'Ecology',
            component: () => import('@/views/admin/marginsAndServiceFees/MarginCertificates.vue'),
            beforeEnter: navigationGuards.guardAdmin,
            meta: {
                parent: 'Ecology',
                breadcrumb: () => [
                    {
                        text: 'breadcrumbs.ecology',
                        active: true,
                    },
                ],
            },
        },
        ...authRoutes,
        ...accountSettingRoutes,
        ...portfoliomanagementRoutes,
        ...procurementRoutes,
        ...customerManagementRoutes,
        ...marketRoutes,
        ...adminRoutes,
        ...taskRoutes,
        ...documentViewRoutes,
        ...laufErstellungRoutes,
        ...financeTransactionRoutes,
        ...audit,
        ...customerStatisticsRoutes,
        ...dataEditorRoutes,
        { path: '*', redirect: '/not-found' },
    ],
})

const routesWithoutAuthorization = new Set([
    '/login',
    '/registration',
    '/registration/company',
    '/registration/private',
    '/reset-password',
    '/reset-2fa',
    '/imprint',
])

router.beforeEach(async (to, from, next) => {
    console.log(to)
    await setLoggedInState()
    if (!store.getters.isLoggedIn && to.query?.redirect) {
        store.dispatch('setDirectLink', to.query?.redirect)
    }
    if ((routesWithoutAuthorization.has(to.path) && to.path !== '/imprint') && store.getters.isLoggedIn) {
        return next('/dashboard')
    }
    if (!store.getters.isLoggedIn && !routesWithoutAuthorization.has(to.path) && from.path !== '/') {
        return next({ path: '/login', query: { redirect: to.fullPath } })
    }
    if (to.redirectedFrom === '/' && to.path === '/login' && from.query?.redirect) {
        return next({ path: from.query.redirect })
    }
    return next()
})

export default router
