<template>
  <div>
    <v-dialog
      v-model="dialog"
      scrollable
      :width="widthDialog"
      persistent
    >
      <v-card>
        <v-card-title class="headline">
          {{ title }}
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-container fluid>
            <v-row justify="center">
              <v-col>
                <v-textarea
                  :value="text"
                  hide-details
                  outlined
                  disabled
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            dark
            class="ma-0"
            @click="close()"
          >
            {{ $t('MessageDialog.ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
    name: 'MessageDialog',

    props: {
        title: {
            type: String,
            default: '',
        },
        text: {
            type: String,
            default: '',
        },
        active: Boolean,
        widthDialog: {
            type: String,
            default: '300',
        },
    },
    data () {
        return {
            dialog: false,
        }
    },

    watch: {
        active: function (newValue) {
            if (newValue) {
                this.dialog = true
            } else {
                this.dialog = false
            }
        },
    },

    methods: {
        close () {
            this.$emit('closed')
        },
    },

}
</script>
