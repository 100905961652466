import { apiGet, apiPost } from '@/api/axiosWrapper.js'
import dateFunctions from '@/api/dateFunctions.js'

export default {
    SOCKET_recalcOfferTableCU ({ commit }, { messageId, dbId }) {
        commit('SET_MESSAGE', { messageId, dbId, topic: 'OFFER' })
    },
    SOCKET_recalcOfferTableSP ({ commit }, { messageId, dbId }) {
        commit('SET_MESSAGE', { messageId, dbId, topic: 'OFFER' })
    },
    SOCKET_recalcOfferTable ({ commit }, { messageId, dbId }) {
        commit('SET_MESSAGE', { messageId, dbId, topic: 'OFFER' })
    },
    SOCKET_recalcProcStratTableSP ({ commit }, { messageId, dbId }) {
        commit('SET_MESSAGE', { messageId, dbId, topic: 'PROCUREMENT_STRATEGY' })
    },
    SOCKET_recalcProcStratTableCU ({ commit }, { messageId, dbId }) {
        commit('SET_MESSAGE', { messageId, dbId, topic: 'PROCUREMENT_STRATEGY' })
    },
    SOCKET_recalcProcStratTable ({ commit }, { messageId, dbId }) {
        commit('SET_MESSAGE', { messageId, dbId, topic: 'PROCUREMENT_STRATEGY' })
    },
    setCertificatesPrice ({ commit }, certificatesPrice) {
        commit('SET_CERTIFICATES_PRICE', certificatesPrice)
    },
    setCookieBanner ({ commit }, cookieBanner) {
        commit('SET_COOKIEBANNER', cookieBanner)
    },
    setLoginState ({ commit }, loggedIn) {
        commit('SET_LOGINSTATE', loggedIn)
    },
    login ({ commit }) {
        commit('SET_LOGGED_IN')
    },
    setClientError ({ commit }, clientMessage) {
        commit('SET_CLIENT_ERROR', clientMessage)
    },
    setClientInfo ({ commit }, payload) {
        commit('SET_CLIENT_MESSAGE', { ...payload, type: 'info' })
    },
    setClientWarning ({ commit }, payload) {
        commit('SET_CLIENT_MESSAGE', { ...payload, type: 'warning' })
    },
    closeClientMessage ({ commit }) {
        commit('CLOSE_CLIENT_ERROR_MESSAGE')
    },
    setDirectLink ({ commit }, link) {
        commit('SET_DIRECTLINK', link)
    },
    async setContactSalespartner ({ commit }, domain) {
        const { data } = await apiGet('/api/getContact/' + domain)

        commit('SET_CONTACTSALESPARTNER', data)
    },
    async getUserInfo ({ commit }) {
        const { data } = await apiGet('/api/getUserInfo')

        if (!data.loggedIn) {
            commit('SET_ROLE', 'default')
            commit('SET_MENU', { role: 'default', customertype: null })
            commit('SET_CUSTOMERTYPE', null)
            commit('SET_SESSIONEND', true)
            return
        }
        commit('SET_LOGINSTATE', data.loggedIn)
        commit('SET_ROLE', data.role)
        commit('SET_MENU', { role: data.role, customertype: data.customerType })
        commit('SET_CUSTOMERTYPE', data.customerType)
        commit('SET_USER', data)
    },
    async logout ({ commit }) {
        commit('SET_SESSIONEND', false)
        commit('SET_ROLE', 'default')
        commit('SET_MENU', { role: 'default', customertype: null })
        commit('SET_CUSTOMERTYPE', null)
        commit('SET_LOGINSTATE', false)
    },
    setRoleMenuDefault ({ commit }) {
        commit('SET_ROLE', 'default')
        commit('SET_MENU', { role: 'default', customertype: null })
        commit('SET_CUSTOMERTYPE', null)
    },
    drawerMini ({ commit }, mini) {
        commit('SET_DRAWERMINI', mini)
    },
    async setDesign ({ commit }, domain) {
        const { data: { color, colorPalette } } = await apiGet(`/api/getConfigDB/${domain}`)
        console.log(await apiGet(`/api/getConfigDB/${domain}`))
        const design = { logo: '', logoFile: '', color, colorPalette, loaded: true }

        commit('SET_DESIGN', design)
    },
    setToken ({ commit }, token) {
        commit('SET_TOKEN', token)
    },
    setSocketIoMode ({ commit }, mode) {
        commit('SET_SOCKETIO_MODE', mode)
    },
    setMessageId ({ commit }, messageId) {
        commit('SET_MESSAGEID', messageId)
    },
    async setConfig ({ commit }, domain) {
        const { data } = await apiGet(`/api/getConfigSelect/${domain}`)

        commit('SET_COUNTRYLIST', data.configCountries)
        commit('SET_CUSTOMERTYPELIST', data.configCustomertype)
        commit('SET_BILLINGTYPELIST', data.configBillingtype)
        commit('SET_SEXTYPELIST', data.configSextype)
        commit('SET_METERINGPOINTTYPELIST', data.configMeteringPointType)
        commit('SET_CERTIFICATESLIST', data.certificatesConfig)
        commit('SET_VERIFIABLEENERGYQUALITYKEY', data.defaultEnergyQuality)
        commit('SET_MARKETAREAS_ALL', data.marketareasAllObj)
        commit('SET_MARKETAREAS_ALL_SP', data.marketareasAllObjSP)
        commit('SET_COUNTRIES_MARKETAREAS_RULES', data.countriesMarketareasRules)
        commit('SET_TIMEZONECONFIG_ALL', data.timeZoneConfigAll)
        commit('SET_COUNTRY_INFO_ALL', data.countryInfoAll)
    },
    setOfferDetailsKeysetData ({ commit }, data) {
        commit('SET_OFFER_DETAILS_KEYSET_DATA', data)
    },
    setOfferDetailsFromComponent ({ commit }, data) {
        commit('SET_OFFER_DETAILS_FROM_COMPONENT', data)
    },
    async getOfferData ({ commit }, payload) {
        commit('SET_LOADING_OFFER_DATA', true)
        let searchObj = {}
        if (payload.role === 'admin') {
            searchObj = {
                offerid: '',
                salesPartnerId: '',
                statusId: [],
                offerDateFrom: '',
                offerDateTo: '',
                productId: '',
                currencyId: '',
                deliveryYear: '',
                changeflag: '',
            }
        }
        if (payload.role === 'salesPartner') {
            searchObj = {
                offerid: '',
                customerId: '',
                statusId: [],
                offerDateFrom: '',
                offerDateTo: '',
                productId: '',
                currencyId: '',
                deliveryYear: '',
            }
        }
        if (payload.role === 'customer') {
            searchObj = {
                offerid: '',
                statusId: [],
                offerDateFrom: '',
                offerDateTo: '',
                productId: '',
                currencyId: '',
                deliveryYear: '',
            }
        }
        searchObj.offerid = payload.offerId
        const data = {
            rowsPerPage: 10,
            firstKey: '',
            lastKey: '',
            previousStep: false,
            nextStep: false,
            language: payload.language,
            direction: 'same',
            searchObj,
        }
        let url = ''
        if (payload.role === 'admin') {
            url = '/apiadmin/getOfferTableKeyset'
        }
        if (payload.role === 'salesPartner') {
            url = '/api/getOfferTableSPKeyset'
        }
        if (payload.role === 'customer') {
            url = '/api/getOfferTableCUKeyset'
        }
        const response = await apiPost(url, data)
        for (let i = 0; i < response.data.data.length; i++) {
            response.data.data[i].offerdatetime = dateFunctions.convertToLocalTimeSystem(response.data.data[i].offerdatetime)
            response.data.data[i].offerexpiredatetime = dateFunctions.convertToLocalTimeSystem(response.data.data[i].offerexpiredatetime)
        }
        commit('SET_OFFER_DATA', ...response.data.data)
        commit('SET_LOADING_OFFER_DATA', false)
    },
    async getStrategiesOfferData ({ commit }, payload) {
        commit('SET_LOADING_STRATEGIES_OFFER_DATA', true)
        let url = ''
        if (payload.role === 'admin') {
            url = '/apiadmin/getProcStratDetail'
        } else {
            url = '/api/getProcStratDetail'
        }
        // A little hacky, but necessary to change as little as necessary.
        const response = await apiGet(`${url}/${payload.offerId}/${payload.language}`)
        response.data.offerdatetime = dateFunctions.convertToLocalTimeSystem(response.data.offerdatetime)
        response.data.offerexpiredatetime = dateFunctions.convertToLocalTimeSystem(response.data.offerexpiredatetime)

        commit('SET_STRATEGIES_OFFER_DATA', response.data)
        commit('SET_LOADING_STRATEGIES_OFFER_DATA', false)
    },
    setStrategiesOfferDetailsKeysetData ({ commit }, data) {
        commit('SET_STRATEGIES_OFFER_DETAILS_KEYSET_DATA', data)
    },
    setStrategiesOfferDetailsFromComponent ({ commit }, data) {
        commit('SET_STRATEGIES_OFFER_DETAILS_FROM_COMPONENT', data)
    },
    changeBreadcrumbs ({ commit }, data) {
        commit('CHANGE_BREADCRUMBS', data)
    },
}
