import navigationGuards from '../navigationGuards.js'
import store from '../../vuex/store.js'

const roleToUrlMap = {
    salesPartner: '/account-settings/account',
    FI: '/account-settings/company-details',
    PR: '/account-settings-personal-details',
}

export default [
    {
        path: '/account-settings',
        component: () => import('@/views/accountSettings/AccountSettings.vue'),
        beforeEnter: navigationGuards.guard,
        children: [
            {
                path: '',
                beforeEnter: (to, from, next) => {
                    const { role, customertype } = store.state
                    const targetUrl = roleToUrlMap[role] || roleToUrlMap[customertype]
                    next(targetUrl)
                },
            },
            {
                path: 'account',
                name: 'account-settings/account',
                component: () => import('@/views/accountSettings/salesPartner/Account.vue'),
                beforeEnter: navigationGuards.guardSalesPartner,
                meta: {
                    breadcrumb: () => [
                        {
                            text: 'breadcrumbs.accountManagement',
                            to: '/account-settings',
                        },
                        {
                            text: 'breadcrumbs.accountSettings',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'design',
                name: 'account-settings/design',
                component: () => import('@/components/DesignCard.vue'),
                beforeEnter: navigationGuards.guardSalesPartner,
                meta: {
                    breadcrumb: () => [
                        {
                            text: 'breadcrumbs.accountManagement',
                            to: '/account-settings',
                        },
                        {
                            text: 'breadcrumbs.design',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'company-details',
                name: 'account-settings/company-details',
                component: () => import('@/views/accountSettings/customer/Firm.vue'),
                beforeEnter: navigationGuards.guardCustomerFirm,
                meta: {
                    breadcrumb: () => [
                        {
                            text: 'breadcrumbs.accountManagement',
                            to: '/account-settings',
                        },
                        {
                            text: 'breadcrumbs.customerFirm',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'users',
                name: 'account-settings/users',
                component: () => import('@/components/generalDialogs/UserCard.vue'),
                beforeEnter: navigationGuards.guardSalesPartnerCustomerFirm,
                meta: {
                    breadcrumb: () => [
                        {
                            text: 'breadcrumbs.accountManagement',
                            to: '/account-settings',
                        },
                        {
                            text: 'breadcrumbs.userSalesPartner',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'users/new',
                name: 'account-settings-users-new',
                component: () => import('@/components/generalDialogs/SetUserCard.vue'),
                beforeEnter: navigationGuards.guardSalesPartnerCustomerFirm,
                meta: {
                    breadcrumb: () => [
                        {
                            text: 'breadcrumbs.accountManagement',
                            to: '/account-settings',
                        },
                        {
                            text: 'breadcrumbs.userSalesPartner',
                            to: { name: 'account-settings/users' },
                        },
                        {
                            text: 'breadcrumbs.newUser',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'personal-details',
                name: 'account-settings-personal-details',
                component: () => import('@/views/accountSettings/customer/PersonalDetails.vue'),
                beforeEnter: navigationGuards.guardCustomerPrivate,
                meta: {
                    breadcrumb: () => [
                        {
                            text: 'breadcrumbs.accountManagement',
                            to: '/account-settings',
                        },
                        {
                            text: 'breadcrumbs.userCustomerPrivate',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'personal-details/change-password',
                name: 'account-settings-personal-details-change-password',
                component: () => import('@/components/generalDialogs/SetUserCard.vue'),
                beforeEnter: navigationGuards.guardCustomerPrivate,
                props: { customerType: 'PR' },
                meta: {
                    breadcrumb: () => [
                        {
                            text: 'breadcrumbs.accountManagement',
                            to: '/account-settings',
                        },
                        {
                            text: 'breadcrumbs.userCustomerPrivate',
                            to: { name: 'account-settings-personal-details' },
                        },
                        {
                            text: 'breadcrumbs.changePassword',
                            active: true,
                        },
                    ],
                },
            },
        ],
    },
    {
        path: '/account-settings/users/:userId',
        component: () => import('@/components/generalDialogs/UserTabs.vue'),
        beforeEnter: navigationGuards.guardSalesPartnerCustomerFirm,
        children: [
            {
                path: '',
                redirect: { name: 'account-settings-edit-user' },
            },
            {
                path: 'edit',
                name: 'account-settings-edit-user',
                component: () => import('@/components/generalDialogs/SetUserCard.vue'),
                beforeEnter: navigationGuards.guardSalesPartnerCustomerFirm,
                props: true,
                meta: {
                    breadcrumb: (route) => [
                        {
                            text: 'breadcrumbs.accountManagement',
                            to: '/account-settings',
                        },
                        {
                            text: 'breadcrumbs.userSalesPartner',
                            translatedText: `(Id: ${route.params.userId})`,
                            to: { name: 'account-settings/users' },
                        },
                        {
                            text: 'breadcrumbs.editUser',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'change-password',
                name: 'account-settings-change-password-user',
                component: () => import('@/components/generalDialogs/SetUserCard.vue'),
                beforeEnter: navigationGuards.guardSalesPartnerCustomerFirm,
                props: true,
                meta: {
                    breadcrumb: (route) => [
                        {
                            text: 'breadcrumbs.accountManagement',
                            to: '/account-settings',
                        },
                        {
                            text: 'breadcrumbs.userSalesPartner',
                            translatedText: `(Id: ${route.params.userId})`,
                            to: { name: 'account-settings/users' },
                        },
                        {
                            text: 'breadcrumbs.changePassword',
                            active: true,
                        },
                    ],
                },
            },
        ],
    },
]
