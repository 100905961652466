
import dateFunctionsDstRules from './dateFunctionsDstRules.js'
import dateFunctionsAdd from './dateFunctionsAdd.js'
import functions from './functions.js'

const sortAscDate = function (a, b) {
    const dateA = dateFunctionsAdd.ISODateToDate(a)
    const dateB = dateFunctionsAdd.ISODateToDate(b)

    if (dateA.getTime() < dateB.getTime()) {
        return -1
    }

    if (dateA.getTime() > dateB.getTime()) {
        return 1
    }

    return 0
}

export default ({

    getLocalDateTimeFromTo: function (dateTimeUTC, timezoneConfig, eqdist = '15min') {
        try {
            let shiftMinutes
            if (eqdist === '15min') {
                shiftMinutes = 15
            } else if (eqdist === '1h') {
                shiftMinutes = 60
            } else {
                throw new Error('invalid eqdist')
            }

            const filterTimeZoneConfig = timezoneConfig.find(item =>
                (dateFunctionsAdd.ISODateToDate(item.validfrom)).getTime() <= dateTimeUTC.getTime() &&
                (dateFunctionsAdd.ISODateToDate(item.validto)).getTime() > dateTimeUTC.getTime())

            if (filterTimeZoneConfig === undefined || filterTimeZoneConfig.length < 1) throw new Error('no matching timezoneConfig rule')

            const tzSummerInt = functions.toIntegerTz(filterTimeZoneConfig.tz_summer)
            const tzWinterInt = functions.toIntegerTz(filterTimeZoneConfig.tz_winter)

            if (filterTimeZoneConfig.tz_summer === filterTimeZoneConfig.tz_winter) {
                const dateTimeShifted = dateFunctionsAdd.addMinutes(dateFunctionsAdd.addHours(dateTimeUTC, tzSummerInt.tzHours), tzSummerInt.tzMinutes)
                const dateTimeShiftedTo = dateFunctionsAdd.addMinutes(dateTimeShifted, shiftMinutes)
                return {
                    from: (dateTimeShifted.toISOString().split('.')[0] + 'Z').replace('Z', filterTimeZoneConfig.tz_summer),
                    to: (dateTimeShiftedTo.toISOString().split('.')[0] + 'Z').replace('Z', filterTimeZoneConfig.tz_summer),
                }
            } else {
                if (dateFunctionsDstRules[filterTimeZoneConfig.dst_rule_key](dateTimeUTC) === true) {
                    const dateTimeShifted = dateFunctionsAdd.addMinutes(dateFunctionsAdd.addHours(dateTimeUTC, tzSummerInt.tzHours), tzSummerInt.tzMinutes)
                    const dateTimeShiftedTo = dateFunctionsAdd.addMinutes(dateTimeShifted, shiftMinutes)
                    return {
                        from: (dateTimeShifted.toISOString().split('.')[0] + 'Z').replace('Z', filterTimeZoneConfig.tz_summer),
                        to: (dateTimeShiftedTo.toISOString().split('.')[0] + 'Z').replace('Z', filterTimeZoneConfig.tz_summer),
                    }
                } else {
                    const dateTimeShifted = dateFunctionsAdd.addMinutes(dateFunctionsAdd.addHours(dateTimeUTC, tzWinterInt.tzHours), tzWinterInt.tzMinutes)
                    const dateTimeShiftedTo = dateFunctionsAdd.addMinutes(dateTimeShifted, shiftMinutes)
                    return {
                        from: (dateTimeShifted.toISOString().split('.')[0] + 'Z').replace('Z', filterTimeZoneConfig.tz_winter),
                        to: (dateTimeShiftedTo.toISOString().split('.')[0] + 'Z').replace('Z', filterTimeZoneConfig.tz_winter),
                    }
                }
            }
        } catch (err) {
            console.log(err)
            throw err
        }
    },

    getTimeLocalQuarter: function (dateTimeUTC, timezoneConfig) {
        return this.getLocalDateTimeFromTo(dateTimeUTC, timezoneConfig, '15min')
    },

    getTimeStartDayLocalInUTC: function (dateISOString, timezoneConfig) {
        try {
            const tryTz = []
            for (const item of timezoneConfig) {
                tryTz.push(item.tz_summer)
                tryTz.push(item.tz_winter)
            }

            const distinctTz = [...new Set(tryTz)]
            if (distinctTz.length < 1) throw new Error('timeZone Config Empty')

            for (const tz of distinctTz) {
                const dateTimeISO = `${dateISOString}T00:00:00${tz}`
                if (this.getDateTimeLocal(dateFunctionsAdd.ISODateToDate(dateTimeISO), timezoneConfig) === dateTimeISO) return dateFunctionsAdd.ISODateToDate(dateTimeISO)
            }
        } catch (err) {
            console.log(err)
            throw err
        }
    },

    getTimeEndDayLocalInUTC: function (dateISOString, timezoneConfig, from = true, eqdist = '15min') {
        try {
            const tryTz = []
            for (const item of timezoneConfig) {
                tryTz.push(item.tz_summer)
                tryTz.push(item.tz_winter)
            }

            const distinctTz = [...new Set(tryTz)]
            if (distinctTz.length < 1) throw new Error('timeZone Config Empty')

            for (const tz of distinctTz) {
                let dateTimeISO

                if (from === true) {
                    if (eqdist === '15min') {
                        dateTimeISO = `${dateISOString}T23:45:00${tz}`
                    } else if (eqdist === '1h') {
                        dateTimeISO = `${dateISOString}T23:00:00${tz}`
                    } else {
                        throw new Error('invalid eqdist')
                    }
                } else {
                    dateTimeISO = `${dateFunctionsAdd.addDays(dateISOString, 1).toISOString().substring(0, 10)}T00:00:00${tz}`
                }

                if (this.getDateTimeLocal(dateFunctionsAdd.ISODateToDate(dateTimeISO), timezoneConfig) === dateTimeISO) return dateFunctionsAdd.ISODateToDate(dateTimeISO)
            }
        } catch (err) {
            console.log(err)
            throw err
        }
    },

    getTimeLocalWithTz: function (dateISOStringWithoutTz, timezoneConfig) {
        try {
            const tryTz = []
            for (const item of timezoneConfig) {
                tryTz.push(item.tz_summer)
                tryTz.push(item.tz_winter)
            }

            const distinctTz = [...new Set(tryTz)]
            if (distinctTz.length < 1) throw new Error('timeZone Config Empty')

            const result = []
            for (const tz of distinctTz) {
                const dateTimeISO = `${dateISOStringWithoutTz}${tz}`
                if (this.getDateTimeLocal(dateFunctionsAdd.ISODateToDate(dateTimeISO), timezoneConfig) === dateTimeISO) {
                    result.push(dateTimeISO)
                }
            }

            return result.sort(sortAscDate)
        } catch (err) {
            console.log(err)
            throw err
        }
    },

    localDateTimeFromDBString: function (dbString, timezoneConfig) {
        return this.getDateTimeLocal(dateFunctionsAdd.ISODateToDate(dbString), timezoneConfig)
    },

    convertToLocalTimeSystem (dateTimeISOString) {
        const dateTime = dateFunctionsAdd.ISODateToDate(dateTimeISOString)

        const year = dateTime.getFullYear().toString().padStart(2, '0')
        const month = (dateTime.getMonth() + 1).toString().padStart(2, '0')
        const day = dateTime.getDate().toString().padStart(2, '0')
        const hour = dateTime.getHours().toString().padStart(2, '0')
        const minutes = dateTime.getMinutes().toString().padStart(2, '0')

        return `${year}-${month}-${day} ${hour}:${minutes}`
    },

    getStartDaySystem: function (dateISOString, returnUTCISOString = true) {
        const year = dateFunctionsAdd.getYearPartFromDBString(dateISOString, true, true)

        const month = dateFunctionsAdd.getMonthPartFromDBString(dateISOString, false, true) - 1

        const day = dateFunctionsAdd.getDayPartFromDBString(dateISOString, false, true)

        const startDay = new Date(year, month, day, 0, 0, 0)

        if (returnUTCISOString === true) return startDay.toISOString()
        return startDay
    },

    getEndDaySystem: function (dateISOString, returnUTCISOString = true) {
        dateISOString = dateFunctionsAdd.addDays(dateISOString, 1, true)

        const year = dateFunctionsAdd.getYearPartFromDBString(dateISOString, true, true)

        const month = dateFunctionsAdd.getMonthPartFromDBString(dateISOString, false, true) - 1

        const day = dateFunctionsAdd.getDayPartFromDBString(dateISOString, false, true)

        const endDay = new Date(year, month, day, 0, 0, 0)

        if (returnUTCISOString === true) return endDay.toISOString()
        return endDay
    },

    getDateTimeLocal: function (dateTimeUTC, timezoneConfig) {
        return this.getLocalDateTimeFromTo(dateTimeUTC, timezoneConfig).from
    },

    getOfferDateTime: function (offerDate, hour, minute, timeZoneConfig) {
        const offerDateISOString = `${offerDate}T${hour.padStart(2, '0')}:${minute.padStart(2, '0')}:00`
        const offerdatetimeArr = this.getTimeLocalWithTz(offerDateISOString, timeZoneConfig)

        if (offerdatetimeArr.length !== 1) throw new Error('invalid data offer datetime Arr')

        return offerdatetimeArr[0]
    },

    getOfferExpiryDateTime: function (offerDateTime, offerValidityPeriod, timeZoneConfig) {
        const expirydatetimeUTC = dateFunctionsAdd.addMinutes(dateFunctionsAdd.ISODateToDate(offerDateTime), functions.toInteger(offerValidityPeriod))
        return this.getDateTimeLocal(expirydatetimeUTC, timeZoneConfig)
    },

})
