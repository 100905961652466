import dateFunctionsAdd from './dateFunctionsAdd.js'

export default ({

    CEST_DST: function (dateTimeUTC) {
        // Von-Zeitstempel basiert --> 01:00 utc Mrz <= Sommerzeit < 01:00 utc Oct
        const yearUTC = dateTimeUTC.getUTCFullYear()

        // Letzter Sonntag Mrz UTC 01:00
        const lastDayMarch = dateFunctionsAdd.ISODateToDate(`${yearUTC.toString()}-03-31T00:00:00+00`)
        const startSummertime = dateFunctionsAdd.addHours(lastDayMarch, -lastDayMarch.getUTCDay() * 24 + 1)

        // Letzter Sonntag Oct UTC 01:00
        const lastDayOctober = dateFunctionsAdd.ISODateToDate(`${yearUTC.toString()}-10-31T00:00:00+00`)
        const startWintertime = dateFunctionsAdd.addHours(lastDayOctober, -lastDayOctober.getUTCDay() * 24 + 1)

        if (startSummertime.getTime() <= dateTimeUTC.getTime() && dateTimeUTC.getTime() < startWintertime.getTime()) {
            return true
        } else {
            return false
        }
    },

    NO_DST: function () {
        return false
    },

})
