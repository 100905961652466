export default [
    {
        path: '/document-view',
        component: () => import('@/views/documentView/Document.vue'),
        props: true,
        parent: 'DocumentView',
        name: 'DocumentView',
        meta: {
            parent: 'DocumentView',
            breadcrumb: () => [
                {
                    text: 'breadcrumbs.documents',
                    to: { name: 'DocumentView' },
                },
            ],
        },
    },
    {
        path: '/document-view/:documentId',
        component: () => import('@/views/documentView/DocumentDetails.vue'),
        props: true,
        parent: 'DocumentView',
        name: 'DocumentDetails',
        meta: {
            parent: 'DocumentView',
            breadcrumb: () => [
                {
                    text: 'breadcrumbs.documents',
                    to: { name: 'DocumentView' },
                },
                {
                    text: 'breadcrumbs.documentDetails',
                    to: { name: 'DocumentDetails' },
                },
            ],
        },
    },
    {
        path: '/document-view/:documentId/edit',
        name: 'DocumentEdit',
        component: () => import('@/views/documentView/DocumentDetailsEditFields.vue'),
        props: true,
        meta: {
            parent: 'DocumentDetails',
            breadcrumb: () => [
                {
                    text: 'breadcrumbs.documents',
                    to: { name: 'DocumentView' },
                },
                {
                    text: 'breadcrumbs.documentDetails',
                    to: { name: 'DocumentDetails' },
                },
                {
                    text: 'breadcrumbs.documentEdit',
                    to: { name: 'DocumentEdit' },
                },
            ],
        },
    },
    {
        path: '/document-view/:documentId/edit/position/new',
        name: 'DocumentPositionEditingNew',
        component: () => import('@/views/documentView/DocumentPositionEditing.vue'),
        props: true,
        meta: {
            parent: 'DocumentDetails',
            breadcrumb: () => [
                {
                    text: 'breadcrumbs.documents',
                    to: { name: 'DocumentView' },
                },
                {
                    text: 'breadcrumbs.documentDetails',
                    to: { name: 'DocumentDetails' },
                },
                {
                    text: 'breadcrumbs.documentEdit',
                    to: { name: 'DocumentEdit' },
                },
                {
                    text: 'breadcrumbs.newEntry',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/document-view/:documentId/edit/position/:param',
        name: 'DocumentPositionEditing',
        component: () => import('@/views/documentView/DocumentPositionEditing.vue'),
        props: true,
        meta: {
            parent: 'DocumentDetails',
            breadcrumb: (route) => [
                {
                    text: 'breadcrumbs.documents',
                    to: { name: 'DocumentView' },
                },
                {
                    text: 'breadcrumbs.documentDetails',
                    to: { name: 'DocumentDetails' },
                },
                {
                    text: 'breadcrumbs.documentEdit',
                    to: { name: 'DocumentEdit' },
                },
                {
                    translatedText: `${route.params.param}`,
                    to: { name: 'DocumentPositionEditing' },
                },
            ],
        },
    },
]
