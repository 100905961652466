import navigationGuards from '../navigationGuards.js'

export default [
    {
        path: '/customer-statistics',
        name: 'Customer Statistics',
        component: () => import('@/views/customerStatistics/CustomerStatistics.vue'),
        beforeEnter: navigationGuards.guard,
        meta: {
            parent: 'CustomerStatistics',
        },
    },
]
