import i18n from '../translations/translations.js'

import dec from './dec.js'

const maxSafeInteger = (Number.MAX_SAFE_INTEGER) ? Number.MAX_SAFE_INTEGER : Math.pow(2, 53)
const minSafeInteger = (Number.MIN_SAFE_INTEGER) ? Number.MIN_SAFE_INTEGER : -Math.pow(2, 53)

const sleep = function (ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
}

export default ({
    fillRange: (start, end) => {
        return Array(end - start + 1).fill().map((item, index) => start + index)
    },

    forceFileDownload (dataBlob, fileName) {
        fileName = fileName.replace(/[<>:"/\\|?*]/g, '_')

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(dataBlob, fileName)
        } else {
            const a = document.createElement('a')
            a.style.display = 'none'
            document.body.appendChild(a)

            a.href = window.URL.createObjectURL(dataBlob)

            a.setAttribute('download', fileName)

            a.click()

            window.URL.revokeObjectURL(a.href)
            document.body.removeChild(a)
        }
    },
    toNumber: function (number, round) {
        try {
            let result

            const type = typeof number

            if (type === 'number') {
                result = number
            } else if (type === 'string') {
                result = Number(number.replace(/['’]/g, ''))

                if (result === 0) {
                    if (number.replace(/[\s]/g, '') === '') throw new Error('Invalid Number')
                }
            } else {
                throw new Error('Invalid Number')
            }

            if (round !== undefined) {
                result = this.roundNumber(result, round)
            }

            if (isNaN(result)) throw new Error('Invalid Number')
            if (!isFinite(result)) throw new Error('Invalid Number')

            return result
        } catch (err) {
            return undefined
        }
    },

    roundNumber: function (number, digits, checkNaN = true) {
        if (checkNaN && isNaN(number)) throw new Error('NaN error')
        if (!Number.isInteger(digits)) throw new Error('round error')
        if (digits > 6 || digits < -6) throw new Error('invalid digits')

        // Shift with exponential notation to avoid floating-point issues.
        // See [MDN](https://mdn.io/round#Examples) for more details.
        // --> additional: round away from zero like the numeric type in postgres (--> with sign correction)
        const sign = Math.sign(number)
        if (digits === 0) return sign * Math.round(sign * number)
        let pair = `${number}e`.split('e')
        const value = sign * Math.round(sign * `${pair[0]}e${+pair[1] + digits}`)

        pair = `${value}e`.split('e')
        return +`${pair[0]}e${+pair[1] - digits}`
    },

    roundToInt: function (number, roundAwayFromZero = true, checkNaN = true) {
        // roundAwayFromZero === true --> round away from zero
        // roundAwayFromZero === false --> round towards zero
        if (checkNaN && isNaN(number)) throw new Error('NaN error')
        const sign = (roundAwayFromZero) ? Math.sign(number) : Math.sign(number) * -1
        return sign * Math.round(sign * number)
    },

    numberThousandSep: function (x) {
        if (x === null) return null

        if (this.toNumber(x) === undefined) return null
        const xString = x.toString()

        // split string by decimal
        const xSplit = xString.split('.')

        if (!(xSplit.length <= 2 && xSplit.length > 0)) throw new Error('invalid number')

        let result = xSplit[0].replace(/\B(?=(\d{3})+(?!\d))/g, '\'')
        if (xSplit.length === 2) result += `.${xSplit[1]}`

        return result
    },

    toInteger: function (number) {
        try {
            const result = this.toNumber(number)

            if (Number.isInteger(result) !== true) throw new Error('Invalid Number')
            if (result > maxSafeInteger || result < minSafeInteger) throw new Error('MAX_SAFE_INTEGER error')

            return result
        } catch (err) {
            return undefined
        }
    },

    toIntegerTz: function (tz) {
        try {
            const numberString = tz.toString()
            if (/^[-+]?\d{2}(:\d{2})?$/.test(numberString)) {
                const tzSign = numberString.substring(0, 1)
                const tzTime = numberString.substring(1)
                const tzTimeSplit = tzTime.split(':')

                const tzHours = this.toInteger(`${tzSign}${tzTimeSplit[0]}`)
                if (!(tzHours >= -24 && tzHours <= 24)) throw new Error('Invalid tz')

                const tzMinutes = (tzTimeSplit.length === 2) ? this.toInteger(`${tzSign}${tzTimeSplit[1]}`) : 0
                if (!(tzMinutes >= -59 && tzMinutes <= 59)) throw new Error('Invalid tz')

                return { tzHours, tzMinutes }
            } else {
                throw new Error('Invalid tz')
            }
        } catch (err) {
            return 'Invalid tz'
        }
    },

    isBoolean: function (input) {
        try {
            if (input === true || input === false) return true
            return false
        } catch (err) {
            return false
        }
    },

    existsInArray: function (arr, key, check) {
        const find = arr.find(function (obj) {
            return obj[key] === check
        })

        if (find === undefined) return false
        return true
    },

    removeValueFromArray: function (arr, value) {
        const filterArr = arr.filter(function (item) {
            return item !== value
        })
        return filterArr
    },

    toggleValueFromArrayOfObjects: function (arr, checkValuesArr, objectKeysArr, toggleKey) {
        for (let i = 0; i < arr.length; i++) {
            let checkEquality = true
            for (let j = 0; j < checkValuesArr.length; j++) {
                if (checkValuesArr[j] !== arr[i][objectKeysArr[j]]) checkEquality = false
            }

            if (checkEquality === true) {
                arr[i][toggleKey] = !arr[i][toggleKey]
                return
            }
        }
    },

    cleanValidation: function (validationObj) {
        const self = this

        Object.keys(validationObj).forEach(function (key) {
            if (Array.isArray(validationObj[key])) {
                self.cleanValidationArray(validationObj[key])
            } else {
                if (validationObj[key].is_valid !== undefined && validationObj[key].text !== undefined) {
                    validationObj[key].is_valid = true
                    validationObj[key].text = ''
                } else {
                    Object.keys(validationObj[key]).forEach(function (keyInner) {
                        validationObj[key][keyInner].is_valid = true
                        validationObj[key][keyInner].text = ''
                    })
                }
            }
        })

        return validationObj
    },

    cleanValidationArray: function (validationArr) {
        validationArr.forEach(function (element, index) {
            validationArr[index].is_valid = true
            validationArr[index].text = ''
        })

        return validationArr
    },

    getCountryObjFromCode: function (countryCode, countryList, language) {
        try {
            const counttryListCopy = JSON.parse(JSON.stringify(countryList))
            const country = counttryListCopy.find(item => item.name[language].value === countryCode)

            if (country === undefined) return undefined

            return country.name[language]
        } catch (err) {
            return undefined
        }
    },

    getStateObjFromCode: function (countryCode, stateCode, countryList, language) {
        try {
            const counttryListCopy = JSON.parse(JSON.stringify(countryList))
            const country = counttryListCopy.find(item => item.name[language].value === countryCode)

            if (country === undefined) return undefined

            const state = country.states[language].find(item => item.value === stateCode)

            return state
        } catch (err) {
            return undefined
        }
    },

    getMeteringPointTypeObjFromCode: function (meteringPointTypeCode, meteringPointTypeList, language) {
        try {
            const meteringPointTypeListCopy = JSON.parse(JSON.stringify(meteringPointTypeList))
            let meteringPointType = meteringPointTypeListCopy.map(obj => ({ value: obj.key, text: obj[language] }))
            meteringPointType = meteringPointType.find(obj => obj.value === meteringPointTypeCode)

            if (meteringPointType === undefined) return undefined

            return meteringPointType
        } catch (err) {
            return undefined
        }
    },

    sleep: function (ms) {
        return new Promise(resolve => setTimeout(resolve, ms))
    },

    mappingToTable: function (rules, data, role, excludeInfoData) {
        const table = []
        for (let i = 0; i < rules.tableRows.length; i++) {
            let addRow = true
            if (rules.tableRows[i].excludeRules !== undefined) {
                for (const excludeKey of Object.keys(rules.tableRows[i].excludeRules)) {
                    if (rules.tableRows[i].excludeRules[excludeKey].includes(excludeInfoData[excludeKey])) addRow = false
                }
            }

            if (addRow) {
                const insertTable = {
                    name: i18n.t(rules.tableRows[i].translationKey),
                }

                for (let j = 0; j < rules.tableRows[i].mappingCols.length; j++) {
                    if (rules.tableRows[i].mappingCols[j].rolesExclude.includes(role) === false) {
                        let valueFrom = data[rules.tableRows[i].mappingCols[j].from]
                        if (valueFrom === undefined) throw new Error('data not found')
                        if (rules.tableRows[i].mappingCols[j].thousandSep === true) valueFrom = this.numberThousandSep(valueFrom)

                        insertTable[rules.tableRows[i].mappingCols[j].to] = (valueFrom === null) ? 'NA' : valueFrom
                    }
                }

                table.push(insertTable)
            }
        }

        return table
    },

    groupTableData: function (data, key, keyName, vue, expandable = true, keysExpanded = []) {
        let keyLoop
        const dataGrouped = []

        for (let i = 0; i < data.length; i++) {
            if (keyLoop !== data[i][key]) {
                keyLoop = data[i][key]

                const insertObj = {
                    key: data[i][key],
                    name: data[i][keyName],
                    data: [],
                }

                if (expandable === true) {
                    if (keysExpanded.includes(data[i][key])) {
                        vue.$set(insertObj, 'expanded', true)
                    } else {
                        vue.$set(insertObj, 'expanded', false)
                    }
                }

                dataGrouped.push(insertObj)
            }

            dataGrouped[dataGrouped.length - 1].data.push(data[i])
        }

        return dataGrouped
    },

    expandInfo (dataTable, idKey, id) {
        for (let i = 0; i < dataTable.length; i++) {
            if (dataTable[i][idKey] === id) {
                if (dataTable[i].expanded === true) {
                    dataTable[i].expanded = false
                } else {
                    dataTable[i].expanded = true
                }
            } else {
                dataTable[i].expanded = false
            }
        }
    },

    getExpandedKey (data, key) {
        const keysExpanded = []

        for (let i = 0; i < data.length; i++) {
            if (data[i].expanded === true) keysExpanded.push(data[i][key])
        }

        return keysExpanded
    },

    isEqual: function (value1, value2, precision = 0.00001) {
        const value1Num = this.toNumber(value1)
        const value2Num = this.toNumber(value2)

        if (value1Num === undefined) throw new Error('invalid value1')
        if (value2Num === undefined) throw new Error('invalid value2')

        if (Math.abs(value1Num - value2Num) <= precision) return true
        return false
    },

    reactiveData: async function (vueData, data, vue, isAwaitAfterSteps = false, counterInitial = 0, awaitAfterSteps = 1000) {
        let counter

        try {
            counter = counterInitial

            if (typeof data !== 'object') throw new Error('input is not a object')
            if (data === null) throw new Error('input is not a object')

            if (Array.isArray(data)) {
                for (let index = 0; index < data.length; index++) {
                    if (typeof data[index] === 'function') throw new Error('no funtions allowed')

                    counter++

                    if (isAwaitAfterSteps === true && counter === awaitAfterSteps) {
                        await sleep(1)
                        counter = 0
                    }

                    if (typeof data[index] === 'object') {
                        if (data[index] !== null) {
                            if (Array.isArray(data[index])) {
                                vue.$set(vueData, index, [])
                            } else {
                                vue.$set(vueData, index, {})
                            }

                            counter = await this.reactiveData(vueData[index], data[index], vue, isAwaitAfterSteps, counter, awaitAfterSteps)
                        } else {
                            vue.$set(vueData, index, null)
                        }
                    } else {
                        vue.$set(vueData, index, data[index])
                    }
                }
            } else {
                const objKeys = Object.keys(data)

                for (let index = 0; index < objKeys.length; index++) {
                    const element = objKeys[index]

                    if (typeof data[element] === 'function') throw new Error('no funtions allowed')

                    counter++

                    if (isAwaitAfterSteps === true && counter === awaitAfterSteps) {
                        await sleep(1)
                        counter = 0
                    }

                    if (typeof data[element] === 'object') {
                        if (data[element] !== null) {
                            if (Array.isArray(data[element])) {
                                vue.$set(vueData, element, [])
                            } else {
                                vue.$set(vueData, element, {})
                            }

                            counter = await this.reactiveData(vueData[element], data[element], vue, isAwaitAfterSteps, counter, awaitAfterSteps)
                        } else {
                            vue.$set(vueData, element, null)
                        }
                    } else {
                        vue.$set(vueData, element, data[element])
                    }
                }
            }

            return counter
        } catch (err) {
            console.log(err)
            throw err
        }
    },

    removeKeys: function (obj, keysArr) {
        for (let i = 0; i < keysArr.length; i++) {
            delete obj[keysArr[i]]
        }
    },

    arrOfObjToObjByKey: function (arrOfObj, keyArr, parseStringify = false) {
        const result = {}

        for (let i = 0; i < arrOfObj.length; i++) {
            let key = ''

            for (let j = 0; j < keyArr.length; j++) {
                if (j === 0) {
                    key += arrOfObj[i][keyArr[j]]
                } else {
                    key += `_${arrOfObj[i][keyArr[j]]}`
                }
            }

            if (Object.prototype.hasOwnProperty.call(result, key)) throw new Error('key not unique')

            if (parseStringify === true) {
                result[key] = JSON.parse(JSON.stringify(arrOfObj[i]))
            } else {
                result[key] = arrOfObj[i]
            }
        }

        return result
    },

    deepCopy: async function (dataObj, isAwaitAfterSteps = true, awaitAfterSteps = 100000, counterInitial = 0) {
        const result = (await this.deepCopyFunction(dataObj, isAwaitAfterSteps, awaitAfterSteps, counterInitial)).data
        return result
    },

    deepCopyFunction: async function (dataObj, isAwaitAfterSteps = true, awaitAfterSteps = 100000, counterInitial = 0) {
        try {
            let clone

            let counter = counterInitial

            counter++
            if (isAwaitAfterSteps === true && counter === awaitAfterSteps) {
                await sleep(1)
                counter = 0
            }

            if (
                typeof dataObj === 'string' ||
                typeof dataObj === 'number' ||
                typeof dataObj === 'boolean' ||
                typeof dataObj === 'undefined' ||
                dataObj === null
            ) {
                return { counter, data: dataObj }
            }

            if (dataObj instanceof Date) {
                return { counter, data: new Date(dataObj.getTime()) }
            }

            if (dec.isExactNumber(dataObj)) {
                return dec.copyExactNumber(dataObj)
            }

            if (typeof dataObj === 'object') {
                if (Array.isArray(dataObj)) {
                    clone = []
                } else {
                    clone = {}
                }

                const objKeys = Object.keys(dataObj)

                for (let i = 0; i < objKeys.length; i++) {
                    const element = objKeys[i]
                    const innerResult = await this.deepCopyFunction(dataObj[element], isAwaitAfterSteps, awaitAfterSteps, counter)

                    clone[element] = innerResult.data
                    counter = innerResult.counter
                }

                return { counter, data: clone }
            }

            throw new Error('invalid type')
        } catch (err) {
            console.log(err)
            throw err
        }
    },

    deepCopySync: function (dataObj) {
        try {
            let clone

            if (
                typeof dataObj === 'string' ||
                typeof dataObj === 'number' ||
                typeof dataObj === 'boolean' ||
                typeof dataObj === 'undefined' ||
                dataObj === null
            ) {
                return dataObj
            }

            if (dataObj instanceof Date) {
                return new Date(dataObj.getTime())
            }

            if (dec.isExactNumber(dataObj)) {
                return dec.copyExactNumber(dataObj)
            }

            if (typeof dataObj === 'object') {
                if (Array.isArray(dataObj)) {
                    clone = []
                } else {
                    clone = {}
                }

                const objKeys = Object.keys(dataObj)

                for (let i = 0; i < objKeys.length; i++) {
                    const element = objKeys[i]
                    clone[element] = this.deepCopySync(dataObj[element])
                }

                return clone
            }

            throw new Error('invalid type')
        } catch (err) {
            console.log(err)
            throw err
        }
    },

    abToStr: function (buf) {
        const enc = new TextDecoder('utf-8')
        return enc.decode(buf)
    },

    isObject: function (object) {
        return object && typeof object === 'object'
    },
    isDeepEqual: function (object1, object2) {
        const objKeys1 = Object.keys(object1)
        const objKeys2 = Object.keys(object2)

        if (objKeys1.length !== objKeys2.length) return false

        for (const key of objKeys1) {
            const value1 = object1[key]
            const value2 = object2[key]

            const isObjects = this.isObject(value1) && this.isObject(value2)

            if ((isObjects && !this.isDeepEqual(value1, value2)) || (!isObjects && value1 !== value2)) {
                return false
            }
        }
        return true
    },
    removeEmptyProperties: function (object) {
        return Object.entries(object)
            .filter(([, value]) => {
                return !(value === '' || value === null || value === undefined || (Array.isArray(value) && value.length === 0))
            })
            .reduce((prev, [key, value]) => ({ ...prev, [key]: value }), {})
    },
})
