import axios from 'axios'
import store from '../vuex/store.js'

import * as rax from 'retry-axios'

rax.attach()

console.log(process.env)
const urlHost = process.env.VUE_APP_MOCK_API

const raxConfig = {
    retry: 2,
    noResponseRetries: 2,
    retryDelay: 500,
    httpMethodsToRetry: ['GET'],
    statusCodesToRetry: [[404, 429, 429], [500, 599]],
    backoffType: 'exponential',

    onRetryAttempt: err => {
        console.log(err)

        const cfg = rax.getConfig(err)
        console.log(`retry-axios: ${cfg.currentRetryAttempt}`)
    },
}

const errorWrapper = async (axiosCall, url) => {
    try {
        return await axiosCall()
    } catch (error) {
        console.error(url, error)

        if (error.response?.data?.isClientError) {
            const { response: { data } } = error

            store.dispatch('setClientError', data)
            throw data
        }

        if (error.isClientError) {
            store.dispatch('setClientError', error)
            throw error
        }

        if (error.code === 'ERR_BAD_REQUEST') {
            store.dispatch('setClientError', { code: 'ERR_BAD_REQUEST' })
            throw error
        }

        console.warn('An unexpected error has occurred. A standard message is displayed.')
        store.dispatch('setClientError', { code: 'default' })
        throw error
    }
}

export const apiPut = async (url, data, config) => errorWrapper(() => axios.put(urlHost + url, data, { raxConfig, ...config }), urlHost + url)
export const apiPost = async (url, data, config) => errorWrapper(() => axios.post(urlHost + url, data, { raxConfig, ...config }), urlHost + url)
export const apiGet = async (url, config) => errorWrapper(() => axios.get(urlHost + url, { raxConfig, ...config }), urlHost + url)
export const apiRemove = async (url, config) => errorWrapper(() => axios.delete(urlHost + url, { raxConfig, ...config }), urlHost + url)
