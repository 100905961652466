<template>
  <div :style="cssProps">
    <message-dialog
      :title="$t('App.info')"
      :text="message"
      :active="dialogInfo"
      width-dialog="600px"
      @closed="closeDialogInfo()"
    />

    <v-app :style="styleApp">
      <div
        v-if="loadingApp"
        class="d-flex ma-auto"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        />
      </div>
      <div
        v-else
        class="h-100"
      >
        <navigation />
        <v-main
          :style="[vContentStyle,
                   user.loggedIn && windowSize.x > 1263 ? {marginLeft: '73px'} : '',
                   user.loggedIn && windowSize.x < 600 ? contentStyleMobile : '']"
          class="h-100"
        >
          <v-container
            fluid
            justify="center"
            class="d-flex flex-column h-100"
            :style="styleContent"
          >
            <toast-component />

            <router-view :key="$route.path" />
            <footer-nav class="mt-auto" />
          </v-container>

          <v-bottom-sheet
            :value="cookieBanner"
            persistent
            flat
          >
            <v-card
              dark
              color="rgb(242 242 242)"
              height="100%"
            >
              <v-card-text class="pa-0">
                <v-container fluid>
                  <v-row
                    dense
                    justify="center"
                  >
                    <v-col>
                      <label>
                        {{ $t('App.cookieBanner1') }} <br>
                        {{ $t('App.cookieBanner2') }}
                      </label>
                    </v-col>
                  </v-row>

                  <v-row
                    dense
                    justify="center"
                  >
                    <v-col>
                      <v-btn
                        color="primary"
                        dark
                        class="mt-3 mb-3"
                        @click="closeCookieBanner()"
                      >
                        {{ $t('App.accept') }}
                      </v-btn>
                    </v-col>
                  </v-row>

                  <v-row
                    dense
                    justify="center"
                  >
                    <v-col class="d-inline-flex">
                      <v-spacer />
                      <v-checkbox
                        v-model="memorizeDecisionCookie"
                        :label="$t('App.memorizeDecision')"
                        color="primary"
                        class="mt-0 check-box-white"
                        hide-details
                        light
                        :ripple="false"
                      />
                      <v-spacer />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-bottom-sheet>
        </v-main>
      </div>
    </v-app>

    <resign-terms-portal-dialog
      v-if="dialogResign"
      @closed="closeResignDialog()"
    />
  </div>
</template>

<script>
import { apiGet } from '@/api/axiosWrapper.js'
import { mapState } from 'vuex'
import navigation from '@/components/Navigation.vue'
import MessageDialog from '@/components/generalDialogs/MessageDialog.vue'
import ResignTermsPortalDialog from '@/components/generalDialogs/ResignTermsPortalDialog.vue'
import ToastComponent from '@/components/generalDialogs/ToastComponent.vue'
import FooterNav from '@/components/FooterNav.vue'
import highChartFunctions from '@/api/highChartFunctions.js'
import { v4 as uuidv4 } from 'uuid'

export default {
    name: 'App',

    components: {
        FooterNav,
        navigation,
        MessageDialog,
        ResignTermsPortalDialog,
        ToastComponent,
    },
    sockets: {
        messageAdmin: function (message) {
            this.message = message
            this.dialogInfo = false
            this.$nextTick(() => {
                this.dialogInfo = true
            })
        },
    },

    data () {
        return {
            loadingApp: false,
            memorizeDecisionCookie: true,
            dialogResign: false,
            windowSize: {
                x: window.innerWidth,
                y: window.innerHeight,
            },
            styleApp: {
                background: '#F7F8FA',
            },
            styleContent: {
                paddingTop: '0px',
                paddingLeft: '0px',
                paddingRight: '0px',
                paddingBottom: '0px',
                backgroundImage: '',
                backgroundSize: '100% 100%',
            },
            vContentStyle: {
                overflowX: 'hidden',
                marginTop: '0px',
                paddingLeft: '28px',
                paddingRight: '28px',
                paddingBottom: '30px',
                paddingTop: '116px',
            },
            contentStyleMobile: {
                paddingLeft: '4px',
                paddingRight: '4px',
            },
            message: '',
            dialogInfo: false,
        }
    },
    computed: {
        styleCookieBanner () {
            if (this.drawerMini) {
                return {
                    marginLeft: '60px',
                    marginBottom: '16px',
                }
            } else {
                return {
                    marginLeft: '360px',
                    marginBottom: '16px',
                }
            }
        },
        cssProps () {
            return {
                '--background-color': this.$store.state.colorTableToolbar,
                '--background-header': this.$store.state.colorHeader,
                '--color-text-table': this.colorTextTable,
                '--design-color': this.design.color,
                '--design-color-opacity': this.design.color + '24',
                '--design-color-opacity-ten': this.design.color + '1A',
                '--link-color': this.$store.state.linkColor,
            }
        },
        windowWidth () {
            return this.$store.state.windowWidth
        },
        ...mapState([
            'role',
            'colorTextTable',
            'cookieBanner',
            'drawerMini',
            'colorTableToolbar',
            'directLink',
            'design',
            'user',
        ]),
        ...mapState({
            errorCode: state => state.clientMessage?.code,
        }),
    },
    watch: {
        async errorCode (newVal, oldVal) {
            if (oldVal !== newVal && newVal === 'NOT_AUTHORIZED') {
                await this.$store.dispatch('logout')
                const encodedRedirectUrl = encodeURIComponent(this.$route.path || '/dashboard')
                const url = `/login?redirect=${encodedRedirectUrl}`
                this.$router.push(url)
            }
        },
        design (newVal) {
            highChartFunctions.changeColorHighcharts(newVal.colorPalette)
            localStorage.setItem('designColor', newVal.color)

            const changeColorFavicon = () => {

            }

            changeColorFavicon()

            this.$vuetify.theme.primary = newVal.color
            this.$vuetify.theme.themes.light.primary = newVal.color
            this.$vuetify.theme.themes.dark.primary = newVal.color
            this.$vuetify.theme.themes.light.accent = newVal.color
            this.$vuetify.theme.themes.dark.accent = newVal.color
        },
        user: {
            handler: async function (newProp) {
                if (newProp.language) {
                    this.$root.$i18n.locale = newProp.language
                    highChartFunctions.changeLanguageHighcharts()
                }
                if (newProp.loggedIn && newProp.role === 'customer') {
                    const { data } = await apiGet('/api/checkTermsConditionsPortal')
                    this.dialogResign = !data
                }
            },
            deep: true,
        },
    },
    async mounted () {
        try {
            this.loadingApp = true

            const cookieInfo = this.$cookies.get('memorizeCookieDecision') !== 'yes'

            await Promise.all([
                this.$store.dispatch('setDesign', this.$domainFull),
                this.$store.dispatch('setCookieBanner', cookieInfo),
                this.$store.dispatch('setConfig', this.$domainFull),
                this.$store.dispatch('setMessageId', uuidv4()),
                this.$store.dispatch('setContactSalespartner', this.$domainFull),
            ])
        } catch (errors) {
            if (!errors.isClientError) {
                console.log(errors)
                this.$store.dispatch('setClientError', { code: 'default' })
            }
        } finally {
            this.loadingApp = false
        }
    },
    created () {
        window.addEventListener('resize', this.onResize)
    },
    unmounted () {
        window.removeEventListener('resize', this.onResize)
    },
    methods: {
        closeModalLoadError () {
            location.reload(true)
        },
        closeDialogInfo () {
            this.dialogInfo = false
            this.message = ''
        },
        closeResignDialog () {
            const url = this.directLink
            this.dialogResign = false
            if (this.$route.name !== 'SetNewPassword') {
                this.$router.push(url)
            }
        },
        closeCookieBanner () {
            if (this.memorizeDecisionCookie) {
                this.$cookies.set('memorizeCookieDecision', 'yes', '4m')
            }
            return this.$store.dispatch('setCookieBanner', false)
        },
        onResize () {
            this.windowSize = { x: window.innerWidth, y: window.innerHeight }
        },
    },
}
</script>

<style>
@import './css/style.css';
@import './css/media.css';
#app {
  padding-left: 0px;
  text-align: center;
  margin-top: 0px;
}
.v-alert__wrapper {
  font-size: 14px !important;
}
.v-card__title {
  font-size: 16px !important;
}
.v-card__text, .v-card__title {
  word-break: normal !important;
}
.v-button-clickable-link {
  background:none !important;
  border:none !important;
  margin:0 !important;
  padding:0 !important;
  cursor: pointer !important;
  text-decoration: underline !important;
  color: var(--link-color) !important;
}
.v-list.v-select-list {
  text-align: left;
}
.v-picker__body {
  padding-bottom: 8px;
}
.table-with-menu-expanded .v-data-table__wrapper {
  overflow: visible !important;
}
.v-select.v-chip-no-opacity .v-chip {
  opacity: 1 !important;
}
.v-card__actions.card-action-white-background {
  background-color:white;
  box-shadow: none;
}
.check-white-background i{
  background-color: white;
  margin-top: 3px;
  width: 18px !important;
  height: 18px !important;
  border-radius: 3px;
}
.check-center-table .v-input__control .v-input__slot {
align-items: center;
justify-content: center;
}
.radio-white-background i {
  background-color: white;
  margin-top: 3px;
  width: 18px !important;
  height: 18px !important;
  border-radius: 50%;
}
.validation {
  color: #f30b17;
  float: left;
  margin-top: 10px;
  margin-bottom: 15px;
  display:block;
  font-size: 12px
}
.validation-years {
  display:block;
  color: #f30b17;
  font-size: 12px
}
.validation-inside-table {
  color: #f30b17;
  font-size: 12px
}
.validation-ok {
  color:green;
  float: left;
  margin-top: 10px;
  margin-bottom: 15px;
  display:block;
  font-size: 12px
}
.nohover-tr:hover {
  background-color: transparent !important;
}
.icon-button-size {
  font-size: 22px
}
.icon-inside-text {
  font-size: 18px !important;
  margin-bottom: 2px !important;
}
.icon-inside-compact-table {
  font-size: 18px;
}
.theme--light.v-text-field.v-input--is-disabled > .v-input__control > .v-input__slot::before {
  border-image: none
}
.v-expansion-panel-flat {
  box-shadow: none !important;
}
.v-text-start {
  text-align: start
}
.v-vertical-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
div.v-select-list div[role=list] div[role=listitem] {
  caret-color: var(--design-color)
}
.label-design {
  color: var(--design-color) !important;
  caret-color: var(--design-color);
}
.v-select__slot label, .v-text-field__slot label {
  color: var(--design-color) !important;
  caret-color: var(--design-color);
}
.v-radio label {
  color: #414141 !important;
}
.theme--light.v-label--is-disabled {
  color: rgba(0,0,0,.38) !important;
}
.circle {
  height: 22px;
  width: 22px;
  border-radius: 50%;
  display: inline-flex;
  vertical-align: inherit;
}
.accepted_icon_outline {
  position: relative;
}
.accepted_icon_outline label {
  background-color: #fff;
  border: 2px solid var(--design-color);
  border-radius: 50%;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}
.accepted_icon_outline label:after {
  border: 2px solid var(--design-color);
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 11px;
}
.accepted_icon_outline input[type="checkbox"] {
  visibility: hidden;
}
.accepted_icon_outline input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
</style>
<style scoped>

.check-box-white /deep/ label {
  color: white;
}
</style>
