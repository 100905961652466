export class BaseFormatter {
    translations = null
    emojiMap = {
        de: '🇩🇪',
        en: '🇬🇧',
        fr: '🇫🇷',
    }

    defaultKey = null

    constructor (translation) {
        this.translations = new Map(Object.keys(translation).map(key => {
            const value = JSON.parse(this.getTranslationFromStorage(key)) || translation[key]
            return [key, value]
        }))
        this.defaultKey = localStorage.getItem('defaultLanguage')
        if (!this.defaultKey) {
            localStorage.setItem('defaultLanguage', 'de')
            this.defaultKey = 'de'
        }
        this.defaultTranslation = this.defaultKey ? JSON.parse(this.getTranslationFromStorage(this.defaultKey)) : null
        this.initLocalStorage()
        this.renderTranslation(700)
    }

    setValueWithFqn (obj, key, value) {
        const [subKey, ...rest] = key.split('.')

        if (rest.length === 0) {
            obj[subKey] = value
        } else {
            this.setValueWithFqn(obj[subKey], rest.join('.'), value)
        }

        return obj
    }

    getValueWithFqn (obj, key) {
        const [subKey, ...rest] = key.split('.')

        const subObj = obj[subKey]
        return rest.length === 0 ? subObj : this.getValueWithFqn(subObj, rest.join('.'))
    }

    getLocalStorageKey (key) {
        return `translation-${key}`
    }

    getTranslationFromStorage (key) {
        return localStorage.getItem(this.getLocalStorageKey(key))
    }

    initLocalStorage () {
        for (const [key, value] of this.translations.entries()) {
            const storage = this.getTranslationFromStorage(key)
            if (!storage) {
                localStorage.setItem(this.getLocalStorageKey(key), JSON.stringify(value))
            }
        }
    }

    showTranslation (spanObject, key) {
        const overlay = document.createElement('div')
        overlay.style.backgroundColor = 'rgba(0,0,0,0.5)'
        overlay.style.position = 'fixed'
        overlay.style.top = 0
        overlay.style.bottom = 0
        overlay.style.left = 0
        overlay.style.right = 0
        overlay.style.display = 'flex'
        overlay.style.gap = '20px'
        overlay.style.flexDirection = 'column'
        overlay.style.alignItems = 'center'
        overlay.style.justifyContent = 'center'
        overlay.style.zIndex = '9999'
        overlay.style.width = '100%'

        const form = document.createElement('div')
        form.style.backgroundColor = '#fff'
        form.style.padding = '20px'
        form.style.borderRadius = '10px'
        form.style.display = 'flex'
        form.style.flexDirection = 'column'
        form.style.alignItems = 'left'
        form.style.width = '50%'
        form.style.justifyContent = 'space-between'

        const headerContainer = document.createElement('div')
        headerContainer.style.display = 'flex'
        headerContainer.style.justifyContent = 'space-between'
        headerContainer.style.width = '100%'
        headerContainer.style.marginBottom = '25px'

        const title = document.createElement('h2')
        title.textContent = `Key: ${key}`

        const closeButton = document.createElement('button')
        closeButton.innerHTML = '<i class="fa fa-times"></i>'
        closeButton.addEventListener('click', () => {
            document.body.removeChild(overlay)
        })
        headerContainer.appendChild(title)
        headerContainer.appendChild(closeButton)

        form.appendChild(headerContainer)

        for (const [translationKey, value] of this.translations) {
            const container = document.createElement('div')
            container.style.display = 'flex'
            container.style.justifyContent = 'space-between'

            container.style.width = '100%'
            const setAsDefault = document.createElement('button')

            const isDefault = this.defaultKey === translationKey

            setAsDefault.style.border = isDefault ? '1px solid #3F51B5' : '1px solid transparent'
            setAsDefault.style.padding = '2px'

            setAsDefault.addEventListener('click', () => {
                localStorage.setItem('defaultLanguage', isDefault ? '' : translationKey)
                location.reload()
            })
            const text = this.emojiMap[translationKey] || translationKey
            setAsDefault.innerText = `${text}`
            setAsDefault.style.marginRight = '10px'
            container.appendChild(setAsDefault)

            const input = document.createElement('input')
            input.value = this.getValueWithFqn(value, key)
            input.style.flexGrow = '1'
            input.style.width = '0'
            input.style.marginRight = '25px'
            container.appendChild(input)

            const buttons = document.createElement('div')
            buttons.style.display = 'flex'
            buttons.style.justifyContent = 'flex-end'

            const saveButton = document.createElement('button')
            saveButton.style.marginRight = '10px'

            saveButton.innerHTML = '<i class="fa fa-save"></i>'
            saveButton.addEventListener('click', () => {
                const updatedTranslation = this.setValueWithFqn(value, key, input.value)
                spanObject.textContent = input.value
                localStorage.setItem(this.getLocalStorageKey(translationKey), JSON.stringify(updatedTranslation))
            })

            buttons.appendChild(saveButton)
            const downloadButton = document.createElement('button')
            downloadButton.innerHTML = '<i class="fa fa-download"></i>'

            downloadButton.addEventListener('click', () => {
                const updatedTranslation = JSON.stringify(JSON.parse(this.getTranslationFromStorage(translationKey)), null, 3)
                const textAsBlob = new Blob([updatedTranslation], { type: 'text/plain' })
                const fileNameToSaveAs = `${translationKey}.json`

                const downloadLink = document.createElement('a')
                downloadLink.download = fileNameToSaveAs
                downloadLink.innerHTML = 'Download File'
                downloadLink.href = window.URL.createObjectURL(textAsBlob)

                downloadLink.click()
            })
            buttons.appendChild(downloadButton)

            container.appendChild(buttons)
            form.appendChild(container)
        }

        overlay.appendChild(form)
        document.body.appendChild(overlay)
    }

    renderTranslation = (interval) => {
        setInterval(() => {
            const elements = Array.from(document.body.getElementsByTagName('*'))
                .filter(({ textContent, childElementCount }) => textContent && childElementCount === 0 && textContent.indexOf('{{') !== -1)

            for (const element of elements) {
                const [[, key, value]] = element.textContent.matchAll(/\{\{(.*):(.*)\}\}/g)
                const span = document.createElement('span')
                span.textContent = value
                element.textContent = ''
                const editButton = document.createElement('button')
                const icon = document.createElement('i')

                editButton.style.backgroundColor = 'transparent'
                editButton.style.opacity = '0.3'
                editButton.style.padding = '3px'
                editButton.style.border = 'none'
                editButton.style.fontSize = 'smaller'
                const firstLetter = key[0]

                if (firstLetter.toUpperCase() === firstLetter) {
                    icon.classList.add('fas', 'fa-check')
                } else {
                    icon.classList.add('fas', 'fa-edit')
                }
                editButton.appendChild(icon)
                editButton.onclick = (event) => {
                    event.stopPropagation()
                    event.preventDefault()
                    this.showTranslation(span, key)
                }
                element.appendChild(editButton)
                element.appendChild(span)
            }
        }, interval)
    }

    interpolate (message, _, path) {
        if (this.defaultTranslation) {
            const updatedMessage = this.getValueWithFqn(this.defaultTranslation, path)

            return `{{${path}:${updatedMessage}}}`
        }
        return `{{${path}:${message}}}`
    }
}
