import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '../translations/translations.js'

Vue.use(Vuetify)

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    lang: {
        t: (key, ...params) => i18n.t(key, params),
    },
    options: {
        customProperties: true,
    },
    theme: {
        themes: {
            light: {
                primary: '#3F56D1',
                secondary: '#ECE9F0',
                accent: '#BCBCBC',
                error: '#FF0000',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FF9505',
                pending: '#BCBCBC',
            },
        },
    },
})
