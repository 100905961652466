import navigationGuards from '../navigationGuards.js'

const comonOffersBreadCrumb = (offerId, parent) => {
    return [
        {
            text: parent === 'PortfolioManagement'
                ? 'breadcrumbs.portfoliomanagement'
                : parent === 'CustomersSalesPartner'
                    ? 'breadcrumbs.customerMenu'
                    : 'breadcrumbs.procurement',
            to: parent === 'CustomersSalesPartner'
                ? '/customer-management'
                : {
                    name: parent === 'PortfolioManagement'
                        ? 'portfolio-management-frame-contracts'
                        : 'offers',
                },
        },
        {
            text: 'breadcrumbs.offers',
            to: { name: 'offers' },
        },
        {
            translatedText: `${offerId}`,
            to: { name: 'offer-details' },
        },
    ]
}

const comonStrategiesBreadCrumb = (route, parent) => {
    return [
        ...comonOffersBreadCrumb(route.params.offerId, parent),
        {
            text: 'breadcrumbs.strategies',
            to: { name: 'offer-details-strategies' },
        },
        {
            translatedText: `${route.params.offerProcStratId}`,
            to: { name: 'strategie-offer-details' },
        },
    ]
}

const getParent = (parent) => {
    return parent === 'Dashboard' ? 'Procurement' : parent || 'Procurement'
}

export default [
    {
        path: '/offers',
        name: 'offers',
        component: () => import('@/views/procurement/ProcurmentManagment.vue'),
        beforeEnter: navigationGuards.guard,
        props: true,
        meta: {
            parent: 'Procurement',
            breadcrumb: () => [
                {
                    text: 'breadcrumbs.procurement',
                    to: { name: 'offers' },
                },
                {
                    text: 'breadcrumbs.offers',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/offers/new-offer/:id',
        name: 'new-offer-no-dialog',
        component: () => import('@/views/procurement/offers/newOffer/NewOfferStepper.vue'),
        beforeEnter: (to, from, next) => {
            to.meta.breadcrumb = (route) => {
                if (from.name === 'customer-management-customers') {
                    to.meta.parent = 'CustomersSalesPartner'
                    return [
                        {
                            text: 'breadcrumbs.customerMenu',
                            to: '/customer-management',
                        },
                        {
                            text: 'breadcrumbs.customer',
                            to: { name: 'customer-management-customers' },
                        },
                        {
                            translatedText: `${route.params.id}`,
                            to: { name: 'customer-management-customers' },
                        },
                        {
                            text: 'breadcrumbs.newOffer',
                            active: true,
                        },
                    ]
                } else {
                    to.meta.parent = 'Procurement'
                    return [
                        {
                            text: 'breadcrumbs.procurement',
                            to: { name: 'offers' },
                        },
                        {
                            text: 'breadcrumbs.offers',
                            to: { name: 'offers' },
                        },
                        {
                            text: 'breadcrumbs.newOffer',
                            active: true,
                        },
                    ]
                }
            }
            navigationGuards.guardSalesPartnerCustomer(to, from, next)
        },
    },
    {
        path: '/strategies',
        name: 'strategies',
        component: () => import('@/views/procurement/ProcurmentManagment.vue'),
        beforeEnter: navigationGuards.guard,
        props: true,
        meta: {
            parent: 'Procurement',
            breadcrumb: () => [
                {
                    text: 'breadcrumbs.procurement',
                    to: { name: 'offers' },
                },
                {
                    text: 'breadcrumbs.strategies',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/offers/:offerId',
        component: () => import('@/views/procurement/offers/offerDetails/OfferManagement.vue'),
        beforeEnter: navigationGuards.guard,
        props: true,
        children: [
            {
                path: '',
                redirect: { name: 'offer-details' },
            },
            {
                path: 'details',
                name: 'offer-details',
                component: () => import('@/views/procurement/offers/offerDetails/OfferDetails.vue'),
                beforeEnter (to, from, next) {
                    to.meta.parent = getParent(from.meta.parent)
                    to.meta.breadcrumb = (route) => {
                        return [
                            ...comonOffersBreadCrumb(route.params.offerId, to.meta.parent),
                            {
                                text: 'breadcrumbs.offerDetails',
                                active: true,
                            },
                        ]
                    }
                    navigationGuards.guard(to, from, next)
                },
                props: true,
            },
            {
                path: 'strategies',
                name: 'offer-details-strategies',
                component: () => import('@/components/offertCards/StrategiesGraph.vue'),
                beforeEnter (to, from, next) {
                    to.meta.parent = getParent(from.meta.parent)
                    to.meta.breadcrumb = (route) => {
                        return [
                            ...comonOffersBreadCrumb(route.params.offerId, to.meta.parent),
                            {
                                text: 'breadcrumbs.offerStrategies',
                                active: true,
                            },
                        ]
                    }
                    navigationGuards.guard(to, from, next)
                },
                props: true,
            },
            {
                path: 'strategic-procurement',
                name: 'offer-details-strategic-procurement',
                component: () => import('@/views/portfoliomanagement/strategiesListTranches/StrategicProcurement.vue'),
                beforeEnter (to, from, next) {
                    to.meta.parent = getParent(from.meta.parent)
                    to.meta.breadcrumb = (route) => {
                        return [
                            ...comonOffersBreadCrumb(route.params.offerId, to.meta.parent),
                            {
                                text: 'breadcrumbs.offerStrategicProcurement',
                                active: true,
                            },
                        ]
                    }
                    navigationGuards.guard(to, from, next)
                },
                props: true,
            },
            {
                path: 'pricing',
                name: 'offer-details-pricing',
                component: () => import('@/views/procurement/offers/offerDetails/ValuationCard.vue'),
                beforeEnter (to, from, next) {
                    to.meta.parent = getParent(from.meta.parent)
                    to.meta.breadcrumb = (route) => {
                        return [
                            ...comonOffersBreadCrumb(route.params.offerId, to.meta.parent),
                            {
                                text: 'breadcrumbs.offerPricing',
                                active: true,
                            },
                        ]
                    }
                    navigationGuards.guard(to, from, next)
                },
                props: true,
            },
            {
                path: 'margin',
                name: 'offer-details-margin',
                component: () => import('@/views/procurement/offers/offerDetails/PriceMarginCardSP.vue'),
                beforeEnter (to, from, next) {
                    to.meta.parent = getParent(from.meta.parent)
                    to.meta.breadcrumb = (route) => {
                        return [
                            ...comonOffersBreadCrumb(route.params.offerId, to.meta.parent),
                            {
                                text: 'breadcrumbs.offerMargin',
                                active: true,
                            },
                        ]
                    }
                    navigationGuards.guard(to, from, next)
                },
                props: true,
            },
            {
                path: 'time-series',
                name: 'offer-details-time-series',
                component: () => import('@/components/offertCards/MenuTimeSeriesCard.vue'),
                beforeEnter (to, from, next) {
                    to.meta.parent = getParent(from.meta.parent)
                    to.meta.breadcrumb = (route) => {
                        return [
                            ...comonOffersBreadCrumb(route.params.offerId, to.meta.parent),
                            {
                                text: 'breadcrumbs.offerTimeSeries',
                                active: true,
                            },
                        ]
                    }
                    navigationGuards.guard(to, from, next)
                },
                props: true,
            },
            {
                path: 'meteringpoints',
                name: 'offer-details-metering-points',
                component: () => import('@/views/customerManagement/meteringPoints/MeteringPoint.vue'),
                beforeEnter (to, from, next) {
                    to.meta.parent = getParent(from.meta.parent)
                    to.meta.breadcrumb = (route) => {
                        const breadcrumbs = comonOffersBreadCrumb(route.params.offerId, to.meta.parent)
                        if (route.query.action) {
                            breadcrumbs.push({
                                text: 'breadcrumbs.offerMeteringPoints',
                                to: { name: 'offer-details-metering-points' },
                            })
                            if (!route.query?.meteringcode && !route.query?.meteringpointtype) {
                                breadcrumbs.push({
                                    text: `breadcrumbs.${route.query?.action}`,
                                    active: true,
                                })
                            } else {
                                breadcrumbs.push(
                                    {
                                        translatedText: `${route.query?.meteringcode} (${route.query?.meteringpointtype === '1' ? 'Cons' : 'Prod'})`,
                                        to: { name: 'offer-details-metering-points' },
                                    },
                                    {
                                        text: `breadcrumbs.${route.query?.action}`,
                                        active: true,
                                    },
                                )
                            }
                        } else {
                            breadcrumbs.push({
                                text: 'breadcrumbs.offerMeteringPoints',
                                active: true,
                            })
                        }
                        return breadcrumbs
                    }
                    navigationGuards.guard(to, from, next)
                },
                props: true,
            },
            {
                path: 'documents',
                name: 'offer-details-documents',
                component: () => import('@/views/procurement/offers/offerDetails/OfferDocuments.vue'),
                beforeEnter (to, from, next) {
                    to.meta.parent = getParent(from.meta.parent)
                    to.meta.breadcrumb = (route) => {
                        return [
                            ...comonOffersBreadCrumb(route.params.offerId, to.meta.parent),
                            {
                                text: 'breadcrumbs.offerDocuments',
                                active: true,
                            },
                        ]
                    }
                    navigationGuards.guard(to, from, next)
                },
                props: true,
            },
            {
                path: 'monthly-overview',
                name: 'offer-details-monthly-overview',
                component: () => import('@/views/portfoliomanagement/monthlyOverview/MonthlyOverviewDetails.vue'),
                beforeEnter (to, from, next) {
                    to.meta.parent = getParent(from.meta.parent)
                    to.meta.breadcrumb = (route) => {
                        return [
                            ...comonOffersBreadCrumb(route.params.offerId, to.meta.parent),
                            {
                                text: 'breadcrumbs.offerMonthlyOverview',
                                active: true,
                            },
                        ]
                    }
                    navigationGuards.guard(to, from, next)
                },
                props: true,
            },
            {
                path: 'monthly-overviews/:year/:month/price-volume/:aggregationDetailId',
                name: 'offer-details-monthly-overview-price-volume',
                component: () => import('@/views/portfoliomanagement/monthlyOverview/PriceVolume.vue'),
                beforeEnter (to, from, next) {
                    to.meta.parent = getParent(from.meta.parent)
                    to.meta.breadcrumb = (route) => {
                        return [
                            ...comonOffersBreadCrumb(route.params.offerId, to.meta.parent),
                            {
                                text: 'breadcrumbs.offerMonthlyOverview',
                                to: { name: 'offer-details-monthly-overview' },
                            },
                            {
                                translatedText: `${route.params.year} - ${route.params.month}`,
                                to: { name: 'offer-details-monthly-overview' },
                            },
                            {
                                text: 'breadcrumbs.offerPriceVolume',
                                active: true,
                            },
                        ]
                    }
                    navigationGuards.guard(to, from, next)
                },
                props: true,
            },
        ],
    },
    {
        path: '/offers/:offerId/strategies/new-management-strategy',
        name: 'offer-details-strategies-new-management-strategy',
        component: () => import('@/views/procurement/strategies/strategiesOfferDetails/ProcStratStepper.vue'),
        beforeEnter (to, from, next) {
            to.meta.parent = getParent(from.meta.parent)
            to.meta.breadcrumb = (route) => {
                return [
                    ...comonOffersBreadCrumb(route.params.offerId, to.meta.parent),
                    {
                        text: 'breadcrumbs.offerStrategies',
                        to: { name: 'offer-details-strategies' },
                    },
                    {
                        text: 'breadcrumbs.offerNewStrategy',
                        active: true,
                    },
                ]
            }
            navigationGuards.guard(to, from, next)
        },
        props: true,
    },
    {
        path: '/offers/:offerId/documents/adjustment',
        name: 'offer-details-documents-adjustment',
        component: () => import('@/views/procurement/offers/offerDetails/ChangeDocumentCard.vue'),
        beforeEnter (to, from, next) {
            to.meta.parent = getParent(from.meta.parent)
            to.meta.breadcrumb = (route) => {
                return [
                    ...comonOffersBreadCrumb(route.params.offerId, to.meta.parent),
                    {
                        text: 'breadcrumbs.offerDocuments',
                        to: { name: 'offer-details-documents' },
                    },
                    {
                        text: 'breadcrumbs.offerDocumentsAdjustment',
                        active: true,
                    },
                ]
            }
            navigationGuards.guard(to, from, next)
        },
        props: true,
    },
    {
        path: '/offers/:offerId/strategies/:offerProcStratId',
        component: () => import('@/views/procurement/strategies/strategiesOfferDetails/StrategiesTabManagement.vue'),
        beforeEnter: navigationGuards.guard,
        props: true,
        children: [
            {
                path: '',
                redirect: { name: 'strategie-offer-details' },
            },
            {
                path: 'details',
                name: 'strategie-offer-details',
                component: () => import('@/views/procurement/strategies/strategiesOfferDetails/StrategiesOfferDetails.vue'),
                beforeEnter (to, from, next) {
                    to.meta.parent = getParent(from.meta.parent)
                    to.meta.breadcrumb = (route) => {
                        return [
                            ...comonStrategiesBreadCrumb(route, to.meta.parent),
                            {
                                text: 'breadcrumbs.strategiesOfferDetails',
                                active: true,
                            },
                        ]
                    }
                    navigationGuards.guard(to, from, next)
                },
                props: true,
            },
            {
                path: 'time-series',
                name: 'strategie-time-series',
                component: () => import('@/views/procurement/strategies/strategiesOfferDetails/StrategiesTimeSeries.vue'),
                beforeEnter (to, from, next) {
                    to.meta.parent = getParent(from.meta.parent)
                    to.meta.breadcrumb = (route) => {
                        return [
                            ...comonStrategiesBreadCrumb(route, to.meta.parent),
                            {
                                text: 'breadcrumbs.strategiesTimeSeries',
                                active: true,
                            },
                        ]
                    }
                    navigationGuards.guard(to, from, next)
                },
                props: true,
            },
            {
                path: 'strategic-procurement',
                name: 'strategic-procurement-strategies',
                component: () => import('@/views/portfoliomanagement/strategiesListTranches/StrategicProcurement.vue'),
                beforeEnter (to, from, next) {
                    to.meta.parent = getParent(from.meta.parent)
                    to.meta.breadcrumb = (route) => {
                        return [
                            ...comonStrategiesBreadCrumb(route, to.meta.parent),
                            {
                                text: 'breadcrumbs.strategiesProcurementStrategies',
                                active: true,
                            },
                        ]
                    }
                    navigationGuards.guard(to, from, next)
                },
                props: true,
            },
            {
                path: 'margin',
                name: 'strategie-offer-details-margin',
                component: () => import('@/views/procurement/strategies/strategiesOfferDetails/StrategiesPriceMarginCardSP.vue'),
                beforeEnter (to, from, next) {
                    to.meta.parent = getParent(from.meta.parent)
                    to.meta.breadcrumb = (route) => {
                        return [
                            ...comonStrategiesBreadCrumb(route, to.meta.parent),
                            {
                                text: 'breadcrumbs.strategiesMargin',
                                active: true,
                            },
                        ]
                    }
                    navigationGuards.guard(to, from, next)
                },
                props: true,
            },
            {
                path: 'pricing',
                name: 'strategie-offer-details-pricing',
                component: () => import('@/components/offertCards/StrategiesValuationCard.vue'),
                beforeEnter (to, from, next) {
                    to.meta.parent = getParent(from.meta.parent)
                    to.meta.breadcrumb = (route) => {
                        return [
                            ...comonStrategiesBreadCrumb(route, to.meta.parent),
                            {
                                text: 'breadcrumbs.strategiesPricing',
                                active: true,
                            },
                        ]
                    }
                    navigationGuards.guard(to, from, next)
                },
                props: true,
            },
            {
                path: 'documents',
                name: 'strategies-offer-details-documents',
                component: () => import('@/views/procurement/offers/offerDetails/OfferDocuments.vue'),
                beforeEnter (to, from, next) {
                    to.meta.parent = getParent(from.meta.parent)
                    to.meta.breadcrumb = (route) => {
                        return [
                            ...comonStrategiesBreadCrumb(route, to.meta.parent),
                            {
                                text: 'breadcrumbs.strategiesDocuments',
                                active: true,
                            },
                        ]
                    }
                    navigationGuards.guard(to, from, next)
                },
                props: true,
            },
        ],
    },
]
