import functions from './functions.js'

const conversionRules = {
    'pr/MWh-->pr100/kWh': function (value) {
        return functions.toNumber(value) / 10
    },
    'pr100/kWh-->pr/MWh': function (value) {
        return functions.toNumber(value) * 10
    },
    'kW/15min-->kWh/15min': function (value) {
        return functions.toNumber(value) / 4
    },
    'kWh/15min-->kW/15min': function (value) {
        return functions.toNumber(value) * 4
    },
    'kW/1h-->kWh/1h': function (value) {
        return functions.toNumber(value)
    },
    'pr100-->pr': function (value) {
        return functions.toNumber(value) / 100
    },
    'kWh-->MWh': function (value) {
        return functions.toNumber(value) / 1000
    },
    'MWh-->kWh': function (value) {
        return functions.toNumber(value) * 1000
    },
    'KWH/15MIN-->kW/15min': function (value) {
        return functions.toNumber(value) * 4
    },
    '1h-->15min': function (value) {
        return functions.toNumber(value) * 4
    },
    'pr100/kWh*kWh-->pr': function (value) {
        return functions.toNumber(value) / 100
    },
}

export default ({

    unitConversion: function (value, fromUnit, toUnit, roundToString) {
        if (fromUnit === toUnit) {
            return functions.toNumber(value)
        }

        const conversionFunction = conversionRules[`${fromUnit}-->${toUnit}`]
        if (conversionFunction === undefined) throw new Error('no conversion rule found')

        if (roundToString !== undefined) {
            return conversionFunction(value).toFixed(roundToString)
        } else {
            return conversionFunction(value)
        }
    },

})
