import navigationGuards from '../navigationGuards.js'
import store from '../../vuex/store.js'

export default [
    {
        path: '/admin',
        component: () => import('@/views/admin/AdminManagment.vue'),
        beforeEnter: navigationGuards.guardAdminOrSalesPartner,
        children: [
            {
                path: '',
                redirect: () => {
                    const { role } = store.state
                    return {
                        name: role === 'admin'
                            ? 'admin-messages'
                            : 'admin-margins-service-fees',
                    }
                },
            },
            {
                path: 'margins-service-fees',
                name: 'admin-margins-service-fees',
                component: () => import('@/views/admin/marginsAndServiceFees/MarginsAndServiceFees.vue'),
                beforeEnter: navigationGuards.guardSalesPartner,
                meta: {
                    parent: 'Admin',
                    breadcrumb: () => [
                        {
                            text: 'breadcrumbs.admin',
                            to: { name: 'admin-margins-service-fees' },
                        },
                        {
                            text: 'breadcrumbs.adminMarginsAndServiceFees',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'messages',
                name: 'admin-messages',
                component: () => import('@/views/admin/messages/Messages.vue'),
                beforeEnter: navigationGuards.guardAdmin,
                meta: {
                    parent: 'Admin',
                    breadcrumb: () => [
                        {
                            text: 'breadcrumbs.admin',
                            to: { name: 'admin-messages' },
                        },
                        {
                            text: 'breadcrumbs.messagesAdmin',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'trading-year',
                name: 'admin-trading-year',
                component: () => import('@/views/admin/TradingYear.vue'),
                beforeEnter: navigationGuards.guardAdmin,
                meta: {
                    parent: 'Admin',
                    breadcrumb: () => [
                        {
                            text: 'breadcrumbs.admin',
                            to: { name: 'admin-messages' },
                        },
                        {
                            text: 'breadcrumbs.tradingyearAdmin',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'holidays',
                name: 'admin-holidays',
                component: () => import('@/views/admin/Holidays.vue'),
                beforeEnter: navigationGuards.guardAdmin,
                meta: {
                    parent: 'Admin',
                    breadcrumb: () => [
                        {
                            text: 'breadcrumbs.admin',
                            to: { name: 'admin-messages' },
                        },
                        {
                            text: 'breadcrumbs.holidaysAdmin',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'document-templates',
                name: 'admin-document-templates',
                component: () => import('@/views/admin/DocumentTemplates.vue'),
                beforeEnter: navigationGuards.guardAdmin,
                meta: {
                    parent: 'Admin',
                    breadcrumb: () => [
                        {
                            text: 'breadcrumbs.admin',
                            to: { name: 'admin-messages' },
                        },
                        {
                            text: 'breadcrumbs.templatesAdmin',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'emails',
                name: 'admin-emails',
                component: () => import('@/views/admin/Emails.vue'),
                beforeEnter: navigationGuards.guardAdmin,
                meta: {
                    parent: 'Admin',
                    breadcrumb: () => [
                        {
                            text: 'breadcrumbs.admin',
                            to: { name: 'admin-messages' },
                        },
                        {
                            text: 'breadcrumbs.emailsAdmin',
                            active: true,
                        },
                    ],
                },
            },
        ],
    },
]
