
import store from '../vuex/store.js'

import i18n from '../translations/translations.js'
import Highcharts from '../plugins/highcharts.js'

import dateFunctions from './dateFunctions.js'
import dateFunctionsAdd from './dateFunctionsAdd.js'
import functions from './functions.js'
import unitFunctions from './unitFunctions.js'

export default ({

    profileToTimeSeriesData: async function (csv, marketarea, timeSeries, type, decimals = 3) {
        const lines = csv.split('\n')
        const nLines = lines.length

        if (nLines < 4) throw new Error('no data')
        if (lines[nLines - 1] !== process.env.VUE_APP_EOF) throw new Error('data not complete')

        const headers = lines[0].split(';')

        const series = []
        for (let i = 1; i < nLines - 1; i++) {
            const fields = lines[i].split(';')

            for (let j = 1; j < fields.length; j++) {
                const inDeliveryField = 2
                if (type === 'meteringPoint' && j === inDeliveryField) {
                    break
                }
                if (i === 1) {
                    series.push({
                        type: 'line',
                        name: headers[j],
                        data: [],
                        dataGrouping: {
                            enabled: false,
                        },
                    })

                    if (type === 'prognosis') {
                        series[j - 1].name = timeSeries?.year + ' ' + series[j - 1].name
                        series[j - 1].year = timeSeries?.year
                    }
                    if (type === 'prognosisStrategies') {
                        series[j - 1].name = `${timeSeries?.deliveryType.replace('_', ' ')} ${timeSeries?.year}`
                        series[j - 1].year = timeSeries?.year
                    }
                }
                series[j - 1].data.push([dateFunctionsAdd.ISODateToDate(fields[0]).getTime(), functions.toNumber(fields[j])])
            }

            if (i % 500 === 0) {
                await sleep(1)
            }
        }

        if (type === 'market') {
            series[0].name = timeSeries.name
        }

        return {
            title: {
                text: '',
            },
            xAxis: {
                type: 'datetime',
            },
            yAxis: {
                labels: {
                    align: 'right',
                    formatter: function () {
                        return Highcharts.numberFormat(this.value, decimals)
                    },
                },
            },
            tooltip: {
                formatter: tooltipFormatterTimeSeries(marketarea, decimals),
                split: true,
            },
            time: {
                getTimezoneOffset: getTimezoneOffset(marketarea),
            },
            credits: {
                enabled: false,
            },
            chart: {
                height: 400,
            },
            rangeSelector: {
                inputEnabled: false,
            },
            series,
        }
    },

    changeLanguageHighcharts: function () {
        try {
            Highcharts.setOptions({
                lang: JSON.parse(i18n.t('highcharts.lang')),
                plotOptions: { series: { animation: false } },
            })
        } catch (error) {
            console.warn('Could not parse highcharts', error)
        }
    },

    changeColorHighcharts: function (colorPalette) {
        Highcharts.setOptions({
            accessibility: { enabled: false },
            colors: colorPalette,
            plotOptions: { series: { animation: false } },
        })
    },

    profileToTimeSeriesDataStrategicProcurement: async function (csv, releaseLimit, avgPrice) {
        const lines = csv.split('\n')
        const nLines = lines.length

        if (nLines < 4) throw new Error('no data')
        if (lines[nLines - 1] !== process.env.VUE_APP_EOF) throw new Error('data not complete')

        const headers = lines[0].split(';')

        const series = []
        for (let i = 1; i < nLines - 1; i++) {
            const fields = lines[i].split(';')

            for (let j = 1; j < fields.length; j++) {
                if (i === 1) {
                    series.push({
                        type: 'line',
                        name: i18n.t('ProcurementHighCharts.' + headers[j]),
                        data: [],

                    })
                    series.push({
                        type: 'line',
                        name: 'Ø-' + i18n.t('ProcurementHighCharts.price'),
                        dashStyle: 'shortdot',
                        data: [],
                        dataGrouping: {
                            enabled: false,
                        },
                        showInNavigator: true,

                    })

                    series.push({
                        type: 'scatter',
                        name: i18n.t('ProcurementHighCharts.tranche'),
                        marker: {
                            enabled: true,
                        },
                        showInNavigator: false,
                        tooltip: {
                            pointFormatter: function () {
                                return '<b>' + this.name + '</b>' + ':<br/>' +
                    i18n.t('ProcurementHighCharts.date') + ': ' + this.date + '<br/>' +
                    i18n.t('ProcurementHighCharts.marketTrend') + ': ' + this.trend + '<br/>' +
                    i18n.t('ProcurementHighCharts.weight') + ': ' + this.weight + '<br/>' +
                    i18n.t('ProcurementHighCharts.accumulatedWeight') + ': ' + this.weightSum + '<br/>' +
                    i18n.t('ProcurementHighCharts.price') + ': ' + this.price + '<br/>' +
                    i18n.t('ProcurementHighCharts.averagePrice') + ': ' + this.avgPrice
                            },
                        },
                        data: [],
                    })
                    if (releaseLimit !== null) {
                        series.push({
                            type: 'line',
                            name: i18n.t('ProcurementHighCharts.releaseLimit'),
                            dashStyle: 'shortdot',
                            data: [],
                            dataGrouping: {
                                enabled: false,
                            },
                            showInNavigator: true,

                        })
                    }
                }

                fields[0] = dateFunctionsAdd.ISODateToDate(fields[0]).getTime()
                for (let i = 0; i < avgPrice.length; i++) {
                    if (fields[0] === avgPrice[i].startDate) {
                        const tranche = {
                            marker: {
                                symbol: 'square',
                                radius: 5,
                            },
                            y: functions.toNumber(avgPrice[i].price),
                            x: fields[0],
                            name: i18n.t('ProcurementHighCharts.tranche') + ' ' + (i + 1),
                            date: avgPrice[i].date,
                            trend: i18n.t('ProcurementHighCharts.neutral'),
                            weight: avgPrice[i].weight,
                            weightSum: avgPrice[i].weightSum,
                            price: avgPrice[i].price,
                            avgPrice: avgPrice[i].avgPrice,
                        }
                        if (avgPrice[i].trend === 'upward') {
                            tranche.marker.symbol = 'triangle'
                            tranche.marker.radius = 7
                            tranche.trend = i18n.t('ProcurementHighCharts.upward')
                        }

                        if (avgPrice[i].trend === 'downward') {
                            tranche.marker.symbol = 'triangle-down'
                            tranche.marker.radius = 7
                            tranche.trend = i18n.t('ProcurementHighCharts.downward')
                        }
                        series[2].data.push(tranche)
                    }

                    if (fields[0] === avgPrice[avgPrice.length - 1].startDate) {
                        series[1].data.push([fields[0], functions.toNumber(avgPrice[avgPrice.length - 1].avgPrice)])
                    } else if (fields[0] >= avgPrice[i].startDate && fields[0] <= avgPrice[i].endDate) {
                        series[1].data.push([fields[0], functions.toNumber(avgPrice[i].avgPrice)])
                    }
                }
                if (releaseLimit !== null) {
                    series[3].data.push([fields[0], functions.toNumber(releaseLimit)])
                }
                series[j - 1].data.push([fields[0], functions.toNumber(fields[j]) || null])
            }

            if (i % 500 === 0) {
                await sleep(1)
            }
        }

        return {
            xAxis: {
                type: 'datetime',
            },
            yAxis: {
                title: {},
                opposite: false,
                labels: {
                    align: 'right',
                    formatter () {
                        return Highcharts.numberFormat(this.value, 4)
                    },
                },
            },
            legend: {
                enabled: true,
            },
            credits: {
                enabled: false,
            },
            chart: {
                height: 'inherit',
            },
            rangeSelector: {
                inputDateFormat: '%Y-%m-%d',
                inputEditDateFormat: '%Y-%m-%d',
                inputEnabled: false,
            },
            exporting: {
                buttons: {
                    contextButton: {
                        menuItems: [
                            'printChart',
                            'separator',
                            'downloadPNG',
                            'downloadJPEG',
                            'downloadPDF',
                            'downloadSVG',
                        ],
                    },
                },
            },
            series,
        }
    },

    profileToTimeSeriesDataMarket: async function (csv, timeSeries) {
        const lines = csv.split('\n')
        const nLines = lines.length
        if (lines[nLines - 1] !== process.env.VUE_APP_EOF) throw new Error('data not complete')

        const series = [
            {
                type: 'line',
                name: timeSeries.name,
                data: [],
                dataGrouping: {
                    enabled: false,
                },
                showInNavigator: true,
            },
        ]
        for (let i = 1; i < nLines - 1; i++) {
            const fields = lines[i].split(';')

            for (let j = 1; j < fields.length; j++) {
                if (i > 2) {
                    const day = new Date(fields[0]).getDay()

                    if (fields[1] === '' && (day === 6 || day === 0)) {
                        const yesterdaysValue = lines[i - 1].split(';')[1]
                        const beforeYesterdaysValue = lines[i - 2].split(';')[1]

                        if (day === 6 && yesterdaysValue === '') continue

                        if (yesterdaysValue !== '') {
                            fields[1] = yesterdaysValue
                        } else if (beforeYesterdaysValue !== '') {
                            fields[1] = beforeYesterdaysValue
                        }
                    }
                }

                fields[0] = dateFunctionsAdd.ISODateToDate(fields[0]).getTime()

                series[j - 1].data.push([fields[0], functions.toNumber(fields[j])])
            }

            if (i % 500 === 0) {
                await sleep(1)
            }
        }

        return {
            xAxis: {
                type: 'datetime',
                crosshair: false,

            },
            yAxis: {},
            legend: {
                enabled: true,
            },
            credits: {
                enabled: false,
            },
            chart: {
                height: 'inherit',
            },
            rangeSelector: {
                inputDateFormat: '%Y-%m-%d',
                inputEditDateFormat: '%Y-%m-%d',
                inputEnabled: false,
            },
            exporting: {
                buttons: {
                    contextButton: {
                        menuItems: [
                            'printChart',
                            'separator',
                            'downloadPNG',
                            'downloadJPEG',
                            'downloadPDF',
                            'downloadSVG',
                        ],
                    },
                },
            },
            series,
        }
    },

    profileToTimeSeriesDataStrategies: async function (csv, marketarea, year, digits = { graph: 3, price: 4, volume: 0, value: 2 }) {
        const lines = csv.split('\n')
        const nLines = lines.length

        if (nLines < 4) throw new Error('no data')
        if (lines[nLines - 1] !== process.env.VUE_APP_EOF) throw new Error('data not complete')

        const headers = lines[0].split(';')
        const indexOp = headers.indexOf('buy_to_close')
        let avgOp = null

        const tzRuleId = store.state.timezoneConfigAll.mappingKey[marketarea]
        const timezoneConfig = store.state.timezoneConfigAll.data[tzRuleId]

        const series = []
        const sumObj = {}
        const valuationOp = []
        for (let i = 1; i < nLines - 1; i++) {
            const fields = lines[i].split(';')

            if (i === 1) {
                for (let val = 0; val < 2; val++) {
                    const [dateNow, dateBefore, unit, unitCode, currency, start] = fields[fields.length - 3].split('|')
                    const startLocal = dateFunctionsAdd.getDatePartFromDBString(
                        dateFunctions.getDateTimeLocal(dateFunctionsAdd.ISODateToDate(start), timezoneConfig),
                    )

                    const date = (val === 0) ? dateNow : dateBefore
                    valuationOp.push({
                        date,
                        unit,
                        unitCode,
                        currency,
                        missing: false,
                        startTime: dateFunctionsAdd.ISODateToDate(start).getTime(),
                        start: startLocal,
                        sumPrice: 0,
                        buy: { volumeKwh: 0, price: 0, value: null },
                        sell: { volumeKwh: 0, price: 0, value: null },
                    })
                }
            }

            for (let j = 2; j < fields.length - 4; j++) {
                if (i === 1) {
                    if (headers[j] === 'cons_deals' || headers[j] === 'prod_deals' || headers[j] === 'net_deals') {
                        series.push({
                            type: 'area',
                            name: year + ' ' + i18n.t('HighChartsGeneral.' + headers[j]),
                            data: [],
                            year,
                            dataGrouping: {
                                enabled: false,
                            },
                        })
                    } else {
                        series.push({
                            type: 'line',
                            name: year + ' ' + i18n.t('HighChartsGeneral.' + headers[j]),
                            data: [],
                            year,
                            dataGrouping: {
                                enabled: false,
                            },
                        })
                    }

                    sumObj[j] = { sum: 0, ts: [] }
                }

                // write avg over all 15min in an hour as 15min values
                // (the logic works because the data from the backend always starts at beginning of an hour and ends at the end of an hour)
                sumObj[j].sum += functions.toNumber(fields[j])
                sumObj[j].ts.push(dateFunctionsAdd.ISODateToDate(fields[0]).getTime())
                if (i % 4 === 0) {
                    // set avg in all values
                    const avg = sumObj[j].sum / 4
                    for (const ts of sumObj[j].ts) {
                        series[j - 2].data.push([ts, avg])
                    }
                    if (j === indexOp) avgOp = avg
                    // reset
                    sumObj[j] = { sum: 0, ts: [] }
                }
            }

            // valuation of open position
            for (let j = fields.length - 2; j < fields.length; j++) {
                const valuationOpObj = valuationOp[j - (fields.length - 2)]
                const { startTime } = valuationOpObj
                if (dateFunctionsAdd.ISODateToDate(fields[0]).getTime() >= startTime) {
                    let direction
                    if (i % 4 === 0) {
                        direction = (avgOp >= 0) ? 'buy' : 'sell'
                        valuationOpObj[direction].volumeKwh += avgOp * Math.sign(avgOp)
                    }
                    if (fields[j] !== '' && !valuationOpObj.missing) {
                        valuationOpObj.sumPrice += functions.toNumber(fields[j])
                        if (i % 4 === 0) {
                            valuationOpObj[direction].price += avgOp * Math.sign(avgOp) * valuationOpObj.sumPrice / 4
                            valuationOpObj.sumPrice = 0
                        }
                    } else {
                        valuationOpObj.missing = true
                    }
                }
            }

            if (i % 4 === 0) avgOp = null

            if (i % 1000 === 0) {
                await sleep(1)
            }
        }

        for (const valuationOpObj of valuationOp) {
            const { missing, unitCode } = valuationOpObj
            for (const direction of ['buy', 'sell']) {
                const valuation = valuationOpObj[direction]
                valuation.price = (!missing && valuation.volumeKwh > 0) ? functions.roundNumber(valuation.price / valuation.volumeKwh, digits.price) : null
                // important that this comes after price calculation
                valuation.volumeKwh = functions.roundNumber(unitFunctions.unitConversion(valuation.volumeKwh, 'kW/1h', 'kWh/1h'), digits.volume)
                if (valuation.price !== null) valuation.value = functions.roundNumber(unitFunctions.unitConversion(valuation.price * valuation.volumeKwh, `${unitCode}*kWh`, 'pr'), digits.value)
            }
            delete valuationOpObj.sumPrice
            delete valuationOpObj.startTime
            delete valuationOpObj.unitCode
        }

        return {
            chartOptions: {
                title: {
                    text: '',
                },
                xAxis: {
                    type: 'datetime',
                },
                yAxis: {
                    title: { text: 'kW' },
                    labels: {
                        align: 'right',
                        formatter: function () {
                            return Highcharts.numberFormat(this.value, digits.graph)
                        },
                    },
                },
                tooltip: {
                    formatter: tooltipFormatterTimeSeries(marketarea, digits.graph),
                    split: true,
                },
                time: {
                    getTimezoneOffset: getTimezoneOffset(marketarea),

                },
                credits: {
                    enabled: false,
                },
                chart: {
                    type: 'area',
                    height: 500,
                },
                rangeSelector: {
                    inputEnabled: false,
                },
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: [
                                'printChart',
                                'separator',
                                'downloadPNG',
                                'downloadJPEG',
                                'downloadPDF',
                                'downloadSVG',
                            ],
                        },
                    },
                },
                series,
            },
            valuation: valuationOp,
        }
    },

})

const tooltipFormatterTimeSeries = function (marketarea, decimals) {
    const tzRuleId = store.state.timezoneConfigAll.mappingKey[marketarea]
    const timezoneConfig = store.state.timezoneConfigAll.data[tzRuleId]
    return function () {
        return ['<span style="font-size: 10px">' + (dateFunctions.getTimeLocalQuarter(new Date(this.x), timezoneConfig)).from + '</span>'].concat(
            this.points
                ? this.points.map(function (point) {
                    return '<span style="color:' + point.series.color + ';">\u25CF ' + '</span>' + point.series.name + ': ' + '<b>' + Highcharts.numberFormat(point.y, decimals) + '</b>'
                })
                : [],
        )
    }
}

const getTimezoneOffset = function (marketarea) {
    const tzRuleId = store.state.timezoneConfigAll.mappingKey[marketarea]
    const timezoneConfig = store.state.timezoneConfigAll.data[tzRuleId]

    return function (dateTimeUTCNumber) {
        const dateTimeUTC = new Date(dateTimeUTCNumber)
        if (dateTimeUTC.toString() === 'Invalid Date') {
            return 0
        }
        return parseInt((dateFunctions.getTimeLocalQuarter(dateTimeUTC, timezoneConfig)).from.substring(19, 22), 10) * -60
    }
}

const sleep = function (ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
}
