import 'flag-icon-css/css/flag-icon.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import './css/fonts.css'
import '@fortawesome/fontawesome-free/css/all.css'

import Vue from 'vue'
import i18n from './translations/translations.js'
import vuetify from './plugins/vuetify.js'
import App from './App.vue'
import router from './router/index.js'
import VueCookies from 'vue-cookies'
import store from './vuex/store.js'

import './plugins/highcharts.js'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import CKEditor from '@ckeditor/ckeditor5-vue2'

library.add(faUpRightFromSquare)

Vue.component('FontAwesomeIcon', FontAwesomeIcon)

Vue.use(VueCookies)
Vue.use(CKEditor)

Vue.prototype.$domainFull = process.env.VUE_APP_DOMAIN || window.location.hostname

new Vue({ store, router, i18n, vuetify, render: h => h(App) }).$mount('#app')
