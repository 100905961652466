import Vue from 'vue'
import VueI18n from 'vue-i18n'
import i18n from '../translations/translations.js'
import { BaseFormatter } from './baseFormatter.js'

const de = require('./de.json')
const en = require('./en.json')
const fr = require('./fr.json')

Vue.use(VueI18n)

const translations = {
    de: {
        ...de,
        TOAST_MESSAGES: ({ values: { body, code } }) => {
            const translation = {
                FILE_DOWNLOAD: () => 'Das File wird gerade erstellt und danach heruntergeladen, bitte haben Sie einen Moment Geduld.',
                INVALID_DATE: () => `Der Wert "${body}" ist kein gültiges Datum.`,
                INVALID_BOOL: () => `Der Wert "${body}" ist kein gültiger Wahr/Falsch-Wert.`,
                INVALID_FORMAT: () => `Im File gibt es ungültige Werte. Der Fehler befindet sich ungefähr auf Zeile "${body.line}". Die Werte sind: ${body.fields.join(';')}. Bitte korrigieren Sie das File und versuchen Sie es erneut.`,
                INVALID_FILE: () => 'Das File hat das falsche Format.',
                THRESHOLD_EXCEEDED: () => 'Die Schwelle für ownProduction/ ownConsumption wurde überschritten. Bitte korrigieren Sie die Werte.',
                VALIDATION_ERROR: () => 'Das Formular, das Sie ausgefüllt haben enthält Fehler. Bitte überprüfen Sie Ihre Eingabe.',
                LOGIN_ERROR: () => 'Benutzername oder Passwort ist falsch. Bitte versuchen Sie es erneut.',
                USER_INACTIVE: () => `Der Registrierungsprozess ist noch nicht abgeschlossen. Der Benutzer wurde noch nicht aktiviert. Bitte überprüfen Sie Ihre E-Mail ${body}.`,
                SALESPARTNER_INACTIVE: () => `Der Vertriebspartner ${body} ist noch nicht aktiv. Bitte wenden Sie sich an Ihren Vertriebspartner.`,
                TOO_MANY_REQUESTS: () => 'Die Anzahl unzuläsiger Verbindungsversuche auf unser System wurde überschritten. Sie wurden deshalb von blockiert. Wenden Sie sich bitte an unseren Support.',
                NOT_AUTHORIZED: () => 'Ihre Sitzung ist abgelaufen. Bitte loggen Sie sich erneut ein.',
                ERR_BAD_RESPONSE: () => 'Die Verbindung zum Stromportal wurde unterbrochen. Bitte versuchen Sie es erneut oder kontaktieren Sie unseren Support.',
                ERROR_TEMPLATE_TRANSFORMATION: () => `Bei der Transformation ist der folgende Fehler aufgetreten: ${body}.`,
                SUCCESS_GENERAL: () => 'Die Aktion wurde erfolgreich durchgeführt.',
                ERROR_TIMEOUT: () => 'Zeitüberschreitung der Anfrage',
                ALREADY_REGISTERED: () => `Die E-Mail Adresse ${body} wird bereits verwendet. Bitte verwenden Sie eine andere E-Mail Adresse.`,
                VERIFICATION_EXPIRED: () => 'Der Verifikationscode ist abgelaufen. Bitte erneuern Sie den Verifikationscode.',
                VERIFICATION_WRONG: () => 'Der Verifikationscode ist falsch. Bitte geben Sie den korrekten Verifikationscode ein.',
                CAPTCHA_WRONG: () => 'Der Captcha ist falsch. Bitte geben Sie den korrekten Captcha ein.',
                CAPTCHA_EXPIRED: () => 'Der Captcha ist abgelaufen. Bitte erzeugen Sie einen neuen.',
                LINK_EXPIRED: () => 'Der Link ist abgelaufen.',
                ACTIVATIONCODE_WRONG: () => 'Falscher Aktivierungscode',
                STRATEGY_NETTED_HEDGE: () => 'Veränderung bei saldiertem Geschäft nicht erlaubt.',
                STRATEGY_PROC_STRAT_ALREADY_SETTLED: () => 'Die Bewirtschaftung ist bereits abgeschlossen.',
                STRATEGY_CHANGE_NOT_POSSIBLE: () => 'Diese Anpassung ist momentan nicht möglich.',
                STRATEGY_TRANCHE_IS_PROCESSING: () => 'Eine Anpassung ist derzeit nicht möglich. Eine Tranche wird momentan verarbeitet. Bitte versuchen Sie es später erneut.',
                STRATEGY_WEIGHT_TOO_HIGH: () => `Die gewählte Menge (${body.weight}) übersteigt die offene Menge (${body.weightPercentSum}). Bitte reduzieren Sie die Menge.`,
                STRATEGY_PROC_STRAT_STILL_PROCESSING: () => 'Die Tranche ist in Bearbeitung. Bitte versuchen Sie es später noch einmal.',
                TWO_FACTOR_ALREADY_ACTIVATED: () => 'Die 2-Faktor-Authentifizierung ist bereits aktiviert.',
                AUTHANTICATOR_CODE_WRONG: () => 'Der Authentifizierungscode ist falsch. Bitte überprüfen Sie, ob Sie den QR-Code korrekt gescannt haben oder fragen Sie unseren Support.',
                COPY_CLIPBOARD_INFO: () => 'Der Wiederherstellungscode wurde kopiert.',
                ACTIVATION_SUCCESSFULL: () => 'Der Benutzer wurde erfolgreich registriert. Sie können sich nun einloggen.',
                TWO_FACTOR_RECOVERY_SUCCESS: () => 'Die 2-Faktor-Authentifizierung wurde wiederhergestellt.',
                WRONG_RECOVERY_KEY: () => 'Der Wiederherstellungscode ist falsch.',
                QRCODE_EXPIRED: () => 'Der QR-Code ist abgelaufen. Klicken Sie OK für einen neuen QR-Code.',
                CUSTOMER_ALREADY_EXISTS: () => 'Dieser Kunde existiert bereits.',
                ERROR_SEND_MESSAGE: () => 'Die Nachricht konnte nicht versendet werden.',
                REGISTRATION_SUCCESSFULL: () => 'Sie sollten in kürze eine E-Mail erhalten. Bitte überprüfen Sie Ihr Postfach um Ihren Account zu bestätigen.(tbt)',
                TRANCHE_PROCESSING: () => 'Aktuell keine Eingabe möglich. Die aktuelle Tranche wird gerade verarbeitet. Bitte warten Sie, bis die Bearbeitung abgeschlossen ist, um Daten einzugeben.(tbt)',
                OFFER_STATE_ERROR: () => 'Der Angebotsstatus lässt eine Freigabe nicht zu.',
                OFFER_EXPIRED: () => 'Das Angebot ist abgelaufen. Das Angebot kann nicht mehr abgelehnt oder angenommen werden.',
                CHOOSE_DELIVERY_YEAR: () => 'Bitte wählen Sie mindestens ein Lieferjahr in den Konditionen aus.',
                OFFER_ALREADY_IN_DELIVERY: () => 'Das Angebot kann nicht angenommen werden, da für diesen Messpunkt nach dem angegebenen Datum bereits ein anderer Vertrag besteht.',
                OFFER_NOT_TODAY: () => 'Das Angebot kann nicht freigegeben werden. Das Angebot kann nur am Angebotstag freigegeben werden.',
                OFFER_FUTURE_PROFILE_NOT_SET: () => 'Das Angebot kann nicht freigegeben werden. Die Langfristprognose wurde noch nicht hochgeladen.',
                RELEASE_OFFER_STATE: () => 'Der Angebotsstatus lässt eine Freigabe nicht zu.',
                RELEASE_OFFER: () => `Sind Sie sicher, dass Sie das Angebot ${body} freigeben möchten?`,
                JOB_IS_RUNNING: () => 'Die Aggregation läuft momentan. Bitte versuchen Sie es später erneut.',
                ALREADY_IN_DELIVERY: () => 'Die Aktion kann nicht durchgeführt werden. Für einige Messpunkte existiert für den gewählten Lieferzeitraum bereits ein anderer Vertrag.',
                NO_VALID_COMMUNICATION_CHANEL: () => 'Beim Kommunikationskanal handelt es sich nicht um eine verifizierte E-Mail Adresse.',
                AT_LEAST_ONE_USER: () => 'Das Löschen ist nicht möglich. Es muss mindestens ein Benutzer existieren.',
                NO_NEW_VERSION_FOUND: () => 'Es existiert keine neue Version.',
                NEW_VERSION_HAS_CHANGED: () => 'Die neue Version hat sich in der Zwischenzeit geändert. Eine Akutalisierung wird durchgeführt.',
                TOO_MANY_PENDING_OFFERS: () => 'Die maximale Anzahl an pendenten Angeboten ist erreicht. Sie können aktuell keine neuen Angebote anfragen.',
                NO_FUTURE_PROFILE: () => 'Bitte laden Sie zuerst eine Langfristprognose hoch.',
                STATE_ERROR: () => 'Diese Aktion ist beim jetzigen Angebotsstatus unzulässig.',
                LIMIT_USER_MAIL_REACHED: () => 'Ihr tägliches Mail-Versandlimit ist aufgebraucht. Bitte versuchen Sie es morgen erneut.',
                DELTA_CHANGE: () => 'Es gab in der Zwischenzeit Mengenänderungen. Die Schadensberechnung wird zurückgesetzt. Bitte berechnen sie den Schaden danach erneut.',
                STATE_DECISION_ERROR: () => 'Der Entscheidungsstatus lässt eine Änderung nicht zu.',
                NO_EFFECT: () => 'Diese Anpassung hat keine Wirkung.',
                AGGREGATE_JOB_RUNNING: () => 'Die Aggregation läuft momentan. Bitte versuchen Sie es später erneut.',
                PROPOSAL_NOT_ACTUAL_ANYMORE: () => 'Der Anpassungsvorschlag ist nicht mehr aktuell und wurde zurückgesetzt. Bitte prüfen Sie den Vorschlag erneut.',
                REQUEST_ALREADY_EXISTS: () => 'Es gibt bereits einen offenen Antrag für diesen Messpunkt. Ein erneuter Antrag ist erst nach dessen Bearbeitung möglich.',
                NO_OFFER_JOB_FOUND: () => 'Es wurde kein Task gefunden.',
                DATA_MISMATCH_RELOAD: () => 'Es existieren Datenkonflikte. Bitte aktualisieren Sie die Ansicht.',
                AUTHORIZATION_NOT_BEFORE_PROCUREMENT_DATE: () => 'Die Freigabe kann nicht vor dem Bewirtschaftungstag erfolgen.',
                MULTIPLICATOR_CANNOT_BE_AUTHORIZED: () => 'Die Freigabe wurde bereits getätigt.',
                TOO_MANY_ADDRESS_CHANGE_JOBS: () => 'Der Messpunkt wird momentan aktualisiert, bitte versuchen Sie es später erneut.',
                OPEN_CONTRACT_CHANGE: () => 'Es gibt offene Vertragsanpassungen. Bitte versuchen Sie es später erneut.',
                OPEN_INTERFACE_JOBS: () => 'Es gibt noch offene Schnittstellen-Jobs. Bitte versuchen Sie es später erneut.',
                NO_VARIABLE_ADD_ON_POSSIBLE: () => 'In gewissen Jahren ist keine Menge vorhanden. Für diese Jahre ist ein variabler Aufschlag nicht möglich.',
                NO_OFFER: () => 'Es wurde kein Task gefunden.',
                MAIL_SEND_ERROR: () => 'Momentan können keine E-Mails versendet werden. Bitte Entschuldigen Sie die Umstände und versuchen Sie es später erneut.',
                DATA_MISMATCH: () => 'Es existieren Datenkonflikte. Bitte aktualisieren Sie die Ansicht.',
                PRICE_NOT_SET: () => 'Das Angebot kann nicht freigegeben werden. Der Preis wurde noch nicht gesetzt.',
                DOC_NOT_ARCHIVED: () => 'Es gibt noch Dokumente, welche zuerst archiviert werden müssen (bitte warten Sie bis die entsprechenden Jobs gelaufen sind).',
                ALREADY_SIGNED: () => 'Bereits akzeptiert (bitte Seite aktualisieren).',
                DOC_NOT_ACTUAL: () => 'Dokument nicht mehr aktuell (bitte Seite aktualisieren).',
                AUTO_RESERVED_WORD: () => '"(auto)" ist ein reserviertes Wort.',
                ERROR_TEMPLATE: () => `Der folgende Fehler ist aufgetreten: ${body}.`,
                NO_CERTIFICATE_PRICE_FOUND: () => 'Keine Preise für die Stromqualität vorhanden. Bitte erfassen Sie die fehlenden Preise.',
                AUTO_LOGOUT: () => 'Sie können keinen Benutzer aktivieren, wenn sie bereits eingeloggt sind. Sie wurden automatisch ausgeloggt.',
                ERR_BAD_REQUEST: () => 'Ihr Benutzerkonto hat nicht die erforderlichen Berechtigungen oder Sie sind nicht im EKT-Netzwerk. Leider können Sie aufgrund dieser Einschränkungen nicht auf den angeforderten Inhalt zugreifen.',
                TRANSLATED_NOTIFICATION: () => i18n.t(body),

                default: () => 'Es ist ein Fehler aufgetreten. Bitte prüfen Sie Ihre Verbindung und versuchen es erneut.',
            }
            const translate = translation[code] || translation.default

            return translate()
        },
    },
    fr: {
        ...fr,
        TOAST_MESSAGES: ({ values: { body, code } }) => {
            const translation = {
                FILE_DOWNLOAD: () => 'The file is being created and then downloaded, please be patient for a moment. (tbt)',
                INVALID_DATE: () => `The value "${body}" is not a valid date. (tbt)`,
                INVALID_BOOL: () => `The value "${body}" is not a valid true/false value.(tbt)`,
                INVALID_FORMAT: () => `There are invalid values in the file. The error is located approximately on line "${body.line}". The values are: ${body.fields.join(';')}. Please correct the file and try again.(tbt)`,
                INVALID_FILE: () => 'The file has the wrong format.(tbt)',
                THRESHOLD_EXCEEDED: () => 'The threshold for ownProduction/ ownConsumption has been exceeded. Please correct the values.(tbt)',
                VALIDATION_ERROR: () => 'The form you have filled in contains errors. Please check your input.(tbt)',
                LOGIN_ERROR: () => 'Username or password is incorrect. Please try again.(tbt)',
                USER_INACTIVE: () => `The registration process is not completed yet. The user has not been activated yet. Please check your email ${body}.(tbt)`,
                SALESPARTNER_INACTIVE: () => `The ${body} salespartner is not yet active. Please contact your sales partner.(tbt)`,
                TOO_MANY_REQUESTS: () => 'The number of unauthorized connection attempts to our system has been exceeded. Therefore, you have been blocked by . Please contact our support.(tbt)',
                ERR_BAD_RESPONSE: () => 'The connection was interrupted. Please try again or contact our support.(tbt)',
                NOT_AUTHORIZED: () => 'Your session has expired. Please log in again.(tbt)',
                ERROR_TEMPLATE_TRANSFORMATION: () => `The following error occurred during the transformation: ${body}.`,
                SUCCESS_GENERAL: () => 'Action has been successfully completed.',
                ERROR_TIMEOUT: () => 'Timeout of the query',
                ALREADY_REGISTERED: () => `L'adresse électronique ${body} est déjà utilisée. Veuillez utiliser une autre adresse électronique`,
                VERIFICATION_EXPIRED: () => 'Le code de vérification a expiré. Veuillez vous en faire envoyer un nouveau.',
                VERIFICATION_WRONG: () => 'Le code de vérification n\'est pas correct. Veuillez entrer le code de vérification correct.',
                CAPTCHA_WRONG: () => 'Le captcha n\'est pas correct. Veuillez entrer le captcha correct.',
                CAPTCHA_EXPIRED: () => 'Le captcha a expiré, veuillez en créer un nouveau.',
                LINK_EXPIRED: () => 'Le lien a expiré.(tbt)',
                ACTIVATIONCODE_WRONG: () => 'Wrong activation code(tbt)',
                STRATEGY_NETTED_HEDGE: () => 'Change not permitted for netted business.(tbt)',
                STRATEGY_PROC_STRAT_ALREADY_SETTLED: () => 'La stratégie d\'approvisionnement est déjà terminée.',
                STRATEGY_CHANGE_NOT_POSSIBLE: () => 'Cette adaptation n\'est pas possible pour le moment.',
                STRATEGY_TRANCHE_IS_PROCESSING: () => 'Une tranche est actuellement en cours de traitement et une adaptation n\'est pas possible. Veuillez attendre que la tranche soit traitée.',
                STRATEGY_WEIGHT_TOO_HIGH: () => 'Le poids a été choisi trop élevé.',
                STRATEGY_PROC_STRAT_STILL_PROCESSING: () => 'The production tranche is still processing, please try again later (tbt)',
                TWO_FACTOR_ALREADY_ACTIVATED: () => 'L\'authentification à 2 facteurs est déjà activée.',
                COPY_CLIPBOARD_INFO: () => 'Recoverycode copied! (tbt)',
                AUTHANTICATOR_CODE_WRONG: () => 'The authentication code is incorrect. Please check if you have scanned the QR code correctly or ask our support. (tbt)',
                ACTIVATION_SUCCESSFULL: () => 'The user has been registered successfully. You can now log in.(tbt)',
                TWO_FACTOR_RECOVERY_SUCCESS: () => 'L\'authentification à deux facteurs a été rétablie avec succès.',
                QRCODE_EXPIRED: () => 'Le code QR a expiré. Lorsque vous cliquez sur OK, un nouveau QR Code est généré.',
                WRONG_RECOVERY_KEY: () => 'Le code de recouvrement est incorrect.',
                CUSTOMER_ALREADY_EXISTS: () => 'Le client existe déjà',
                ERROR_SEND_MESSAGE: () => 'Le message n\'a pas pu être envoyé.(tbt)',
                REGISTRATION_SUCCESSFULL: () => 'Vous devriez recevoir un e-mail sous peu. Veuillez vérifier votre boîte de réception pour confirmer votre compte.(tbt)',
                TRANCHE_PROCESSING: () => 'Aktuell keine Eingabe möglich. Die aktuelle Tranche wird gerade verarbeitet. Bitte warten Sie, bis die Bearbeitung abgeschlossen ist, um Daten einzugeben.(tbt)',
                OFFER_STATE_ERROR: () => 'Der Angebotsstatus lässt eine Freigabe nicht zu. (tbt)',
                OFFER_EXPIRED: () => 'Das Angebot ist abgelaufen. Das Angebot kann nicht mehr abgelehnt oder angenommen werden.(tbt)',
                CHOOSE_DELIVERY_YEAR: () => 'Bitte wählen Sie mindestens ein Lieferjahr in den Konditionen aus.(tbt)',
                OFFER_ALREADY_IN_DELIVERY: () => 'Das Angebot kann nicht angenommen werden, da für diesen Messpunkt nach dem angegebenen Datum bereits ein anderer Vertrag besteht.(tbt)',
                OFFER_NOT_TODAY: () => 'Das Angebot kann nicht freigegeben werden. Das Angebot kann nur am Angebotstag freigegeben werden.(tbt)',
                OFFER_FUTURE_PROFILE_NOT_SET: () => 'Das Angebot kann nicht freigegeben werden. Die Langfristprognose wurde noch nicht hochgeladen.(tbt)',
                RELEASE_OFFER_STATE: () => 'Der Angebotsstatus lässt eine Freigabe nicht zu.(tbt)',
                RELEASE_OFFER: () => `Sind Sie sicher, dass Sie das Angebot ${body} freigeben möchten?(tbt)`,
                JOB_IS_RUNNING: () => 'L\'agrégation est actuellement en cours, veuillez réessayer plus tard.',
                ALREADY_IN_DELIVERY: () => 'L\'adaptation de la période d’approvisionnement aura pour conséquence que les points de mesure seront approvisionnés en double. L\'action ne peut donc être réalisée.',
                NO_VALID_COMMUNICATION_CHANEL: () => 'Le canal de communication n\'est pas une adresse électronique vérifiée.',
                AT_LEAST_ONE_USER: () => 'Suppression impossible. Il doit y avoir au moins un utilisateur.',
                NO_NEW_VERSION_FOUND: () => 'Pas de nouvelle version à activer',
                NEW_VERSION_HAS_CHANGED: () => 'La nouvelle version a changé entretemps. Une actualisation est en cours.',
                TOO_MANY_PENDING_OFFERS: () => 'Il est actuellement impossible de demander une offre car vous avez déjà trop d\'offres en attente.',
                NO_FUTURE_PROFILE: () => 'Veuillez d\'abord télécharger une prévision à long terme',
                STATE_ERROR: () => 'Compte tenu du statut actuel de l\'offre, cette action n\'est pas autorisée.',
                LIMIT_USER_MAIL_REACHED: () => 'Pour compléter le processus, nous devons vous envoyer un e-mail. Malheureusement, votre limite quotidienne d\'envoi de courrier électronique est épuisée. Veuillez réessayer demain.',
                DELTA_CHANGE: () => 'Il y a eu des changements quantitatifs entre-temps. Veuillez vérifier les suppléments et envoyer à nouveau.',
                STATE_DECISION_ERROR: () => 'Le statut de décision ne permet aucune modification.',
                NO_EFFECT: () => 'Cet ajustement n\'aurait aucun effet.',
                AGGREGATE_JOB_RUNNING: () => 'L\'agrégation est actuellement en cours, veuillez réessayer plus tard.',
                PROPOSAL_NOT_ACTUAL_ANYMORE: () => 'La proposition d\'ajustement n\'est plus d\'actualité et a été réinitialisée: Veuillez vérifier et éventuellement ajuster.',
                REQUEST_ALREADY_EXISTS: () => 'Une demande est déjà ouverte pour ce point de mesure, une nouvelle demande ne sera possible qu\'après traitement de la première.',
                NO_OFFER_JOB_FOUND: () => 'Aucune tâche trouvée',
                DATA_MISMATCH_RELOAD: () => 'There is a data mismatch, please reload.(tbt)',
                AUTHORIZATION_NOT_BEFORE_PROCUREMENT_DATE: () => 'The authorization is not allowed before the procurment date.(tbt)',
                MULTIPLICATOR_CANNOT_BE_AUTHORIZED: () => 'It is already authorized.(tbt)',
                TOO_MANY_ADDRESS_CHANGE_JOBS: () => 'Le point de mesure est actuellement mis à jour, veuillez réessayer plus tard.',
                OPEN_CONTRACT_CHANGE: () => 'Il y a des adaptations de contrat en cours, veuillez réessayer plus tard.',
                OPEN_INTERFACE_JOBS: () => 'Il y a encore des jobs d\'interface ouverts. Veuillez réessayer plus tard.',
                NO_VARIABLE_ADD_ON_POSSIBLE: () => 'Certaines années, la quantité passe à 0. Pour ces années, un supplément de prix variable n\'est pas possible.',
                NO_OFFER: () => 'Aucune tâche trouvée',
                MAIL_SEND_ERROR: () => 'Pour l\'instant, aucun courrier électronique ne peut être envoyé. Veuillez excuser les circonstances et réessayer plus tard.',
                DATA_MISMATCH: () => 'There is a data mismatch, please reload.(tbt)',
                PRICE_NOT_SET: () => 'L\'offre ne peut pas encore être approuvée car le prix n\'est pas encore défini.',
                DOC_NOT_ARCHIVED: () => 'Il y a encore des documents qui doivent être archivés (veuillez attendre que les jobs d\'archivage soient terminés).',
                ALREADY_SIGNED: () => 'Déjà accepté (veuillez actualiser la page).',
                DOC_NOT_ACTUAL: () => 'Document périmé (veuillez actualiser la page).',
                AUTO_RESERVED_WORD: () => '"(auto)" est un mot réservé.',
                ERROR_TEMPLATE: () => `The following error occurred: ${body}.`,
                NO_CERTIFICATE_PRICE_FOUND: () => 'Keine Preise für die Stromqualität vorhanden. Bitte erfassen Sie die fehlenden Preise.(tbt)',
                AUTO_LOGOUT: () => 'Sie können keinen Benutzer aktivieren, wenn sie bereits eingeloggt sind. Sie wurden automatisch ausgeloggt.(tbt)',
                ERR_BAD_REQUEST: () => 'Ihr Benutzerkonto hat nicht die erforderlichen Berechtigungen oder Sie sind nicht im EKT-Netzwerk. Leider können Sie aufgrund dieser Einschränkungen nicht auf den angeforderten Inhalt zugreifen.(tbt)',
                TRANSLATED_NOTIFICATION: () => i18n.t(body),

                default: () => 'An error occurred, please check your connection and try again.',
            }
            const translate = translation[code] || translation.default

            return translate()
        },
    },
    en: {
        ...en,
        TOAST_MESSAGES: ({ values: { body, code } }) => {
            const translation = {
                FILE_DOWNLOAD: () => 'The file is being created and then downloaded, please be patient for a moment. (tbt)',
                INVALID_DATE: () => `The value "${body}" is not a valid date. (tbt)`,
                INVALID_BOOL: () => `The value "${body}" is not a valid true/false value.(tbt)`,
                INVALID_FORMAT: () => `There are invalid values in the file. The error is located approximately on line "${body.line}". The values are: ${body.fields.join(';')}. Please correct the file and try again.(tbt)`,
                INVALID_FILE: () => 'The file has the wrong format.(tbt)',
                THRESHOLD_EXCEEDED: () => 'The threshold for ownProduction/ ownConsumption has been exceeded. Please correct the values.(tbt)',
                VALIDATION_ERROR: () => 'The form you have filled in contains errors. Please check your input.(tbt)',
                LOGIN_ERROR: () => 'Username or password is incorrect. Please try again.(tbt)',
                USER_INACTIVE: () => `The registration process is not completed yet. The user has not been activated yet. Please check your email ${body}.(tbt)`,
                SALESPARTNER_INACTIVE: () => `The ${body} salespartner is not yet active. Please contact your sales partner.(tbt)`,
                TOO_MANY_REQUESTS: () => 'The number of unauthorized connection attempts to our system has been exceeded. Therefore, you have been blocked by . Please contact our support.(tbt)',
                NOT_AUTHORIZED: () => 'Your session has expired. Please log in again.(tbt)',
                ERR_BAD_RESPONSE: () => 'The connection was interrupted. Please try again or contact our support.(tbt)',
                ERROR_TEMPLATE_TRANSFORMATION: () => `The following error occurred during the transformation: ${body}.`,
                SUCCESS_GENERAL: () => 'Action has been successfully completed.(tbt)',
                ERROR_TIMEOUT: () => 'Délai de la requête',
                ALREADY_REGISTERED: () => `The e-mail address ${body} is already in use. Please use a different e-mail address.`,
                VERIFICATION_EXPIRED: () => 'The verification code has expired. Please have a new one sent to you.',
                VERIFICATION_WRONG: () => 'The verification code is not correct. Please enter the correct verification code.',
                CAPTCHA_WRONG: () => 'The captcha is not correct. Please enter the correct captcha',
                CAPTCHA_EXPIRED: () => 'The captcha has expired, please create a new one.',
                LINK_EXPIRED: () => 'The link has expired.',
                ACTIVATIONCODE_WRONG: () => 'Wrong activation code(tbt)',
                STRATEGY_NETTED_HEDGE: () => 'Change not permitted for netted business.(tbt)',
                STRATEGY_PROC_STRAT_ALREADY_SETTLED: () => 'The procurement strategy has already been completed.',
                STRATEGY_CHANGE_NOT_POSSIBLE: () => 'This adjustment is not possible at the moment.',
                STRATEGY_TRANCHE_IS_PROCESSING: () => 'Currently a tranche is being processed and an adjustment is not possible. Please wait until the tranche has been processed.',
                STRATEGY_WEIGHT_TOO_HIGH: () => 'The weight was set too high.',
                STRATEGY_PROC_STRAT_STILL_PROCESSING: () => 'The production tranche is still processing, please try again later (tbt)',
                TWO_FACTOR_ALREADY_ACTIVATED: () => 'The 2-factor authentication is already enabled.',
                COPY_CLIPBOARD_INFO: () => 'Recoverycode copied! (tbt)',
                AUTHANTICATOR_CODE_WRONG: () => 'The authentication code is incorrect. Please check if you have scanned the QR code correctly or ask our support. (tbt)',
                ACTIVATION_SUCCESSFULL: () => 'The user has been registered successfully. You can now log in.(tbt)',
                TWO_FACTOR_RECOVERY_SUCCESS: () => 'The 2-factor authentication was successfully restored.',
                QRCODE_EXPIRED: () => 'The QR Code has expired. As soon as you click OK a new QR Code will be generated.',
                WRONG_RECOVERY_KEY: () => 'The recovery code is wrong.',
                CUSTOMER_ALREADY_EXISTS: () => 'Customer already exists',
                ERROR_SEND_MESSAGE: () => 'The message could not be sent.(tbt)',
                REGISTRATION_SUCCESSFULL: () => 'You should receive an email soon. Please check your mailbox to confirm your account(tbt)',
                TRANCHE_PROCESSING: () => 'Currently no input possible. The current tranche is being processed. Please wait until processing is complete to enter data.(tbt)',
                OFFER_STATE_ERROR: () => 'Der Angebotsstatus lässt eine Freigabe nicht zu. (tbt)',
                OFFER_EXPIRED: () => 'Das Angebot ist abgelaufen. Das Angebot kann nicht mehr abgelehnt oder angenommen werden.(tbt)',
                CHOOSE_DELIVERY_YEAR: () => 'Bitte wählen Sie mindestens ein Lieferjahr in den Konditionen aus.(tbt)',
                OFFER_ALREADY_IN_DELIVERY: () => 'Das Angebot kann nicht angenommen werden, da für diesen Messpunkt nach dem angegebenen Datum bereits ein anderer Vertrag besteht.(tbt)',
                OFFER_NOT_TODAY: () => 'Das Angebot kann nicht freigegeben werden. Das Angebot kann nur am Angebotstag freigegeben werden.(tbt)',
                OFFER_FUTURE_PROFILE_NOT_SET: () => 'Das Angebot kann nicht freigegeben werden. Die Langfristprognose wurde noch nicht hochgeladen.(tbt)',
                RELEASE_OFFER_STATE: () => 'Der Angebotsstatus lässt eine Freigabe nicht zu.(tbt)',
                RELEASE_OFFER: () => `Sind Sie sicher, dass Sie das Angebot ${body} freigeben möchten?(tbt)`,
                JOB_IS_RUNNING: () => 'The aggregation job is currently running, please try again later.',
                ALREADY_IN_DELIVERY: () => 'Adjusting the delivery period leads to metering points being supplied twice. The action therefore cannot be implemented',
                NO_VALID_COMMUNICATION_CHANEL: () => 'The communication channel is not a verified e-mail address.',
                AT_LEAST_ONE_USER: () => 'Deletion not possible. There must be at least one user.',
                NO_NEW_VERSION_FOUND: () => 'No new version to activate',
                NEW_VERSION_HAS_CHANGED: () => 'New version has changed in the meantime. A refresh is in progress.',
                TOO_MANY_PENDING_OFFERS: () => 'A request for quotation is currently not possible, because you already have too many pending offers.',
                NO_FUTURE_PROFILE: () => 'Please upload a long-term forecast first',
                STATE_ERROR: () => 'This action is inadmissible at the current status of the offer.',
                LIMIT_USER_MAIL_REACHED: () => 'To complete the process we need to send you an e-mail. Unfortunately your daily mail sending limit is used up. Please try again tomorrow.',
                DELTA_CHANGE: () => 'There have been quantity changes in the meantime. Please check surcharges and send again.',
                STATE_DECISION_ERROR: () => 'The status of the decision does not permit a change.',
                NO_EFFECT: () => 'This adjustment would have no effect.',
                AGGREGATE_JOB_RUNNING: () => 'The aggregation job is currently running, please try again later.',
                PROPOSAL_NOT_ACTUAL_ANYMORE: () => 'The adjustment suggestion is no longer current and has been reset: Please check and possibly adjust.',
                REQUEST_ALREADY_EXISTS: () => 'There is already an open application for this metering point. A new application is only possible after it has been processed.',
                NO_OFFER_JOB_FOUND: () => 'No task found',
                DATA_MISMATCH_RELOAD: () => 'There is a data mismatch, please reload.',
                AUTHORIZATION_NOT_BEFORE_PROCUREMENT_DATE: () => 'The authorization is not allowed before the procurment date.',
                MULTIPLICATOR_CANNOT_BE_AUTHORIZED: () => 'It is already authorized.',
                TOO_MANY_ADDRESS_CHANGE_JOBS: () => 'The measuring point is currently being updated, please try again later.',
                OPEN_CONTRACT_CHANGE: () => 'There are open contract adjustments, please try again later.',
                OPEN_INTERFACE_JOBS: () => 'There are still open interface jobs. Please try again later.',
                NO_VARIABLE_ADD_ON_POSSIBLE: () => 'In certain years, the quantity goes to 0. For these years, a variable price surcharge is not possible.',
                NO_OFFER: () => 'No task found',
                MAIL_SEND_ERROR: () => 'At the moment no e-mails can be sent. Please excuse the circumstances and try again later.',
                DATA_MISMATCH: () => 'There is a data mismatch, please reload.',
                PRICE_NOT_SET: () => 'The offer cannot be released because the price has not yet been set.',
                DOC_NOT_ARCHIVED: () => 'There are still documents that need to be archived (please wait until the archiving jobs are completed).',
                ALREADY_SIGNED: () => 'Already accepted (please refresh the page).',
                DOC_NOT_ACTUAL: () => 'Document no longer current (please refresh the page).',
                AUTO_RESERVED_WORD: () => '"(auto)" is a reserved word',
                ERROR_TEMPLATE: () => `The following error occurred: ${body}.`,
                NO_CERTIFICATE_PRICE_FOUND: () => 'Keine Preise für die Stromqualität vorhanden. Bitte erfassen Sie die fehlenden Preise.(tbt)',
                AUTO_LOGOUT: () => 'Sie können keinen Benutzer aktivieren, wenn sie bereits eingeloggt sind. Sie wurden automatisch ausgeloggt.(tbt)',
                ERR_BAD_REQUEST: () => 'Ihr Benutzerkonto hat nicht die erforderlichen Berechtigungen oder Sie sind nicht im EKT-Netzwerk. Leider können Sie aufgrund dieser Einschränkungen nicht auf den angeforderten Inhalt zugreifen.(tbt)',
                TRANSLATED_NOTIFICATION: () => i18n.t(body),

                default: () => 'An error occurred, please check your connection and try again.',
            }
            const translate = translation[code] || translation.default

            return translate()
        },
    },
}

export default new VueI18n({
    locale: 'de',
    fallbackLocale: 'de',
    formatter: process.env.VUE_APP_TRANSLATION === window.location.hostname && new BaseFormatter({ de, en, fr }),
    messages: translations,
})
