import functions from './functions.js'

export default ({

    addMinutes: function (dateTime, m) {
        m = functions.toInteger(m)
        return new Date(dateTime.getTime() + m * 60 * 1000)
    },

    addQuarterHours: function (dateTime, qh) {
        qh = functions.toInteger(qh)
        return this.addMinutes(dateTime, qh * 15)
    },

    addHours: function (dateTime, h) {
        h = functions.toInteger(h)
        return this.addMinutes(dateTime, h * 60)
    },

    addDays (dateISOString, days, returnString = false) {
        // Achtung Date nicht mit new Date() initialisieren, da setDate immer relativ zum Beginn des Monats rechnet und es sonst bei übergängen Probleme gibt
        days = functions.toInteger(days)

        const result = this.ISODateToDate(dateISOString)
        result.setUTCDate(result.getUTCDate() + days)
        if (returnString === true) return this.dateUTCToDateString(result)
        return result
    },

    dateUTCToDateString (date) {
        return date.toISOString().substring(0, 10)
    },

    ISODateToDate: function (ISODateTime) {
        try {
            if (/^\d{4}-\d{2}-\d{2}[T ]\d{2}:\d{2}:\d{2}(?:[+-]\d{2}|[+-]\d{2}:\d{2}|Z)$/.test(ISODateTime)) {
                // ISODateTime --> yyyy-mm-ddTHH24:MM:SS+tz
                // we always need a time zone

                // replace Z wit +00
                ISODateTime = ISODateTime.replace(/Z/, '+00')

                // year
                const year = functions.toInteger(ISODateTime.substring(0, 4))
                // Monat 0-11
                const month = functions.toInteger(ISODateTime.substring(5, 7)) - 1
                if (!(month >= 0 && month <= 11)) throw new Error('Invalid Date')
                // day
                const day = functions.toInteger(ISODateTime.substring(8, 10))
                if (!(day >= 1 && day <= 31)) throw new Error('Invalid Date')
                // hour
                const hour = functions.toInteger(ISODateTime.substring(11, 13))
                if (!(hour >= 0 && hour <= 23)) throw new Error('Invalid Date')
                // minute
                const minute = functions.toInteger(ISODateTime.substring(14, 16))
                if (!(minute >= 0 && minute <= 59)) throw new Error('Invalid Date')
                // second
                const second = functions.toInteger(ISODateTime.substring(17, 19))
                if (!(second >= 0 && second <= 59)) throw new Error('Invalid Date')
                // tz
                const tzSign = ISODateTime.substring(19, 20)
                const tzTime = ISODateTime.substring(20)
                const tzTimeSplit = tzTime.split(':')

                const tzHours = functions.toInteger(`${tzSign}${tzTimeSplit[0]}`)
                if (!(tzHours >= -24 && tzHours <= 24)) throw new Error('Invalid Date')

                const tzMinutes = (tzTimeSplit.length === 2) ? functions.toInteger(`${tzSign}${tzTimeSplit[1]}`) : 0
                if (!(tzMinutes >= -59 && tzMinutes <= 59)) throw new Error('Invalid Date')

                return this.addMinutes(this.addHours(new Date(Date.UTC(year, month, day, hour, minute, second)), -tzHours), -tzMinutes)
            } else if (/^\d{4}-\d{2}-\d{2}$/.test(ISODateTime)) {
                // ISODate --> yyyy-mm-dd

                // year
                const year = functions.toInteger(ISODateTime.substring(0, 4))
                // Monat 0-11
                const month = functions.toInteger(ISODateTime.substring(5, 7)) - 1
                if (!(month >= 0 && month <= 11)) throw new Error('Invalid Date')
                // day
                const day = functions.toInteger(ISODateTime.substring(8, 10))
                if (!(day >= 1 && day <= 31)) throw new Error('Invalid Date')

                return new Date(Date.UTC(year, month, day))
            } else {
                return 'Invalid Date'
            }
        } catch (err) {
            return 'Invalid Date'
        }
    },

    checkISODate: function (ISODateTime) {
        try {
            if (/^\d{4}-\d{2}-\d{2}$/.test(ISODateTime)) return true
            return false
        } catch (err) {
            return false
        }
    },

    getDatePartFromDBString (dbString) {
        return dbString.substring(0, 10)
    },

    getYearPartFromDBString (dbString, checkDate = true, asInteger = false) {
        if (checkDate === true) this.ISODateToDate(dbString, false, true)
        const yearPart = dbString.substring(0, 4)
        if (asInteger === true) return functions.toInteger(yearPart)
        return yearPart
    },

    getMonthPartFromDBString (dbString, checkDate = true, asInteger = false) {
        if (checkDate === true) this.ISODateToDate(dbString, false, true)
        const monthPart = dbString.substring(5, 7)
        if (asInteger === true) return functions.toInteger(monthPart)
        return monthPart
    },

    getDayPartFromDBString (dbString, checkDate = true, asInteger = false) {
        if (checkDate === true) this.ISODateToDate(dbString, false, true)
        const dayPart = dbString.substring(8, 10)
        if (asInteger === true) return functions.toInteger(dayPart)
        return dayPart
    },

    getTimePartFromDBString (dbString, checkDate = true, asInteger = false) {
        if (checkDate === true) this.ISODateToDate(dbString, false, true)
        const dayPart = dbString.substring(11)
        if (asInteger === true) return functions.toInteger(dayPart)
        return dayPart
    },

    isoStringRemoveMs (isoString) {
        return isoString.split('.')[0] + 'Z'
    },

    ISODateToSwissFormat: function (ISODateTime) {
        if (/^\d{4}-\d{2}-\d{2}$/.test(ISODateTime)) {
            // ISODate --> yyyy-mm-dd

            // year
            // let year = functions.toInteger(ISODateTime.substring(0,4))
            // Monat 0-11
            const month = functions.toInteger(ISODateTime.substring(5, 7)) - 1
            if (!(month >= 0 && month <= 11)) return 'Invalid Date'
            // day
            const day = functions.toInteger(ISODateTime.substring(8, 10))
            if (!(day >= 1 && day <= 31)) return 'Invalid Date'

            return `${ISODateTime.substring(8, 10)}.${ISODateTime.substring(5, 7)}.${ISODateTime.substring(0, 4)}`
        } else {
            return 'Invalid Date'
        }
    },

})
