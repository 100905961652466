import navigationGuards from '../navigationGuards.js'

export default [
    {
        path: '/audit',
        name: 'audt',
        component: () => import('@/views/audit/Audit.vue'),
        beforeEnter: navigationGuards.guard,
        meta: {
            parent: 'Audit',
        },
    },
]
