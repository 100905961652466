export default [
    {
        path: '/login',
        name: 'Login',
        alias: '/',
        component: () => import('@/views/auth/Login.vue'),
    },
    {
        path: '/registration/:type',
        name: 'RegistrationFirm',
        component: () => import('@/views/auth/registration/RegistrationView.vue'),
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: () => import('@/views/auth/ResetPassword.vue'),
    },
    {
        path: '/reset-2fa',
        name: 'TwoFactorAuthRecovery',
        component: () => import('@/views/auth/TwoFactorAuthRecovery.vue'),
    },
    {
        path: '/set-new-password/:id',
        name: 'SetNewPassword',
        component: () => import('@/views/auth/registration/SetNewPassword.vue'),
    },
    {
        path: '/activate-user/:id',
        name: 'ActivateUser',
        component: () => import('@/views/auth/registration/UserActivation.vue'),
    },
]
