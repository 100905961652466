
import functions from './functions.js'
import downloadFunctions from './downloadFunctions.js'

export default ({

    async getConditionsPortal (termKey) {
        const { id, name } = termKey
        const response = await downloadFunctions.axiosStreamSerializable(`/api/termsConditionsPortalPdf/${id}`, true)

        const blob = downloadFunctions.checkCompleteFileArrayBuffer(response.data)
        const pdf = new Blob([blob], { type: 'application/pdf' })
        functions.forceFileDownload(pdf, name + '.pdf')
    },

})
