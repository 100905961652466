const BigNumber = require('bignumber.js')

BigNumber.config({
    DECIMAL_PLACES: 20,
    ROUNDING_MODE: BigNumber.ROUND_HALF_UP, // round away from zero (--> same as numeric type in postgres)
})

// no Type coercion --> see https://mikemcl.github.io/bignumber.js/#type-coercion
BigNumber.prototype.valueOf = function () {
    throw Error('no BigNumber type coercion allowed')
}

const conversionRules = {
    'pr/MWh-->pr100/kWh': function (value) {
        return division(toExactNumber(value), toExactNumber(10))
    },
    'pr100/kWh-->pr/MWh': function (value) {
        return multiplication(toExactNumber(value), toExactNumber(10))
    },
    'kW/15min-->kWh/15min': function (value) {
        return division(toExactNumber(value), toExactNumber(4))
    },
    'kW/1h-->kWh/1h': function (value) {
        return toExactNumber(value)
    },
    'pr100-->pr': function (value) {
        return division(toExactNumber(value), toExactNumber(100))
    },
    'kWh-->MWh': function (value) {
        return division(toExactNumber(value), toExactNumber(1000))
    },
    'MWh-->kWh': function (value) {
        return multiplication(toExactNumber(value), toExactNumber(1000))
    },
    'KWH/15MIN-->kW/15min': function (value) {
        return multiplication(toExactNumber(value), toExactNumber(4))
    },
    'KWT/15MIN-->kW/15min': function (value) {
        return toExactNumber(value)
    },
    '1h-->15min': function (value) {
        return multiplication(toExactNumber(value), toExactNumber(4))
    },
    'pr100/kWh*kWh-->pr': function (value) {
        return division(toExactNumber(value), toExactNumber(100))
    },
    'pr/MWh*kW/15min-->pr': function (value) {
        return division(toExactNumber(value), toExactNumber(4000))
    },
}

const roundModes = {
    ROUND_UP: 'ROUND_UP',
    ROUND_DOWN: 'ROUND_DOWN',
    ROUND_CEIL: 'ROUND_CEIL',
    ROUND_FLOOR: 'ROUND_FLOOR',
    ROUND_HALF_UP: 'ROUND_HALF_UP',
    ROUND_HALF_DOWN: 'ROUND_HALF_DOWN',
    ROUND_HALF_EVEN: 'ROUND_HALF_EVEN',
    ROUND_HALF_CEIL: 'ROUND_HALF_CEIL',
    ROUND_HALF_FLOOR: 'ROUND_HALF_FLOOR',
}

const toExactNumber = function (number) {
    if (isExactNumber(number)) return number
    if (typeof number === 'string') number = number.replace(/['’]/g, '')
    const bigNumber = new BigNumber(number)
    if (bigNumber.isNaN() || !bigNumber.isFinite()) throw new Error('invalid bigNumber')
    return bigNumber
}

const checkToExactNumber = function (number) {
    try {
        toExactNumber(number)
        return true
    } catch (err) {
        console.log(err)
        return false
    }
}

const isExactNumber = function (number) {
    return BigNumber.isBigNumber(number)
}

// important in deep copy function
const copyExactNumber = function (number) {
    if (isExactNumber(number) !== true) throw new Error('no exact number')
    return new BigNumber(number)
}

const stringExactNumber = function (bigNumber) {
    if (!(bigNumber instanceof BigNumber)) throw new Error('no BigNumber')
    return bigNumber.toString()
}

const addition = function () {
    if (arguments.length < 2) throw new Error('invalid arguments')

    let result = toExactNumber(arguments[0])

    for (let i = 1; i < arguments.length; i++) {
        result = result.plus(toExactNumber(arguments[i]))
    }

    return result
}

const subtraction = function () {
    if (arguments.length < 2) throw new Error('invalid arguments')

    let result = toExactNumber(arguments[0])

    for (let i = 1; i < arguments.length; i++) {
        result = result.minus(toExactNumber(arguments[i]))
    }

    return result
}

const multiplication = function () {
    if (arguments.length < 2) throw new Error('invalid arguments')

    let result = toExactNumber(arguments[0])

    for (let i = 1; i < arguments.length; i++) {
        result = result.multipliedBy(toExactNumber(arguments[i]))
    }

    return result
}

const division = function (numerator, denominator) {
    return (toExactNumber(numerator)).dividedBy(toExactNumber(denominator))
}

const negated = function (number) {
    return (toExactNumber(number)).negated()
}

const isInteger = function (number) {
    return (toExactNumber(number)).isInteger()
}

const isEqual = function (number, compareTo) {
    return (toExactNumber(number)).isEqualTo(toExactNumber(compareTo))
}

const isGreaterThan = function (number, compareTo) {
    return (toExactNumber(number)).isGreaterThan(toExactNumber(compareTo))
}

const isGreaterThanOrEqualTo = function (number, compareTo) {
    return (toExactNumber(number)).isGreaterThanOrEqualTo(toExactNumber(compareTo))
}

const isLessThan = function (number, compareTo) {
    return (toExactNumber(number)).isLessThan(toExactNumber(compareTo))
}

const isLessThanOrEqualTo = function (number, compareTo) {
    return (toExactNumber(number)).isLessThanOrEqualTo(toExactNumber(compareTo))
}

const isZero = function (number) {
    return (toExactNumber(number)).isZero()
}

const isNegative = function (number) {
    return (toExactNumber(number)).isNegative()
}

const abs = function (number) {
    return (toExactNumber(number)).absoluteValue()
}

const max = function () {
    const vArr = []
    for (let i = 0; i < arguments.length; i++) {
        vArr.push(toExactNumber(arguments[i]))
    }
    return BigNumber.max(...vArr)
}

const random = function (digits) {
    return BigNumber.random(digits)
}

const hasAbsDiffDeviation = function (number1, number2, maxDiff) {
    return isGreaterThan(abs(subtraction(toExactNumber(number1), toExactNumber(number2))), toExactNumber(maxDiff))
}

const round = function (number, digits, toString = false, roundMode) {
    if (Number.isInteger(digits) !== true) throw new Error('invalid digits')
    if (roundMode !== undefined) {
        const roundModeMap = roundModes[roundMode]
        if (roundModeMap === undefined) throw new Error('invalid round mode')
        roundMode = BigNumber[roundModeMap]
    }
    const rounded = (toExactNumber(number)).decimalPlaces(digits, roundMode)
    if (toString) return rounded.toString()
    return rounded
}

const floor = function (number, toString = false) {
    const rounded = (toExactNumber(number)).decimalPlaces(0, BigNumber.ROUND_FLOOR)
    if (toString) return rounded.toString()
    return rounded
}

const ceiling = function (number, toString = false) {
    const rounded = (toExactNumber(number)).decimalPlaces(0, BigNumber.ROUND_CEIL)
    if (toString) return rounded.toString()
    return rounded
}

const modulo = function (number, mod) {
    return (toExactNumber(number)).modulo(toExactNumber(mod))
}

const isAbsDeviation = function (actualValue, compareValue, maxDeviation, maxAbsDevWhenZero = 0.1) {
    const actualValueNum = toExactNumber(actualValue)
    const compareValueNum = toExactNumber(compareValue)
    const maxDeviationNum = toExactNumber(maxDeviation)
    maxAbsDevWhenZero = toExactNumber(maxAbsDevWhenZero)
    if (isNegative(maxAbsDevWhenZero)) throw new Error('invalid maxAbsDevWhenZero')

    // max deviation
    if (isZero(compareValue)) {
        if (isGreaterThanOrEqualTo(actualValueNum, negated(maxAbsDevWhenZero)) && isLessThanOrEqualTo(actualValueNum, maxAbsDevWhenZero)) {
            return false
        } else {
            return true
        }
    }

    const dev = abs(division(subtraction(actualValueNum, compareValueNum), compareValueNum))
    return isGreaterThan(dev, maxDeviationNum)
}

const unitConversion = function (value, fromUnit, toUnit) {
    if (fromUnit === toUnit) {
        return toExactNumber(value)
    }

    const conversionFunction = conversionRules[`${fromUnit}-->${toUnit}`]
    if (conversionFunction === undefined) throw new Error('no conversion rule found')

    return conversionFunction(value)
}

export default ({
    toExactNumber,
    checkToExactNumber,
    isExactNumber,
    stringExactNumber,
    copyExactNumber,
    round,
    floor,
    ceiling,
    modulo,
    max,
    hasAbsDiffDeviation,
    addition,
    subtraction,
    division,
    multiplication,
    negated,
    isInteger,
    isEqual,
    isGreaterThan,
    isGreaterThanOrEqualTo,
    isLessThan,
    isLessThanOrEqualTo,
    isAbsDeviation,
    random,
    unitConversion,
})
