
export default ({

    fetchGet: async function (url, contentType = 'application/json', mode = 'no-cors', cacheMode = 'no-store', redirect = 'follow') {
        const response = await fetch(url, {
            method: 'GET',
            mode,
            cache: cacheMode,
            credentials: 'same-origin',
            headers: {
                'Content-Type': contentType,
            },
            redirect,
        })

        if (response.ok === false) {
            try {
                const errorJSON = await response.json()
                throw errorJSON
            } catch {
                throw new Error(`${response.status}: ${response.statusText}`)
            }
        }

        return response
    },

    fetchPost: async function (url, data, contentType = 'application/json', mode = 'no-cors', cacheMode = 'no-store', redirect = 'follow') {
        const options = {
            method: 'POST',
            mode,
            cache: cacheMode,
            credentials: 'same-origin',
            headers: {},
            redirect,
        }

        if (contentType !== '') {
            options.headers['Content-Type'] = contentType
        }

        if (contentType === 'application/json') {
            options.body = JSON.stringify(data)
        } else {
            options.body = data
        }

        const response = await fetch(url, options)

        if (response.ok === false) {
            const errorJSON = await response.json()
            throw errorJSON
        }

        return response
    },

})
