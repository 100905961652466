<template>
  <div>
    <v-bottom-sheet
      :value="dialog"
      persistent
      flat
    >
      <v-card
        dark
        color="rgb(242 242 242)"
        height="100%"
      >
        <v-card-text class="pa-0">
          <v-container fluid>
            <v-row
              dense
              justify="center"
            >
              <v-card-text
                class="text-h6 spacing-playground pa-0 ma-0"
                :style="{color:linkColor}"
              >
                {{ $t('ResignTermsPortalDialog.termsPortalsChangedHeader') }}
              </v-card-text>
              <v-card-text
                class="spacing-playground pa-0 ma-0"
                :style="{color:linkColor}"
              >
                <v-container fluid>
                  <v-col>
                    <v-spacer />
                    <v-icon
                      color="info"
                      icon="mdi-information"
                    />
                    {{ $t('ResignTermsPortalDialog.termsPortalsChanged') }}
                  </v-col>
                </v-container>
              </v-card-text>
            </v-row>

            <v-row
              dense
              justify="center"
            >
              <v-col class="d-inline-flex">
                <v-spacer />
                <v-checkbox
                  v-model="acceptObj.acceptPortalConditions"
                  class="mt-0 check-box-white"
                  color="primary"
                  light
                  :hide-details="validationsAcceptObj.acceptPortalConditions.is_valid"
                  :error-messages="validationsAcceptObj.acceptPortalConditions.text"
                  :ripple="false"
                >
                  <template #label>
                    <div :style="{cursor: 'default'}">
                      {{ $t('ResignTermsPortalDialog.termsPortalsAcceptedPart1') }}
                      <span
                        v-for="(termKey, key) in termsKeys"
                        :key="key"
                      >
                        <span v-if="key > 0">
                          {{ `${(key === termsKeys.length - 1) ? $t('ResignTermsPortalDialog.and') : ','}` }}
                        </span>
                        <button
                          class="text-decoration-underline"
                          @click.stop="getDocument(termKey)"
                        >{{ termKey.name }}</button>
                      </span>
                    </div>
                  </template>
                </v-checkbox>
                <v-spacer />
              </v-col>
            </v-row>
            <v-row
              dense
              justify="center"
            >
              <v-col>
                <v-btn
                  color="primary"
                  dark
                  class="mt-3 mb-3"
                  @click="accept()"
                >
                  {{ $t('ResignTermsPortalDialog.ok') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { apiGet, apiPost } from '@/api/axiosWrapper.js'
import functions from '@/api/functions.js'
import sharedFunctions from '@/api/sharedFunctions.js'
import validationFunctions from '@/api/validationFunctions.js'
import { mapState } from 'vuex'

export default {
    name: 'ResignTermsPortalDialog',

    data () {
        return {
            dialog: false,
            loadingDialog: false,
            termsKeys: [],
            acceptObj: {
                acceptPortalConditions: false,
            },
            validationsAcceptObj: {
                acceptPortalConditions: {
                    is_valid: true,
                    text: '',
                },
                overall: {
                    is_valid: true,
                    text: '',
                },
            },
        }
    },
    computed: {
        ...mapState([
            'user',
            'language',
            'colorTableToolbarDialog',
            'linkColor',
        ]),
    },
    async mounted () {
        await this.loadDocumentsData()
    },
    methods: {
        async accept () {
            const check = this.validate()
            if (check) {
                this.loadingDialog = true
                try {
                    await apiPost('/api/resignTermsConditionsPortal', this.termsKeys)
                    this.$emit('closed')
                } finally {
                    this.loadingDialog = false
                }
            }
        },
        async getDocument (termKey) {
            this.loadingDialog = true
            try {
                await sharedFunctions.getConditionsPortal(termKey)
            } finally {
                this.loadingDialog = false
            }
        },
        async loadDocumentsData () {
            try {
                this.loadingDialog = true
                const termsConditionsPortal = await apiGet(`/api/termsConditionsPortal/${this.$root.$i18n.locale}/${this.$domainFull}`)
                this.termsKeys = termsConditionsPortal.data
                this.dialog = true
            } catch (errors) {
                this.$emit('closed')
                this.$store.dispatch('setRoleMenuDefault')
                this.$store.dispatch('setLoginState', false)
                this.$router.push('/login')
            } finally {
                this.loadingDialog = false
            }
        },
        cleanValidations () {
            functions.cleanValidation(this.validationsAcceptObj)
        },
        validate () {
            this.cleanValidations()
            let validateAcceptObject = true
            Object.keys(this.validationsAcceptObj).forEach((item) => {
                if (!['overall'].includes(item)) {
                    this.validationsAcceptObj[item] = validationFunctions[item](this.acceptObj[item])
                    if (!this.validationsAcceptObj[item].is_valid) validateAcceptObject = false
                }
            })
            if (!validateAcceptObject) {
                this.validationsAcceptObj.overall.is_valid = false
                this.validationsAcceptObj.overall.text = this.$t('ResignTermsPortalDialog.inputError')
            } else {
                this.validationsAcceptObj.overall.is_valid = true
                this.validationsAcceptObj.overall.text = ''
            }
            return validateAcceptObject
        },
    },
}
</script>
