<template>
  <div>
    <v-row
      justify="center"
      class="d-flex align-center"
    >
      <v-btn
        class="footer-button"
        text
        to="/imprint"
        :ripple="false"
      >
        {{ $t('FooterNav.imprint') }}
      </v-btn>
      <div
        v-for="(termKey, key) in termsKeys"
        :key="key"
      >
        <span class="mx-1 separator-size"> • </span>
        <v-btn
          class="footer-button"
          @click.stop="getDocument(termKey)"
        >
          {{ termKey.name }}
        </v-btn>
      </div>
    </v-row>
  </div>
</template>

<script>
import { apiGet } from '@/api/axiosWrapper.js'
import sharedFunctions from '@/api/sharedFunctions.js'

export default {
    data () {
        return {
            termsKeys: [],
        }
    },
    mounted () {
        this.loadDocumentsData()
    },
    methods: {
        async getDocument (termKey) {
            await sharedFunctions.getConditionsPortal(termKey)
        },
        async loadDocumentsData () {
            const language = this.$root.$i18n.locale
            const termsConditionsPortal = await apiGet(`/api/termsConditionsPortal/${language}/${this.$domainFull}`)
            this.termsKeys = termsConditionsPortal.data
        },
    },
}
</script>
<style scoped>
.footer-button:hover{
  border-bottom: 1px solid #585859;;
  border-radius: 0px;
}
.footer-button{
  padding: 0 2px!important;
  text-transform:none;
  size: 12px;
  box-shadow: none;
  background-color:transparent;
}
.separator-size{
  font-size: 12px;
}
.v-btn.v-size--default {
  font-size: 12px;
  color: #585859;
  background-color:transparent;
  min-width: 0;
}
.footer-button a{
  text-decoration: none;
  color:#585859;
  padding: 0 5px;
}
</style>
