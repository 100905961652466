import navigationGuards from '../navigationGuards.js'

export default [
    {
        path: '/tasks-management',
        component: () => import('@/views/tasks/TasksManagement.vue'),
        beforeEnter: navigationGuards.guardAdmin,
        children: [
            {
                path: '',
                redirect: { name: 'tasks-management-contracts' },
            },
            {
                path: 'contracts',
                name: 'tasks-management-contracts',
                component: () => import('@/views/tasks/contracts/Contracts.vue'),
                beforeEnter: navigationGuards.guardAdmin,
                meta: {
                    parent: 'Tasks',
                    breadcrumb: () => [
                        {
                            text: 'breadcrumbs.task',
                            to: { name: 'tasks-management-contracts' },
                        },
                        {
                            text: 'breadcrumbs.tasksFrameContracts',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'contracts/:requestid/request-metering-points',
                name: 'tasks-management-contracts-request-metering-points',
                component: () => import('@/views/tasks/contracts/RequestMeteringPointsActionCard.vue'),
                beforeEnter: (to, from, next) => {
                    to.meta.breadcrumb = () =>
                        [
                            {
                                text: 'breadcrumbs.task',
                                to: { name: 'tasks-management-contracts' },
                            },
                            {
                                text: 'breadcrumbs.tasksFrameContracts',
                                to: { name: 'tasks-management-contracts' },
                            },
                        ]
                    navigationGuards.guardAdmin(to, from, next)
                },
                meta: { parent: 'Tasks' },
                props: true,
            },
            {
                path: 'offer',
                name: 'tasks-management-offer',
                component: () => import('@/views/tasks/Offer.vue'),
                beforeEnter: navigationGuards.guardAdmin,
                meta: {
                    parent: 'Tasks',
                    breadcrumb: () => [
                        {
                            text: 'breadcrumbs.task',
                            to: { name: 'tasks-management-contracts' },
                        },
                        {
                            text: 'breadcrumbs.offerTasks',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'strategy-offers',
                name: 'tasks-management-strategy-offers',
                component: () => import('@/views/tasks/StrategyOffers.vue'),
                beforeEnter: navigationGuards.guardAdmin,
                meta: {
                    parent: 'Tasks',
                    breadcrumb: () => [
                        {
                            text: 'breadcrumbs.task',
                            to: { name: 'tasks-management-contracts' },
                        },
                        {
                            text: 'breadcrumbs.strategyOffers',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'next-date',
                name: 'tasks-management-next-date',
                component: () => import('@/views/tasks/nextDate/NextDate.vue'),
                beforeEnter: navigationGuards.guardAdmin,
                meta: {
                    parent: 'Tasks',
                    breadcrumb: () => [
                        {
                            text: 'breadcrumbs.task',
                            to: { name: 'tasks-management-contracts' },
                        },
                        {
                            text: 'breadcrumbs.procurementNextDate',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'next-date/:procurementId/procurement-authorization',
                name: 'tasks-management-next-date-procurement-authorization',
                component: () => import('@/views/tasks/nextDate/ProcurementAuthorization'),
                beforeEnter: navigationGuards.guardAdmin,
                meta: {
                    parent: 'Tasks',
                    breadcrumb: (route) => [
                        {
                            text: 'breadcrumbs.task',
                            to: { name: 'tasks-management-contracts' },
                        },
                        {
                            text: 'breadcrumbs.procurementNextDate',
                            translatedText: `(Id: ${route.params.procurementId})`,
                            to: { name: 'tasks-management-next-date' },
                        },
                        {
                            text: 'breadcrumbs.procurementAuthorization',
                            active: true,
                        },
                    ],
                },
                props: true,
            },
            {
                path: 'data',
                name: 'tasks-management-data',
                component: () => import('@/views/tasks/Data.vue'),
                beforeEnter: navigationGuards.guardAdmin,
                meta: {
                    parent: 'Tasks',
                    breadcrumb: () => [
                        {
                            text: 'breadcrumbs.task',
                            to: { name: 'tasks-management-contracts' },
                        },
                        {
                            text: 'breadcrumbs.dataTasks',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'external-offers-interface-jobs',
                name: 'tasks-management-external-interface-offers-jobs',
                component: () => import('@/views/tasks/ExternalInterfaceJobs.vue'),
                beforeEnter: navigationGuards.guardAdmin,
                meta: {
                    parent: 'Tasks',
                    breadcrumb: () => [
                        {
                            text: 'breadcrumbs.task',
                            to: { name: 'tasks-management-contracts' },
                        },
                        {
                            text: 'breadcrumbs.externalOffersInterfaceJobs',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'external-meteringpoints-interface-jobs',
                name: 'tasks-management-external-interface-meteringpoints-jobs',
                component: () => import('@/views/tasks/ExternalInterfaceJobs.vue'),
                beforeEnter: navigationGuards.guardAdmin,
                meta: {
                    parent: 'Tasks',
                    breadcrumb: () => [
                        {
                            text: 'breadcrumbs.task',
                            to: { name: 'tasks-management-contracts' },
                        },
                        {
                            text: 'breadcrumbs.externalMeteringpointsInterfaceJobs',
                            active: true,
                        },
                    ],
                },
            },
        ],
    },
    {
        path: '/tasks-management/offer/:offerId/edit/:taskTypeId',
        name: 'tasks-management-offer-edit',
        component: () => import('@/views/procurement/offers/offerDetails/ValuationCard.vue'),
        beforeEnter: navigationGuards.guardAdmin,
        props: true,
        meta: {
            parent: 'Tasks',
            breadcrumb: (route) => [
                {
                    text: 'breadcrumbs.task',
                    to: { name: 'tasks-management-contracts' },
                },
                {
                    text: 'breadcrumbs.offerTasks',
                    translatedText: `(Id: ${route.params.offerId})`,
                    to: { name: 'tasks-management-offer' },
                },
                {
                    text: 'breadcrumbs.offerTasksEdit',
                    translatedText: `(Type Id: ${route.params.taskTypeId})`,
                    active: true,
                },
            ],
        },
    },
    {
        path: '/tasks-management/strategy-offers/:offerProcStratId/edit/:taskTypeId',
        name: 'tasks-management-strategy-offers-edit',
        component: () => import('@/components/offertCards/StrategiesValuationCard.vue'),
        beforeEnter: navigationGuards.guardAdmin,
        props: true,
        meta: {
            parent: 'Tasks',
            breadcrumb: (route) => [
                {
                    text: 'breadcrumbs.task',
                    to: { name: 'tasks-management-contracts' },
                },
                {
                    text: 'breadcrumbs.strategyOffers',
                    translatedText: `(Id: ${route.params.offerProcStratId})`,
                    to: { name: 'tasks-management-strategy-offers' },
                },
                {
                    text: 'breadcrumbs.strategyOffersEdit',
                    translatedText: `(Type Id: ${route.params.taskTypeId})`,
                    active: true,
                },
            ],
        },
    },
    {
        path: '/tasks-management/offer/:offerId/time-series',
        name: 'tasks-management-offer-time-series',
        component: () => import('@/components/offertCards/MenuTimeSeriesCard.vue'),
        beforeEnter: navigationGuards.guardAdmin,
        props: true,
        meta: {
            parent: 'Tasks',
            breadcrumb: (route) => [
                {
                    text: 'breadcrumbs.task',
                    to: { name: 'tasks-management-contracts' },
                },
                {
                    text: 'breadcrumbs.offerTasks',
                    translatedText: `(Id: ${route.params.offerId})`,
                    to: { name: 'tasks-management-offer' },
                },
                {
                    text: 'breadcrumbs.offerTasksTimeSeries',
                    active: true,
                },
            ],
        },
    },
]
